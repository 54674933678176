import { Button } from '@mui/material';
import ErrorIcon from '../../Assets/error-icon.svg';
import { useParams, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import './ProviderError.css';
import { PropsWithChildren, useContext } from 'react';
import { getStepNumber } from '../../Assets/stepDirectory';
import { Context } from '../Wrapper/Wrapper';

const ProviderError = ({ children }: PropsWithChildren) => {
  const { formData } = useContext(Context);
  const { uuid } = useParams();
  const navigate = useNavigate();

  return (
    <main>
      <div className="error-container">
        <img className="error-icon" src={ErrorIcon} />
        <h1 className="error-header">
          <FormattedMessage id="results-error.header" defaultMessage="Oops! Looks like something went wrong." />
        </h1>
        <p className="error-message">{children}</p>
        <Button
          className="error-button"
          onClick={() => {
            navigate(`/${uuid}/step-${getStepNumber('confirmation', formData.referrerCode)}`);
          }}
          variant="contained"
        >
          <FormattedMessage id="results-error.button" defaultMessage="Edit Application" />
        </Button>
      </div>
    </main>
  );
};

export default ProviderError;
