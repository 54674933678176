import { IconButton } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { ReactComponent as HelpBubbleIcon } from '../../Assets/AdamsAssets/helpBubble.svg';
import './HelpBubble.css';

type Messages = {
  fMsgId: string;
  fMDefaultMsg: string;
};

type HelpBubbleProps = {
  listOfMessages: Messages[];
  fontSize?: string;
};

const HelpBubble = ({ listOfMessages, fontSize }: HelpBubbleProps) => {
  const [showHelperText, setShowHelperText] = useState(false);

  return (
    <>
      <IconButton onClick={() => setShowHelperText(!showHelperText)}>
        <HelpBubbleIcon style={{ height: '1.25rem', width: '1.25rem', transform: 'translate(0px, -3px)' }} />
      </IconButton>
      {showHelperText &&
        listOfMessages.map((message) => {
          return (
            <p className="help-text" key={message.fMsgId} style={{ fontSize: fontSize ?? '1rem' }}>
              <FormattedMessage id={message.fMsgId} defaultMessage={message.fMDefaultMsg} />
            </p>
          );
        })}
    </>
  );
};

export default HelpBubble;
