import { FormattedMessage } from 'react-intl';
import './FinalPage.css';

const FinalPage = () => {
  return (
    <main className="benefits-form final-page-container">
      <h1 className="sub-header">
        <FormattedMessage
          id="finalPage.header"
          defaultMessage="Thank you for Submitting Your Application! You are Almost Done!"
        />
      </h1>
      <p>
        <FormattedMessage
          id="finalPage.description"
          defaultMessage="You must connect with a team member from Adams County to finish this process. They will call you in the next three business days to set up a time to finish.  You can meet virtually, in person near you, or at the Adams County office."
        />
      </p>
      <p>
        <FormattedMessage
          id="finalPage.documents"
          defaultMessage="When they call, they’ll talk to you about documents you’ll need to provide to verify your eligibility."
        />
      </p>
      <p>
        <FormattedMessage
          id="finalPage.email"
          defaultMessage="A copy of your provider selection has been emailed to you. If you do not see this confirmation email in your inbox, please check your spam folder."
        />
      </p>
      <p>
        <FormattedMessage
          id="finalPage.questions"
          defaultMessage="For questions, contact: adamsecepilot@garycommunity.org"
        />
      </p>
      <p>
        <FormattedMessage
          id="finalPage.thankYou"
          defaultMessage="We are looking forward to connecting your child with child care or preschool!"
        />
      </p>
    </main>
  );
};

export default FinalPage;
