import {
  ApiAddress,
  ApiAdultMember,
  ApiApplication,
  ApiChildMember,
  ApiContactInfo,
  ApiIncome,
  ApiLegalConsent,
  ApiMember,
  ApiOtherConditions,
  ApiSelectedProvider,
} from '../Types/ApiFormData.js';
import { Address, Application, ContactInfo, LegalConsent, Member, OtherConditions } from '../Types/FormData.js';
import { putScreen, postScreen } from '../apiCalls.js';
import { Language } from './languageOptions.js';

const getScreensBody = (formData: Application, languageCode: Language) => {
  const address = getAddressBody(formData.address);
  const otherConditions = getOtherConditionsBody(formData.otherConditions);
  const contactInfo = getContactInfoBody(formData.contactInformation);
  const legalConsent = getLegalConsentBody(formData.legalConsent);
  const members = getMembersBody(formData.members);

  const screenBody: ApiApplication = {
    id: '',
    request_language_code: languageCode,
    referrer_code: formData.referrerCode ?? null,
    is_test: formData.isTest,
    external_id: formData.externalId ?? null,
    adams_with_child: formData.adamsWithChild ?? null,
    household_size: formData.householdSize ?? null,
    school_district: formData.schoolDistrict ?? null,
    address: address,
    other_conditions: otherConditions,
    contact_information: contactInfo,
    legal_consent: legalConsent,
    members: members,
  };

  return screenBody;
};

const getAddressBody = (address: Address | undefined): ApiAddress | null => {
  if (address === undefined) {
    return null;
  }

  return {
    address_1: address.address1,
    address_2: address.address2,
    city: address.city,
    state: address.state,
    zipcode: address.zipcode,
    latitude: address.latitude,
    longitude: address.longitude,
    county: address.county,
  };
};

const getOtherConditionsBody = (otherConditions: OtherConditions | undefined): ApiOtherConditions | null => {
  if (otherConditions === undefined) {
    return null;
  }

  return {
    homeless: otherConditions.homeless,
    has_tanf: otherConditions.hasTanf,
    has_snap: otherConditions.hasSnap,
    has_ssi: otherConditions.hasSsi,
  };
};

const getContactInfoBody = (contactInfo: ContactInfo | undefined): ApiContactInfo | null => {
  if (contactInfo === undefined) {
    return null;
  }

  return {
    first_name: contactInfo.firstName,
    last_name: contactInfo.lastName,
    email: contactInfo.email,
    phone: contactInfo.phone,
    weekdays_mornings: contactInfo.weekdaysMornings,
    weekdays_lunch: contactInfo.weekdaysLunch,
    weekdays_afternoons: contactInfo.weekdaysAfternoons,
    weekdays_evenings: contactInfo.weekdaysEvenings,
    saturdays: contactInfo.saturdays,
    sundays: contactInfo.sundays,
  };
};

const getLegalConsentBody = (legalConsent: LegalConsent | undefined): ApiLegalConsent | null => {
  if (legalConsent === undefined) {
    return null;
  }

  return {
    info_correct: legalConsent.infoCorrect,
    read_policies_and_terms: legalConsent.readPoliciesAndTerms,
    thirteen_or_older: legalConsent.thirteenOrOlder,
    electronic_signature: legalConsent.electronicSignature,
    initials: legalConsent.initials,
    signature: legalConsent.signature,
  };
};

const getMembersBody = (members: Member[]): ApiMember[] => {
  return members.map(getMemberBody);
};

const getMemberBody = (member: Member): ApiMember => {
  let childMember: ApiChildMember | null = null;
  let adultMember: ApiAdultMember | null = null;

  if (member.childMember !== undefined) {
    const child = member.childMember;
    const careNeeds = child.childCareNeeds;

    const selectedProviders = child.selectedProviders.map((selectedProvider): ApiSelectedProvider => {
      return {
        name: selectedProvider.name,
        license_number: selectedProvider.id,
      };
    });

    const initialSelectedProviders = child.initialSelectedProviders.map((selectedProvider): ApiSelectedProvider => {
      return {
        name: selectedProvider.name,
        license_number: selectedProvider.id,
      };
    });

    childMember = {
      front_end_id: child.frontEndId,
      first_name: child.firstName,
      last_name: child.lastName,
      date_of_birth: `${child.birthYear}-${child.birthMonth}-${child.birthDay}`,
      seeking_child_care: child.seekingChildCare,
      iep: child.iep,
      limited_english: child.limitedEnglish,
      unable_to_care_for_themself: child.unableToCareForThemself,
      child_care_needs: {
        home_based: careNeeds.homeBased,
        center: careNeeds.center,
        school: careNeeds.school,
        preschool: careNeeds.preschool,
        head_start: careNeeds.headStart,
        family_or_friend: careNeeds.familyOrFriend,
        exploring: careNeeds.exploring,
        needed_care: careNeeds.neededCare,
        start_date: careNeeds.startDate,
      },
      selected_providers: selectedProviders,
      initial_selected_providers: initialSelectedProviders,
    };
  }

  if (member.adultMember !== undefined) {
    const adult = member.adultMember;

    const incomes = adult.income.map((income): ApiIncome => {
      return {
        type: income.type,
        amount: income.amount,
        frequency: income.frequency,
        hours_worked: income.hoursWorked ?? null,
      };
    });

    adultMember = {
      employed: adult.employed,
      self_employed: adult.selfEmployed,
      searching_for_job: adult.searchingForJob,
      post_secondary_student: adult.postSecondaryStudent,
      training: adult.training,
      english_second_language_student: adult.englishSecondLanguageStudent,
      high_school_student: adult.highSchoolStudent,
      middle_school_student: adult.middleSchoolStudent,
      disabled: adult.disabled,
      national_guard: adult.nationalGuard,
      millitary_reserves: adult.militaryReserves,
      millitary_active: adult.militaryActive,
      pregnant: adult.pregnant,
      breast_feeding: adult.breastFeeding,
      has_qualifying_factor: !adult.noQualifyingFactor,
      income: incomes,
    };
  }

  return {
    relationship: member.relationship || 'head_of_household',
    child_member: childMember,
    adult_member: adultMember,
  };
};

const updateScreen = (uuid: string, formData: Application, languageCode: Language) => {
  return putScreen(getScreensBody(formData, languageCode), uuid);
};

const createScreen = (formData: Application, languageCode: Language) => {
  const uuid = postScreen(getScreensBody(formData, languageCode));
  return uuid;
};

export { updateScreen, createScreen };
