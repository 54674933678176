import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as Edit } from '../../Assets/edit.svg';
import { ReactComponent as Residence } from '../../Assets/residence.svg';
import { ReactComponent as Household } from '../../Assets/household.svg';
import { ReactComponent as Head } from '../../Assets/head.svg';
import { ReactComponent as Conditions } from '../../Assets/benefits.svg';
import { ReactComponent as Student } from '../../Assets/OptionCardIcons/Conditions/student.svg';
import { FormattedMessageType } from '../../Types/Questions';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useMemo } from 'react';
import { Context } from '../Wrapper/Wrapper';
import { AdultMember, ChildMember, ContactInfo, ContactTimeOptions, Income, Member } from '../../Types/FormData';
import { SCHOOL_DISTRICT_OPTIONS } from '../SchoolDistrict/SchoolDistrict';
import { OTHER_CONDITIONS } from '../Conditions/Conditions';
import relationshipOptions from '../../Assets/relationshipOptions';
import neededCareOptions from '../../Assets/ChildBlockAssets/neededCareOptions';
import incomeOptions from '../../Assets/incomeOptions';
import { childCareStartDateOptions } from '../../Assets/ChildBlockAssets/childCareStartDateOptions';
import { contactTimeOptions } from '../Contact/contactTimeOptions';
import './Confirmation.css';

const RELATIONSHIP_MAPPING: { [key: string]: FormattedMessageType } = {
  ...relationshipOptions,
  head_of_household: <FormattedMessage id="confirmation.headOfHousehold" defaultMessage="Yourself" />,
};

function useNavigateToStep(step: string) {
  const { uuid } = useParams();
  const navigate = useNavigate();

  return () => {
    navigate(`/${uuid}/${step}`);
  };
}

function useFormatMessage() {
  const intl = useIntl();

  return (id: string, defaultMessage: string) => {
    return intl.formatMessage({ id: id, defaultMessage: defaultMessage });
  };
}

type ConfirmationCardProps = React.PropsWithChildren<{
  icon: React.ReactNode;
  title: FormattedMessageType | string;
  onEdit: () => void;
}>;

const ConfirmationCard = ({ icon, title, onEdit, children }: ConfirmationCardProps) => {
  return (
    <div className="confirmation-card-container">
      {icon}
      <div className="card-content">
        <h2 className="section-title">{title}</h2>
        <article className="section-p">{children}</article>
      </div>
      <div>
        <button className="edit-button" aria-label="edit household member" onClick={onEdit}>
          <Edit />
        </button>
      </div>
    </div>
  );
};

type CardInfoProps = {
  label: FormattedMessageType | string;
  item: FormattedMessageType | string;
};

const CardInfo = ({ label, item }: CardInfoProps) => {
  return (
    <div>
      <span className="confirmation-card-info-label">{label}:</span> {item}
    </div>
  );
};

type AdultMemberDetailsProps = {
  adult: AdultMember;
};

type AdultConditions =
  | 'employed'
  | 'selfEmployed'
  | 'searchingForJob'
  | 'postSecondaryStudent'
  | 'training'
  | 'englishSecondLanguageStudent'
  | 'highSchoolStudent'
  | 'middleSchoolStudent'
  | 'disabled'
  | 'nationalGuard'
  | 'militaryReserves'
  | 'militaryActive'
  | 'pregnant'
  | 'breastFeeding';

const ADULT_CONDTION_VARIABLES: AdultConditions[] = [
  'employed',
  'selfEmployed',
  'searchingForJob',
  'postSecondaryStudent',
  'training',
  'englishSecondLanguageStudent',
  'highSchoolStudent',
  'middleSchoolStudent',
  'disabled',
  'nationalGuard',
  'militaryReserves',
  'militaryActive',
  'pregnant',
  'breastFeeding',
];

function useConditionString(adult: AdultMember) {
  const formatMessage = useFormatMessage();
  const mapCondition = (need: AdultConditions) => {
    switch (need) {
      case 'employed':
        return formatMessage('confirmation.member.adult.conditions.employed', 'employed');
      case 'selfEmployed':
        return formatMessage('confirmation.member.adult.conditions.selfEmployed', 'self employed');
      case 'searchingForJob':
        return formatMessage('confirmation.member.adult.conditions.searchingForJob', 'searching for a job');
      case 'postSecondaryStudent':
        return formatMessage('confirmation.member.adult.conditions.postSecondaryStudent', 'post secondary student');
      case 'training':
        return formatMessage('confirmation.member.adult.conditions.training', 'training');
      case 'englishSecondLanguageStudent':
        return formatMessage(
          'confirmation.member.adult.conditions.englishSecondLanguageStudend',
          'English second language student',
        );
      case 'highSchoolStudent':
        return formatMessage('confirmation.member.adult.conditions.highSchoolStudent', 'high school student');
      case 'middleSchoolStudent':
        return formatMessage('confirmation.member.adult.conditions.middleSchoolStudent', 'middle school student');
      case 'disabled':
        return formatMessage('confirmation.member.adult.conditions.disabled', 'disabled');
      case 'nationalGuard':
        return formatMessage('confirmation.member.adult.conditions.nationalGuard', 'national guard');
      case 'militaryReserves':
        return formatMessage('confirmation.member.adult.conditions.militaryReserves', 'military reserves');
      case 'militaryActive':
        return formatMessage('confirmation.member.adult.conditions.militaryActive', 'militart active');
      case 'pregnant':
        return formatMessage('confirmation.member.adult.conditions.pregnant', 'pregnant');
      case 'breastFeeding':
        return formatMessage('confirmation.member.adult.conditions.breastFeeding', 'breast feeding');
    }
  };

  const conditions = useMemo(() => {
    const conditions: AdultConditions[] = [];

    for (const condition of ADULT_CONDTION_VARIABLES) {
      if (adult[condition]) {
        conditions.push(condition);
      }
    }

    if (conditions.length === 0) {
      return formatMessage('confirmation.member.adult.conditions', 'None');
    }

    return conditions.map(mapCondition).join(', ');
  }, [adult]);

  return conditions;
}

const formatToUSD = (num: number) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
};

const useDisplayAnnualIncome = (incomeStream: Income) => {
  const { amount, frequency, hoursWorked } = incomeStream;
  const formatMessage = useFormatMessage();
  const translatedAnnualText = formatMessage('displayAnnualIncome.annual', ' annually');
  let num = 0;

  switch (frequency) {
    case 'weekly':
      num = Number(amount) * 52;
      break;
    case 'biweekly':
      num = Number(amount) * 26;
      break;
    case 'monthly':
      num = Number(amount) * 12;
      break;
    case 'hourly':
      num = Number(amount) * Number(hoursWorked) * 52;
      break;
  }

  return `(${formatToUSD(num)}` + translatedAnnualText + ')';
};

function useCreateIncomeString(income: Income) {
  const formatMessage = useFormatMessage();
  const anualIncome = useDisplayAnnualIncome(income);

  const amount = formatToUSD(Number(income.amount));

  let frequency = '';

  if (income.frequency === 'monthly') {
    frequency = formatMessage('confirmation.member.adult.income.frequency.monthy', 'every month');
  } else if (income.frequency === 'weekly') {
    frequency = formatMessage('confirmation.member.adult.income.frequency.weekly', 'every week');
  } else if (income.frequency === 'biweekly') {
    frequency = formatMessage('confirmation.member.adult.income.frequency.twoWeeks', 'every two weeks');
  } else if (income.frequency === 'hourly') {
    frequency = formatMessage('confirmation.member.adult.income.frequency.hourly', 'every hour');
  }

  return amount + ' ' + frequency + ' ' + anualIncome;
}

type IncomeDetailsProps = {
  income: Income;
};
const IncomeDetails = ({ income }: IncomeDetailsProps) => {
  const incomeString = useCreateIncomeString(income);
  return (
    <li className="list-bullet">
      <CardInfo
        label={
          // @ts-ignore
          incomeOptions[income.type]
        }
        item={incomeString}
      />
    </li>
  );
};

const AdultMemberDetails = ({ adult }: AdultMemberDetailsProps) => {
  const conditions = useConditionString(adult);

  return (
    <>
      <CardInfo
        label={<FormattedMessage id="confirmation.member.adult.condition.info" defaultMessage="Conditions" />}
        item={conditions}
      />
      {adult.income.length === 0 && (
        <CardInfo
          label={<FormattedMessage id="confimation.member.adult.income.none.label" defaultMessage="Income" />}
          item={<FormattedMessage id="confirmaion.member.adult.income.none.text" defaultMessage="None" />}
        />
      )}
      {adult.income.length !== 0 && (
        <>
          <span className="confirmation-card-info-label">
            <FormattedMessage id="confirmation.member.adult.income.label" defaultMessage="Income:" />
          </span>
          <ul>
            {adult.income.map((income, index) => {
              return <IncomeDetails income={income} key={index} />;
            })}
          </ul>
        </>
      )}
    </>
  );
};

type ChildCareNeeds = 'homeBased' | 'center' | 'school' | 'preschool' | 'headStart' | 'familyOrFriend' | 'exploring';

const CHILD_CARE_NEEDS: ChildCareNeeds[] = [
  'homeBased',
  'center',
  'school',
  'preschool',
  'headStart',
  'familyOrFriend',
  'exploring',
];

function useChildCareNeedsString(child: ChildMember) {
  const formatMessage = useFormatMessage();

  const mapNeeds = (need: ChildCareNeeds) => {
    switch (need) {
      case 'homeBased':
        return formatMessage('confirmation.member.child.needs.homeBased', 'home based');
      case 'center':
        return formatMessage('confirmation.member.child.needs.center', 'center');
      case 'school':
        return formatMessage('confirmation.member.child.needs.school', 'school');
      case 'preschool':
        return formatMessage('confirmation.member.child.needs.preschool', 'preschool');
      case 'headStart':
        return formatMessage('confirmation.member.child.needs.headStart', 'Head Start');
      case 'familyOrFriend':
        return formatMessage('confirmation.member.child.needs.familyOrFriend', 'family or friend');
      case 'exploring':
        return formatMessage('confirmation.member.child.needs.exploring', 'exploring');
    }
  };

  const conditions = useMemo(() => {
    const conditions: ChildCareNeeds[] = [];

    for (const condition of CHILD_CARE_NEEDS) {
      if (child.childCareNeeds[condition]) {
        conditions.push(condition);
      }
    }

    if (conditions.length === 0) {
      return formatMessage('confirmation.member.child.needs.none', 'None');
    }

    return conditions.map(mapNeeds).join(', ');
  }, [child]);

  return conditions;
}

type ChildMemberCareDetailsProps = {
  child: ChildMember;
};
const ChildMemberCareDetails = ({ child }: ChildMemberCareDetailsProps) => {
  const typesOfPrograms = useChildCareNeedsString(child);
  return (
    <>
      <CardInfo
        label={<FormattedMessage id="confirmation.member.child.needs.typeOfProgram" defaultMessage="Type of program" />}
        item={typesOfPrograms}
      />
      <CardInfo
        label={<FormattedMessage id="confirmation.member.child.needs.hoursNeeded" defaultMessage="Hours of care" />}
        item={neededCareOptions[child.childCareNeeds.neededCare]}
      />
      <CardInfo
        label={<FormattedMessage id="confirmation.member.child.needs.startDate" defaultMessage="Start Date" />}
        item={childCareStartDateOptions[child.childCareNeeds.startDate]}
      />
    </>
  );
};

type ChildConditions = 'iep' | 'limitedEnglish' | 'unableToCareForThemself';

const CHILD_CONDITIONS: ChildConditions[] = ['iep', 'limitedEnglish', 'unableToCareForThemself'];

function useChildConditionsString(child: ChildMember) {
  const formatMessage = useFormatMessage();

  const mapCondition = (condition: ChildConditions) => {
    if (condition === 'iep') {
      return formatMessage('confirmation.member.adult.conditions.iep', 'IEP');
    }

    if (condition === 'limitedEnglish') {
      return formatMessage('confirmation.member.adult.conditions.limitedEng', 'multilingual');
    }

    if (condition === 'unableToCareForThemself') {
      return formatMessage('confirmation.member.adult.conditions.unableToCare', 'unable to care for themselves');
    }
  };
  const conditions = useMemo(() => {
    const conditions: ChildConditions[] = [];

    for (const condition of CHILD_CONDITIONS) {
      if (child[condition]) {
        conditions.push(condition);
      }
    }

    if (conditions.length === 0) {
      return formatMessage('confirmation.member.child.conditions.none', 'None');
    }

    return conditions.map(mapCondition).join(', ');
  }, [child]);

  return conditions;
}

type ChildMemberDetailsProps = {
  child: ChildMember;
};
const ChildMemberDetails = ({ child }: ChildMemberDetailsProps) => {
  const formatMessage = useFormatMessage();
  const childConditions = useChildConditionsString(child);
  return (
    <>
      <CardInfo
        label={<FormattedMessage id="confirmation.member.child.firstName.info" defaultMessage="First Name" />}
        item={child.firstName}
      />
      <CardInfo
        label={<FormattedMessage id="confirmation.member.child.lastName.info" defaultMessage="Last Name" />}
        item={child.lastName}
      />
      <CardInfo
        label={<FormattedMessage id="confirmation.member.child.birthday.info" defaultMessage="Date of Birth" />}
        item={`${parseInt(child.birthMonth)}/${parseInt(child.birthDay)}/${child.birthYear}`}
      />
      <CardInfo
        label={
          <FormattedMessage
            id="confirmation.member.child.seekingChildCare.info"
            defaultMessage="Seeking child care / an early childhood program"
          />
        }
        item={
          child.seekingChildCare
            ? formatMessage('confirmation.member.child.seekingChildCare.yes', 'Yes')
            : formatMessage('confirmation.member.child.seekingChildCare.no', 'No')
        }
      />
      {child.seekingChildCare && <ChildMemberCareDetails child={child} />}
      <CardInfo
        label={<FormattedMessage id="confirmation.member.child.conditions.info" defaultMessage="Conditions" />}
        item={childConditions}
      />
    </>
  );
};

type MemberCardProps = {
  member: Member;
  index: number;
};

function fullName(firstName: string, lastName: string) {
  return `${firstName} ${lastName}`;
}
const MemberCard = ({ member, index }: MemberCardProps) => {
  if (member.adultMember !== undefined && member.childMember !== undefined) {
    throw new Error('Member is both an adult and a child');
  }

  const navToMember = useNavigateToStep(`step-5/${index + 1}`);

  return (
    <ConfirmationCard
      icon={<Head className="confirmation-card-icon" />}
      title={
        member.childMember === undefined
          ? RELATIONSHIP_MAPPING[member.relationship]
          : fullName(member.childMember.firstName, member.childMember.lastName)
      }
      onEdit={navToMember}
    >
      <CardInfo
        label={<FormattedMessage id="confirmation.member.relationship" defaultMessage="Relationship" />}
        item={RELATIONSHIP_MAPPING[member.relationship]}
      />
      {member.adultMember !== undefined && <AdultMemberDetails adult={member.adultMember} />}
      {member.childMember !== undefined && <ChildMemberDetails child={member.childMember} />}
    </ConfirmationCard>
  );
};

const Confirmation = () => {
  const { formData } = useContext(Context);
  const formatMessage = useFormatMessage();

  const navToAddress = useNavigateToStep('step-3');
  const navToHouseholdMembers = useNavigateToStep('step-4');
  const navToSchool = useNavigateToStep('step-6');
  const navToConditions = useNavigateToStep('step-7');
  const navToContact = useNavigateToStep('step-8');

  const conditions = formData.otherConditions;
  const hasConditions = useMemo(() => {
    if (conditions === undefined) {
      return true;
    }

    return conditions.hasSnap || conditions.hasTanf || conditions.hasSsi || conditions.homeless;
  }, [conditions?.hasSnap, conditions?.hasTanf, conditions?.hasSsi, conditions?.homeless]);

  const selectedTimeToContactOptions = (contactInformation: ContactInfo | undefined) => {
    const intl = useIntl();
    const timeToContactOptions: Array<keyof ContactTimeOptions>= [
      'weekdaysMornings',
      'weekdaysLunch',
      'weekdaysAfternoons',
      'weekdaysEvenings',
      'saturdays',
      'sundays',
    ];

    const selectedTimesToContact = timeToContactOptions
      .filter((contactOption) => {
        const typedOptionValue = contactOption as keyof ContactInfo;
        if (contactInformation) {
          return contactInformation[typedOptionValue];
        }
      })
      .map((filteredOption) => {
        const translatedMessageId = contactTimeOptions[filteredOption].props.id;
        const translatedMessageDefaultMsg = contactTimeOptions[filteredOption].props.defaultMessage;
        const translatedOption = intl.formatMessage({
          id: translatedMessageId,
          defaultMessage: translatedMessageDefaultMsg,
        });

        return translatedOption;
      })
      .join(', ');

    return selectedTimesToContact;
  };

  return (
    <article className="confirmation-container">
      <ConfirmationCard
        icon={<Residence className="confirmation-card-icon" />}
        title={<FormattedMessage id="confirmation.address.title" defaultMessage="Address" />}
        onEdit={navToAddress}
      >
        {formData.address?.address1 ?? ''}
      </ConfirmationCard>
      <ConfirmationCard
        icon={<Household className="confirmation-card-icon" />}
        title={<FormattedMessage id="confirmation.householdSize.title" defaultMessage="Household Members" />}
        onEdit={navToHouseholdMembers}
      >
        <CardInfo
          label={<FormattedMessage id="confirmation.householdSize.info" defaultMessage="Household Size" />}
          item={`${formData.householdSize ?? 0} ${
            (formData.householdSize ?? 0) > 1
              ? formatMessage('confirmation.householdSize.info.label', 'people')
              : formatMessage('confirmation.householdSize.info.lable.singular', 'person')
          }`}
        />
      </ConfirmationCard>
      {formData.members.map((member, index) => {
        return <MemberCard member={member} key={index} index={index} />;
      })}
      <ConfirmationCard
        icon={<Student className="confirmation-card-icon" />}
        title={<FormattedMessage id="confirmation.schoolDistrict.title" defaultMessage="School District" />}
        onEdit={navToSchool}
      >
        {SCHOOL_DISTRICT_OPTIONS[formData.schoolDistrict ?? ''] ?? ''}
      </ConfirmationCard>
      <ConfirmationCard
        icon={<Conditions className="confirmation-card-icon" />}
        title={<FormattedMessage id="confirmation.conditions.title" defaultMessage="Household Conditions" />}
        onEdit={navToConditions}
      >
        <div>{formData.otherConditions?.homeless && OTHER_CONDITIONS['homeless']}</div>
        <div>{formData.otherConditions?.hasSnap && OTHER_CONDITIONS['hasSnap']}</div>
        <div>{formData.otherConditions?.hasTanf && OTHER_CONDITIONS['hasTanf']}</div>
        <div>{formData.otherConditions?.hasSsi && OTHER_CONDITIONS['hasSsi']}</div>
        <div>{!hasConditions && <FormattedMessage id="confirmation.conditions.none" defaultMessage="None" />}</div>
      </ConfirmationCard>
      <ConfirmationCard
        icon={<Head className="confirmation-card-icon" />}
        title={<FormattedMessage id="confirmation.contactInformation.title" defaultMessage="Contact Information" />}
        onEdit={navToContact}
      >
        <CardInfo
          label={<FormattedMessage id="confirmation.contactInformation.firstName.label" defaultMessage="First Name" />}
          item={formData.contactInformation?.firstName ?? ''}
        />
        <CardInfo
          label={<FormattedMessage id="confirmation.contactInformation.lastName.label" defaultMessage="Last Name" />}
          item={formData.contactInformation?.lastName ?? ''}
        />
        <CardInfo
          label={<FormattedMessage id="confirmation.contactInformation.email.label" defaultMessage="Email" />}
          item={formData.contactInformation?.email ?? ''}
        />
        <CardInfo
          label={<FormattedMessage id="confirmation.contactInformation.phone.label" defaultMessage="Phone Number" />}
          item={formData.contactInformation?.phone ?? ''}
        />
        <CardInfo
          label={
            <FormattedMessage id="confirmation.contactInformation.timeToContact" defaultMessage="Time to contact" />
          }
          item={selectedTimeToContactOptions(formData.contactInformation) ?? ''}
        />
      </ConfirmationCard>
    </article>
  );
};

export default Confirmation;
