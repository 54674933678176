export default [
  '80002',
  '80003',
  '80010',
  '80011',
  '80018',
  '80019',
  '80020',
  '80022',
  '80023',
  '80024',
  '80030',
  '80031',
  '80045',
  '80102',
  '80103',
  '80105',
  '80136',
  '80137',
  '80212',
  '80216',
  '80220',
  '80221',
  '80229',
  '80233',
  '80234',
  '80238',
  '80239',
  '80241',
  '80249',
  '80260',
  '80516',
  '80601',
  '80602',
  '80603',
  '80640',
  '80642',
  '80643',
  '80652',
  '80654',
  '80701',
  '80757',
];
