import Header from '../Header/Header';
import { FormattedMessage } from 'react-intl';
import Footer from '../Footer/Footer';

type HeaderProps = {
  handleTextFieldChange: (event: Event) => void;
};

export const BrandedHeader = ({ handleTextFieldChange }: HeaderProps) => {
  return <Header handleTextfieldChange={handleTextFieldChange} />;
};

export const BrandedFooter = () => {
  return <Footer />;
};

type ResultsHeaderProps = {
  programCount: number;
  programsValue: number;
  taxCreditsValue: number;
};

export const BrandedResultsHeader = ({ programsValue, taxCreditsValue, programCount }: ResultsHeaderProps) => {
  return (
    <h1 className="bottom-border program-value-header">
      {programCount}
      <FormattedMessage id="results.return-programsUpToLabel" defaultMessage=" programs with an estimated value of " />$
      {Math.round(programsValue / 12).toLocaleString()}
      <FormattedMessage id="results.return-perMonthLabel" defaultMessage=" monthly in cash or reduced expenses, and " />
      ${Math.round(taxCreditsValue).toLocaleString()}
      <FormattedMessage id="results.return-taxCredits" defaultMessage=" in tax credits for you to consider " />
    </h1>
  );
};
