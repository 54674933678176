import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import ProviderCards from './ProviderCards';
import ProviderChildren from './ProviderChildren';
import ProviderFilter from './ProviderFilters';
import ProviderMap from './ProviderMap';
import { CccapProvider, Filters, MemberEligibility, Provider, TotalEligibility } from '../../Types/Eligibility';
import { getEligibility } from '../../apiCalls';
import { useParams } from 'react-router-dom';
import SortProviders from './providerSorting';
import { Context } from '../Wrapper/Wrapper';
import './ProviderPage.css';
import { filterDefault, findProvider, mapCccapCitizenship, updatedMemberProviders } from './hooks';
import { providerError, providerMessage, useErrorController } from '../../Assets/validationFunctions';
import { ErrorController } from '../../Types/ErrorController';
import loading from '../../Assets/loading/loading-icon.png';
import ProviderDescription from './ProviderDescription';

export type ChildProviders = {
  [key: string]: Provider[];
};

type FocusedProvider = {
  scrollTo: boolean;
  provider: Provider;
};

export type ProviderContext = {
  providers: ChildProviders;
  allProviders: Provider[];
  eligibility: MemberEligibility[];
  focusedProvider: FocusedProvider | undefined;
  setFocusedProvider: (provider: FocusedProvider | undefined) => void;
  filters: Filters;
  setFilters: (filter: Filters) => void;
  errorController: ErrorController;
};

export const ProviderContext = createContext<ProviderContext | undefined>(undefined);

type ProviderPageProps = {
  submitted: number;
  setProviderHasError: (hasError: boolean) => void;
  setProviderNoChildren: (hasError: boolean) => void;
};

const ProviderPage = ({ submitted, setProviderHasError, setProviderNoChildren }: ProviderPageProps) => {
  const { uuid } = useParams();
  const { formData, setFormData } = useContext(Context);
  const [providerLoading, setProviderLoading] = useState(true);
  const [rawProviders, setRawProviders] = useState<Provider[] | undefined>();
  const [rawEligibility, setRawEligibility] = useState<MemberEligibility[] | undefined>();

  const [providers, setProviders] = useState<ChildProviders>({});
  const [focusedProvider, setFocusedProvider] = useState<FocusedProvider | undefined>();
  const [eligibility, setEligibility] = useState<MemberEligibility[]>([]);
  const [filters, setFilters] = useState<Filters>(filterDefault(formData));
  const [initialProvidersSet, setInitialProvidersSet] = useState(false);

  const allProviders = useMemo(() => rawProviders?.map(mapCccapCitizenship(filters)) ?? [], [filters]);
  const errorController = useErrorController(providerError, providerMessage);
  useEffect(() => {
    errorController.setSubmittedCount(submitted);

    if (errorController.hasError) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [submitted]);

  useEffect(() => {
    errorController.updateError(null, formData);
  }, [formData.members, submitted]);

  useEffect(() => {
    getEligibility(uuid)
      .then((res: TotalEligibility) => {
        setRawProviders(res.providers);
        setRawEligibility(res.eligibility);
        setProviderLoading(false);
      })
      .catch(() => {
        setProviderHasError(true);
        setProviderLoading(false);
      });
  }, []);

  useEffect(() => {
    const childProviders: ChildProviders = {};
    let updatedMembers = formData.members;

    if (rawEligibility === undefined || allProviders.length === 0) {
      setProviders({});
      setEligibility([]);
      return;
    }

    for (const childEligibility of rawEligibility) {
      const member = formData.members.find((member) => {
        return member.childMember?.frontEndId === childEligibility.child_id;
      });

      if (member === undefined || member.childMember === undefined) {
        throw new Error('no child with that id');
      }

      const sorter = new SortProviders(childEligibility, formData, member.childMember, filters);
      const sortedProviders = allProviders.toSorted(sorter.sort());

      childProviders[childEligibility.child_id] = sortedProviders;

      if (member.childMember.selectedProviders.length === 0) {
        updatedMembers = updatedMemberProviders(
          updatedMembers,
          childEligibility.child_id,
          sortedProviders.slice(0, 3),
          true,
        );
      }
    }

    if (!initialProvidersSet) {
      for (const child of rawEligibility) {
        const updatedMember = updatedMembers.find((member) => member.childMember?.frontEndId === child.child_id);

        if (updatedMember === undefined || updatedMember.childMember === undefined) {
          continue;
        }

        updatedMember.childMember.eligibility = {
          upk_eligible: child.eligibility.upk.eligible,
          upk_hours: child.eligibility.upk.hours,
          cccap_eligible: child.eligibility.cccap.eligible,
          cccap_hours: child.eligibility.cccap.hours,
          head_start_eligible: child.eligibility.head_start.eligible,
          head_start_hours: child.eligibility.head_start.hours,
        };
      }

      setFormData({ ...formData, members: updatedMembers });
      setInitialProvidersSet(true);
    }

    setEligibility(rawEligibility);
    setProviders(childProviders);
  }, [allProviders, rawEligibility, filters]);

  useEffect(() => {
    if (rawEligibility === undefined) {
      return;
    }

    if (rawEligibility.length === 0) {
      setProviderNoChildren(true);
    }
  }, [rawEligibility]);

  if (providerLoading) {
    return (
      <div className="loading-container">
        <img className="loading-image" src={loading} alt="loading-icon" />
      </div>
    );
  }

  return (
    <div>
      <ProviderContext.Provider
        value={{
          eligibility: eligibility,
          providers: providers,
          allProviders: allProviders,
          focusedProvider: focusedProvider,
          setFocusedProvider: setFocusedProvider,
          filters: filters,
          setFilters: setFilters,
          errorController: errorController,
        }}
      >
        <ProviderDescription />
        <ProviderChildren />
        <ProviderFilter />
        <div className="provider-container">
          <ProviderCards />
          <ProviderMap />
        </div>
      </ProviderContext.Provider>
    </div>
  );
};

export default ProviderPage;
