import { FormattedMessage } from 'react-intl';

const relationshipOptions = {
  child: <FormattedMessage id="relationshipOptions.child" defaultMessage="Child" />,
  foster_child: <FormattedMessage id="relationshipOptions.fosterChild" defaultMessage="Foster Child / Kinship Care" />,
  step_child: <FormattedMessage id="relationshipOptions.stepChild" defaultMessage="Step-child" />,
  grandchild: <FormattedMessage id="relationshipOptions.grandChild" defaultMessage="Grandchild" />,
  spouse: (
    <FormattedMessage
      id="relationshipOptions.spouse"
      defaultMessage="Spouse / Domestic Partner / Additional Guardian"
    />
  ),
  other: <FormattedMessage id="relationshipOptions.otherAdult" defaultMessage="Other Adult" />,
};

export default relationshipOptions;
