import { PropsWithChildren } from 'react';
import { ReactComponent as WarningIcon } from '../../Assets/warning-icon.svg';
import './ErrorBox.css';

const ErrorBox = ({ children }: PropsWithChildren) => {
  return (
    <div className="back-to-screen-message">
      <WarningIcon className="back-to-screen-warning-icon" />
      <div>{children}</div>
    </div>
  );
};

export default ErrorBox;
