import { FormattedMessage } from 'react-intl';
import { FormattedMessageType } from '../../Types/Questions';
import { useContext, useEffect, useState } from 'react';
import { Context } from '../Wrapper/Wrapper';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { OtherConditions } from '../../Types/FormData';

export type Condition = 'hasSnap' | 'hasTanf' | 'hasSsi' | 'homeless';

export const OTHER_CONDITIONS: Record<Condition, FormattedMessageType> = {
  homeless: (
    <FormattedMessage
      id="conditions.snap"
      defaultMessage="Experiencing homelessness (without regular / stable housing, living with friends or family, or in a shelter)"
    />
  ),
  hasTanf: (
    <FormattedMessage id="conditions.tanf" defaultMessage="Receiving cash assistance through Colorado Works / TANF" />
  ),
  hasSnap: (
    <FormattedMessage
      id="conditions.homeless"
      defaultMessage="Receiving SNAP (Supplemental Nutrition Assistance Program)"
    />
  ),
  hasSsi: (
    <FormattedMessage
      id="conditions.ssi"
      defaultMessage="Receiving SSI (Supplemental Security Income) - cash assistance for individuals who are disabled, blind, or 65+"
    />
  ),
};

type ConditionProps = {
  submitted: number;
};

const Conditions = ({ submitted }: ConditionProps) => {
  const { formData, setFormData } = useContext(Context);

  const [conditions, setCondtions] = useState<OtherConditions>({
    homeless: formData.otherConditions?.homeless ?? false,
    hasTanf: formData.otherConditions?.hasTanf ?? false,
    hasSnap: formData.otherConditions?.hasSnap ?? false,
    hasSsi: formData.otherConditions?.hasSsi ?? false,
  });

  const update = (type: Condition) => {
    return () => {
      setCondtions({ ...conditions, [type]: !conditions[type] });
    };
  };

  useEffect(() => {
    setFormData({ ...formData, otherConditions: conditions });
  });

  return (
    <div>
      <FormControl>
        {Object.entries(OTHER_CONDITIONS).map((entry) => {
          const [key, value] = entry;
          return (
            <FormControlLabel
              control={<Checkbox onClick={update(key as Condition)} />}
              checked={conditions[key as Condition]}
              label={value}
              key={key}
            />
          );
        })}
      </FormControl>
    </div>
  );
};

export default Conditions;
