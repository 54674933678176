import { useContext, useMemo } from 'react';
import { ProviderContext } from './ProviderPage';
import { Context } from '../Wrapper/Wrapper';
import { Filters, MemberEligibility, Provider } from '../../Types/Eligibility';
import { Application, ChildMember, Member } from '../../Types/FormData';
import { FormattedMessageType } from '../../Types/Questions';

export function useProviderContext() {
  const context = useContext(ProviderContext);

  if (context === undefined) {
    throw new Error('Component not in results context');
  }

  return context;
}

export function useEligibleChildren() {
  const { formData } = useContext(Context);
  const { eligibility } = useProviderContext();

  const eligibleChildren = useMemo(() => {
    return formData.members.filter((member) => {
      if (member.childMember === undefined) {
        return false;
      }

      const sameChild = (eligibility: MemberEligibility) => {
        // @ts-ignore TS thinks that childMember can be undefined
        return eligibility.child_id === member.childMember.frontEndId;
      };

      return eligibility.some(sameChild);
    });
  }, [formData.members, eligibility]);

  return eligibleChildren;
}

interface ISelectedProvider {
  name: string;
  id: string;
}

export function updatedMemberProviders(
  members: Member[],
  frontEndId: string,
  providers: ISelectedProvider[],
  intial: boolean = false,
) {
  return members.map((member) => {
    if (member.childMember === undefined || member.childMember.frontEndId !== frontEndId) {
      return member;
    }

    const updatedProviders = providers.map((provider) => {
      return {
        name: provider.name,
        id: provider.id,
      };
    });

    return {
      ...member,
      childMember: {
        ...member.childMember,
        selectedProviders: updatedProviders,
        initialSelectedProviders: intial ? updatedProviders : member.childMember.initialSelectedProviders,
      },
    };
  });
}
export function useUpdateSelectedProviders() {
  const { formData, setFormData } = useContext(Context);

  return (frontEndId: string, providers: ISelectedProvider[]) => {
    const updatedMembers = updatedMemberProviders(formData.members, frontEndId, providers);
    setFormData({ ...formData, members: updatedMembers });
  };
}

export function providerIsSelected(provider: Provider, child: ChildMember): boolean {
  return child.selectedProviders.some((selected) => selected.id === provider.id);
}

export function maxUpkHoursAvailable(provider: Provider): number {
  const upk = provider.upk;

  if (upk.extended_day || upk.full_day || upk.half_day || upk.half_day_am || upk.half_day_pm) {
    return 15;
  }

  if (upk.part_time || upk.part_time_am || upk.part_time_pm) {
    return 10;
  }

  return 0;
}

export function maxCccapHoursAvailable(provider: Provider): number {
  const cccap = provider.cccap;

  if (cccap.extended_day) {
    return 41;
  }

  if (cccap.full_day) {
    return 40;
  }

  if (cccap.half_day_am || cccap.half_day_pm) {
    return 29;
  }

  if (cccap.part_time) {
    return 14;
  }
  return 0;
}

export function maxHeadStartHoursAvailable(provider: Provider): number {
  const headStart = provider.head_start;

  if (headStart.extended_day || headStart.full_day_35) {
    return 35;
  }
  if (headStart.full_day_28) {
    return 28;
  }
  if (headStart.half_day_am || headStart.half_day_pm) {
    return 28;
  }
  if (headStart.part_time) {
    return 14;
  }

  return 0;
}

export function formatHours(hours: number): string {
  if (hours >= 41) {
    return '41+';
  }

  if (hours <= 0) {
    return '0';
  }

  return String(Math.round(hours));
}

export function useHoursEligible(childId: string, provider: Provider): number {
  const { eligibility } = useProviderContext();

  const hoursEligible = useMemo(() => {
    const childEligibility = eligibility.find((eligibility) => eligibility.child_id === childId)?.eligibility;

    if (childEligibility === undefined) {
      return 0;
    }

    if (childEligibility.cccap.hours > 0 && provider.cccap.on_provider_list) {
      return Math.min(maxCccapHoursAvailable(provider), childEligibility.cccap.hours);
    }

    if (childEligibility.head_start.hours > 0 && provider.head_start.on_provider_list) {
      return Math.min(maxHeadStartHoursAvailable(provider), childEligibility.head_start.hours);
    }

    if (childEligibility.upk.hours > 0 && provider.upk.on_provider_list) {
      return Math.min(maxUpkHoursAvailable(provider), childEligibility.upk.hours);
    }

    return 0;
  }, [childId, provider, eligibility]);

  return hoursEligible;
}

interface IPreferredChildCareTypes {
  homeBased: boolean;
  center: boolean;
  school: boolean;
  preschool: boolean;
  headStart: boolean;
}

export function childProviderTypes(needs: IPreferredChildCareTypes) {
  const allNeeds: string[] = [];
  if (needs.center) {
    allNeeds.push('Child Care Center');
  }
  if (needs.homeBased) {
    allNeeds.push(
      'Experienced Family Child Care Home',
      'Family Child Care Home',
      'Large Family Child Care Home',
      'Three under Two Family Child Care Home',
    );
  }
  if (needs.school) {
    allNeeds.push('School-Age Child Care Center');
  }
  if (needs.preschool) {
    allNeeds.push('Preschool Program');
  }

  return allNeeds;
}

export function useFocused(provider: Provider) {
  const { focusedProvider } = useProviderContext();
  const focused = useMemo(
    () => provider.id === focusedProvider?.provider.id,
    [provider.id, focusedProvider?.provider.id],
  );

  return focused;
}

export function useSetFocusedProvider(provider: Provider, scroll: boolean = true) {
  const { setFocusedProvider } = useProviderContext();

  return () => {
    setFocusedProvider({ provider: provider, scrollTo: scroll });
  };
}

export function useCitizenIsDefault() {
  const { filters } = useProviderContext();

  const citizenIsDefault = useMemo(() => {
    return filters.citizen === true;
  }, [filters.citizen]);

  return citizenIsDefault;
}
export function useAddressIsDefault() {
  const { formData } = useContext(Context);
  const { filters } = useProviderContext();

  const addressIsDefault = useMemo(() => {
    return filters.address.lat === formData.address?.latitude && filters.address.lng === formData.address.longitude;
  }, [filters.address]);

  return addressIsDefault;
}
export function useChildIsDefault() {
  const { filters } = useProviderContext();

  const childIsDefault = useMemo(() => {
    return filters.childId === undefined;
  }, [filters.childId]);

  return childIsDefault;
}
export function useTypeIsDefault() {
  const { filters } = useProviderContext();

  const typeIsDefault = useMemo(() => {
    return Object.values(filters.types).every((type) => !type);
  }, [filters.types]);

  return typeIsDefault;
}

export function useFiltersIsDefault() {
  const citizenIsDefault = useCitizenIsDefault();
  const addressIsDefault = useAddressIsDefault();
  const childIsDefault = useChildIsDefault();
  const typeIsDefault = useTypeIsDefault();

  return citizenIsDefault && addressIsDefault && childIsDefault && typeIsDefault;
}

export function filterDefault(formData: Application): Filters {
  return {
    address: { lat: formData.address?.latitude ?? 0, lng: formData.address?.longitude ?? 0 },
    citizen: true,
    childId: undefined,
    types: {
      homeBased: false,
      center: false,
      school: false,
      preschool: false,
      headStart: false,
    },
  };
}

export function filterCitizen(filters: Filters) {
  return (provider: Provider) => {
    return (
      provider.upk.on_provider_list ||
      provider.head_start.on_provider_list ||
      (provider.cccap.on_provider_list && filters.citizen)
    );
  };
}

export function filterTypes(filters: Filters) {
  return (provider: Provider) => {
    if (Object.values(filters.types).every((typeSelected) => typeSelected === false)) {
      return true;
    }

    const headStart = provider.head_start.on_provider_list && filters.types.headStart;
    const preschool = provider.preschool_provider && filters.types.preschool;
    const childProviderType = childProviderTypes(filters.types).includes(provider.provider_type);

    return headStart || preschool || childProviderType;
  };
}

export function mapCccapCitizenship(filters: Filters) {
  return (provider: Provider) => {
    if (filters.citizen) {
      return provider;
    }

    return {
      ...provider,
      cccap: {
        on_provider_list: false,
        part_time: false,
        half_day_am: false,
        half_day_pm: false,
        full_day: false,
        extended_day: false,
        fiscal_agreement: false,
        authorization_status: false,
      },
    };
  };
}

export function findProvider(allProviders: Provider[], provider: Provider) {
  return allProviders.find((initialProvider) => provider.id === initialProvider.id);
}
