import { ChangeEventHandler, useContext, useEffect, useState } from 'react';
import { Context } from '../Wrapper/Wrapper';
import { usePlacesWidget } from 'react-google-autocomplete';
import Textfield, { StyledTextField } from '../Textfield/Textfield';
import {
  useErrorController,
  address1HasError,
  address1HelperText,
  addressHasError,
} from '../../Assets/validationFunctions';
import { FormattedMessage } from 'react-intl';
import './Address.css';
import { Address as AddressData } from '../../Types/FormData';
import { STATES } from '../../Assets/states';
import { ADDRESS2_DETAILS } from '../../Assets/addressInputProps';
import ErrorBox from '../ErrorBox/ErrorBox';

const AddressError = () => {
  return (
    <ErrorBox>
      <div>
        <div>
          <FormattedMessage
            id="address.error.header"
            defaultMessage="This pilot application is for Adams County residents only. If you live outside Adams County, you can apply for assistance with child care and preschool here:"
          />
        </div>
        <ul className="address-error-list">
          <li>
            <strong>
              <FormattedMessage id="address.error.options.0.label" defaultMessage="Universal Preschool: " />
            </strong>
            <FormattedMessage id="address.error.options.0.phone.label" defaultMessage="Helpline " />
            <a href="tel:+13038665223">
              <FormattedMessage id="address.error.options.0.phone" defaultMessage="+13038665223" />
            </a>
            {' | '}
            <a href="https://upk.colorado.gov/">
              <FormattedMessage id="address.error.options.0.link" defaultMessage="Apply here" />
            </a>
            <p>
              <FormattedMessage
                id="address.error.options.0.text"
                defaultMessage="Free preschool: Universal Preschool (UPK) provides Colorado 4-year-olds with 15 hours per week of free preschool, and potentially extra hours with a qualifying factor.  Some households may qualify for 10 hours per week for your 3-year-old."
              />
            </p>
          </li>
          <li>
            <strong>
              <FormattedMessage
                id="address.error.options.1.label"
                defaultMessage="CCCAP (Colorado Child Care Assistance Program): "
              />
            </strong>
            <a
              href="https://peak.my.site.com/peak/s/benefit-information/benefit-detail?language=en_US&category=early-childhood-programs"
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage id="address.error.options.1.link" defaultMessage="Apply here" />
            </a>
            <p>
              <FormattedMessage
                id="address.error.options.1.nav.label"
                defaultMessage="For help applying, reach out to:"
              />
            </p>
            <ul>
              <li>
                <strong>
                  <FormattedMessage id="address.error.options.1.nav.0.label" defaultMessage="Benefits in Action: " />
                </strong>
                <a href="https://www.benefitsinaction.org/application-assistance" target="_blank" rel="noreferrer">
                  <FormattedMessage id="address.error.options.1.nav.0.website" defaultMessage="Website" />
                </a>
                {' | '}
                <a href="tel:+18884964252">
                  <FormattedMessage id="address.error.options.1.nav.0.phone" defaultMessage="+18884964252" />
                </a>
              </li>
              <li>
                <strong>
                  <FormattedMessage
                    id="address.error.options.1.nav.1.label"
                    defaultMessage="Colorado Shines / Mile High United Way: "
                  />
                </strong>
                <a href="https://unitedwaydenver.org/child-care-request/" target="_blank" rel="noreferrer">
                  <FormattedMessage id="address.error.options.1.nav.1.website" defaultMessage="Visit Website" />
                </a>
                {' | '}
                <a href="tel:+18773882273">
                  <FormattedMessage id="address.error.options.1.nav.1.phone" defaultMessage="+18773882273" />
                </a>
              </li>
            </ul>
            <p>
              <FormattedMessage
                id="address.error.options.1.post"
                defaultMessage="CCCAP provides help with child care costs so you can work, look for a job, or go to school."
              />
            </p>
          </li>
          <li>
            <strong>
              <FormattedMessage id="address.error.options.2.label" defaultMessage="Head Start: " />
            </strong>
            <a href="https://eclkc.ohs.acf.hhs.gov/">
              <FormattedMessage id="address.error.options.2.link" defaultMessage="Apply here" />
            </a>
            <p>
              <FormattedMessage
                id="address.error.options.2.text"
                defaultMessage="Free early childhood care and preschool. Head Start helps support school readiness for children from ages 3 to 5. If you are pregnant or have a child younger than 3, you may qualify for Early Head Start. "
              />
            </p>
          </li>
        </ul>
      </div>
    </ErrorBox>
  );
};

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
if (GOOGLE_API_KEY === undefined) {
  throw new Error('Google API key not set');
}

type ValidatedAddressParts = {
  zipcode: string;
  city: string;
  state: string;
  latitude: number;
  longitude: number;
  county: string;
};

type ValidateAddressReturn = { valid: true; parts: ValidatedAddressParts } | { valid: false };

export async function validateAddress(value: string): Promise<ValidateAddressReturn> {
  const res = await fetch(`https://content-addressvalidation.googleapis.com/v1:validateAddress?key=${GOOGLE_API_KEY}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      address: {
        regionCode: 'US',
        addressLines: [value],
      },
    }),
  });

  const resJson = await res.json();

  const autocompleteableFields: Partial<ValidatedAddressParts> = {};
  const parts: string[] = [];
  const requiredParts = ['street_number', 'route', 'locality', 'administrative_area_level_1', 'postal_code'];
  const relaventParts = { postal_code: 'zipcode', locality: 'city', administrative_area_level_1: 'state' };
  for (const component of resJson.result.address.addressComponents) {
    const componentType = component.componentType;

    if (
      requiredParts.includes(componentType) &&
      !parts.includes(componentType) &&
      (component.confirmationLevel === 'CONFIRMED' || component.confirmationLevel === 'UNCONFIRMED_BUT_PLAUSIBLE')
    ) {
      parts.push(componentType);
    }
    if (componentType in relaventParts && component.confirmationLevel === 'CONFIRMED') {
      // @ts-ignore
      autocompleteableFields[relaventParts[componentType]] = component.componentName.text;
    }
  }

  const valid = parts.length === requiredParts.length && resJson.result.uspsData.county !== undefined;

  if (valid) {
    const { latitude, longitude } = resJson.result.geocode.location as { latitude: number; longitude: number };

    autocompleteableFields.latitude = latitude;
    autocompleteableFields.longitude = longitude;
    autocompleteableFields.county = resJson.result.uspsData.county;

    // If the address is valid, then it is garenteed that the address has all of the parts.
    return { valid: true, parts: autocompleteableFields as ValidatedAddressParts };
  }

  return { valid: false };
}

type AddressProps = {
  submitted: number;
};
const Address = ({ submitted }: AddressProps) => {
  const { formData, setFormData } = useContext(Context);

  const [addressData, setAddressData] = useState<AddressData>(
    formData.address ?? {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      validAddress: false,
      latitude: 0,
      longitude: 0,
      county: '',
    },
  );
  const address1ErrorController = useErrorController(address1HasError, address1HelperText);
  const errorController = useErrorController(addressHasError, () => <AddressError />);

  const { ref, autocompleteRef } = usePlacesWidget({
    apiKey: GOOGLE_API_KEY,
    options: {
      types: ['address'],
      fields: ['formatted_address', 'address_components'],
      componentRestrictions: { country: 'us' },
    },
  });

  // onPlaceSelected did not work for the usePlacesWidget
  // Work around to add the event listener maually using the autocompleteRef
  // It is not best practice to use a ref as a useEffect dependency, because refs don't trigger rerenders
  // but a rerender will be triggered before the user selects an address
  useEffect(() => {
    if (!autocompleteRef.current) {
      return;
    }

    const eventListener = autocompleteRef.current.addListener('place_changed', () => {
      const place = autocompleteRef.current.getPlace();
      setAddressData({ ...addressData, address1: place.formatted_address });
    });

    return () => {
      eventListener.remove();
    };
  }, [autocompleteRef.current]);

  useEffect(() => {
    address1ErrorController.setSubmittedCount(submitted);
    errorController.setSubmittedCount(submitted);
  }, [submitted]);

  useEffect(() => {
    setFormData({ ...formData, address: addressData });
  }, [addressData]);

  useEffect(() => {
    errorController.updateError(null, formData);
  }, [formData]);

  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout>();

  function debounceValidateAddress(address1: string) {
    clearTimeout(debounceTimer);

    const timer = setTimeout(() => {
      validateAddress(address1)
        .then((res) => {
          address1ErrorController.updateError(!res.valid);

          if (res.valid) {
            setAddressData({
              ...addressData,
              ...res.parts,
              state: STATES[res.parts.state] ?? 'Colorado',
              validAddress: res.valid,
            });
          } else {
            setAddressData({
              ...addressData,
              city: '',
              state: '',
              zipcode: '',
              validAddress: res.valid,
            });
          }
        })
        .catch((err) => {
          address1ErrorController.updateError(true);
        });
    }, 500);

    setDebounceTimer(timer);
  }

  useEffect(() => {
    // prevent going to the next question before input is validated
    setAddressData({ ...addressData, validAddress: false });

    // don't request api if address is empty
    if (addressData.address1 === '') {
      address1ErrorController.updateError(true);
      return;
    }

    debounceValidateAddress(addressData.address1);
  }, [addressData.address1]);

  const handleFieldChangeGenerator = (
    field: keyof AddressData,
  ): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> => {
    return (event) => {
      const target = event.target;
      const value = target.value;

      setAddressData({ ...addressData, [field]: value });
    };
  };

  return (
    <div>
      <div className="address-input-container">
        <StyledTextField
          fullWidth
          onChange={handleFieldChangeGenerator('address1')}
          label={<FormattedMessage id="address.addressLine1" defaultMessage="Address Line 1*" />}
          color="secondary"
          variant="outlined"
          inputRef={ref}
          value={addressData.address1}
          error={address1ErrorController.showError}
          helperText={address1ErrorController.showError && address1ErrorController.message(addressData.address1)}
        />
      </div>
      <div className="address-input-container">
        <Textfield
          data={addressData}
          index={0}
          handleTextfieldChange={handleFieldChangeGenerator('address2')}
          componentDetails={ADDRESS2_DETAILS}
          submitted={address1ErrorController.submittedCount}
        />
      </div>
      {errorController.showError && errorController.message(null)}
    </div>
  );
};

export default Address;
