import { CssBaseline, createTheme, ThemeProvider } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, Navigate, Routes, Route, useSearchParams } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';
import { Context } from './Components/Wrapper/Wrapper';
import FetchScreen from './Components/FetchScreen/FetchScreen';
import QuestionComponentContainer from './Components/QuestionComponentContainer/QuestionComponentContainer';
import LandingPage from './Components/LandingPage/LandingPage';
import HouseholdDataBlock from './Components/HouseholdDataBlock/HouseholdDataBlock.js';
import ProgressBar from './Components/ProgressBar/ProgressBar';
import LoadingPage from './Components/LoadingPage/LoadingPage.tsx';
import SelectLanguagePage from './Components/SelectLanguagePage/SelectLanguagePage.tsx';
import { updateScreen } from './Assets/updateScreen.ts';
import { getStepDirectory, STARTING_QUESTION_NUMBER, getStepNumber } from './Assets/stepDirectory';
import Box from '@mui/material/Box';
import { BrandedFooter, BrandedHeader } from './Components/Referrer/Referrer.tsx';
import dataLayerPush from './Assets/analytics.ts';
import pageTitleTags, { StepName } from './Assets/pageTitleTags.ts';
import './App.css';
import { ErrorController } from './Types/ErrorController.ts';
import { Member } from './Types/FormData.ts';
import FinalPage from './Components/FinalPage/FinalPage.tsx';
import PilotOver from './Components/PilotOver/PilotOver';
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY + '=');

const PILOT_OVER = process.env.REACT_APP_PILOT_OVER === 'true';

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlSearchParams = location.search;
  const [searchParams] = useSearchParams();
  const isTest = searchParams.get('test') ? true : false;
  const rawExternalId = searchParams.get('externalid');
  const externalId = rawExternalId !== null ? rawExternalId : undefined;
  const {
    locale,
    formData,
    setFormData,
    styleOverride,
    setTheme: changeTheme,
    pageIsLoading,
    getReferrer,
  } = useContext(Context);
  const [totalSteps, setTotalSteps] = useState(
    getStepDirectory(formData.referrerCode).length + STARTING_QUESTION_NUMBER,
  );
  const [theme, setTheme] = useState(createTheme(styleOverride));

  useEffect(() => {
    changeTheme(getReferrer('theme') as 'default' | 'twoOneOne');
  }, [getReferrer('theme')]);

  useEffect(() => {
    setTotalSteps(getStepDirectory(formData.referrerCode).length + STARTING_QUESTION_NUMBER);
  }, [formData.referrerCode]);

  useEffect(() => {
    setTheme(createTheme(styleOverride));
  }, [styleOverride]);

  useEffect(() => {
    dataLayerPush({
      event: 'Page Change',
      url: window.location.pathname + window.location.search,
    });
  }, [location.pathname]);

  useEffect(() => {
    const stepString = location.pathname.split('/').filter((string) => string.includes('step'))[0] as StepName;
    const isConfirmationPage = location.pathname.includes('confirm-information');
    const isResultsPage = location.pathname.includes('results');

    if (isConfirmationPage) {
      document.title = pageTitleTags['confirm-information'];
    } else if (isResultsPage) {
      document.title = pageTitleTags['results'];
    } else if (pageTitleTags[stepString]) {
      document.title = pageTitleTags[stepString];
    } else {
      document.title = 'MyFriendBen';
    }
  }, [location]);

  useEffect(() => {
    const referrerParam = searchParams.get('referrer');
    const utmParam = searchParams.get('utm_source');

    // use referrer if there is a referrer, otherwise use utm source
    const referrer = referrerParam ?? utmParam ?? '';

    setFormData({
      ...formData,
      isTest: isTest,
      externalId: externalId,
      referrerCode: referrer,
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleTextfieldChange = (event: Event) => {
    const { name, value } = event.target as HTMLInputElement;
    const numberUpToEightDigitsLongRegex = /^\d{0,8}$/; //for zipcode
    const numberUpToTenDigitsLongRegex = /^\d{0,10}$/; //for phone number
    const isFirstLastOrEmail = name === 'firstName' || name === 'lastName' || name === 'email';

    if (isFirstLastOrEmail) {
      const updatedSignUpInfo = { ...formData.signUpInfo, [name]: value };
      setFormData({ ...formData, signUpInfo: updatedSignUpInfo });
      return;
    }

    if (name === 'phone' && numberUpToTenDigitsLongRegex.test(value)) {
      const updatedSignUpInfo = { ...formData.signUpInfo, [name]: value };
      setFormData({ ...formData, signUpInfo: updatedSignUpInfo });
      return;
    }

    if (name === 'otherSource') {
      setFormData({ ...formData, [name]: value });
    } else if (numberUpToEightDigitsLongRegex.test(value)) {
      if (value === '') {
        setFormData({ ...formData, [name]: value });
        return;
      }
      setFormData({ ...formData, [name]: Number(value) });
    }
  };

  const handleCheckboxChange = (event: React.FormEvent<HTMLInputElement>) => {
    //the value is the name of the formData property for everything except the commConsent
    const { value, name } = event.target as HTMLInputElement;

    if (name === 'commConsent') {
      const updatedCommConsent = !formData.signUpInfo.commConsent;
      const updatedSignUpInfo = { ...formData.signUpInfo, commConsent: updatedCommConsent };
      setFormData({ ...formData, signUpInfo: updatedSignUpInfo });
      return;
    } else {
      // @ts-ignore
      setFormData({ ...formData, [value]: !formData[value] });
    }
  };

  const handleRadioButtonChange = (event: Event) => {
    const { name, value } = event.target as HTMLInputElement;
    let boolValue = value === 'true';
    setFormData({ ...formData, [name]: boolValue });
  };

  const handleNoAnswerChange = (event: Event) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormData({ ...formData, [name]: value });
  };

  const handleContinueSubmit = (
    event: Event,
    errorController: ErrorController,
    inputToBeValidated: string | number,
    stepId: number,
    questionName: string,
    uuid: string,
  ) => {
    event.preventDefault();
    errorController.incrementSubmitted();
    const hasError = errorController.updateError(inputToBeValidated, formData);
    if (hasError) {
      return;
    }

    // finally runs a higher rist of entering incorrect info, but it prevents soft locks.
    updateScreen(uuid, formData, locale).finally(() => {
      if (questionName === 'householdSize') {
        navigate(`/${uuid}/step-${stepId + 1}/1`);
        return;
      }

      //this is just for development so that we don't crash and loop instead, will delete later
      if (stepId === STARTING_QUESTION_NUMBER + getStepDirectory(formData.referrerCode).length - 1) {
        navigate(`/${uuid}/final-page`);
        return;
      }

      navigate(`/${uuid}/step-${stepId + 1}`);
    });
  };

  const handleIncomeStreamsSubmit = (validatedIncomeStreams: IncomeStream[], stepId: number, uuid: string) => {
    const updatedFormData = { ...formData, incomeStreams: validatedIncomeStreams };
    updateScreen(uuid, updatedFormData, locale);
    setFormData(updatedFormData);
    navigate(`/${uuid}/step-${stepId + 1}`);
  };

  const handleExpenseSourcesSubmit = (validatedExpenseSources: Expense[], stepId: number, uuid: string) => {
    const updatedFormData = { ...formData, expenses: validatedExpenseSources };
    updateScreen(uuid, updatedFormData, locale);
    setFormData(updatedFormData);
    navigate(`/${uuid}/step-${stepId + 1}`);
  };

  const handleHouseholdDataSubmit = (memberData: Member, stepId: number, uuid: string) => {
    const updatedMembers = [...formData.members];
    updatedMembers[stepId] = memberData;
    const updatedFormData = { ...formData, members: updatedMembers };
    updateScreen(uuid, updatedFormData, locale);
    setFormData(updatedFormData);
  };

  if (PILOT_OVER) {
    return (
      <ThemeProvider theme={theme}>
        <BrandedHeader handleTextFieldChange={handleTextfieldChange} />
        <PilotOver />
      </ThemeProvider>
    );
  }

  if (pageIsLoading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path=":uuid">
            <Route path="" element={<FetchScreen />} />
            <Route path="*" element={<FetchScreen />} />
          </Route>
          <Route path="" element={<LoadingPage />} />
          <Route path="*" element={<LoadingPage />} />
        </Routes>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <CssBaseline />
        <BrandedHeader handleTextFieldChange={handleTextfieldChange} />
        <Box className="main-max-width">
          <Routes>
            <Route path="/step-1" element={<ProgressBar step={1} />} />
            <Route path="/step-2" element={<ProgressBar step={2} />} />
            <Route path="/:uuid/step-:id" element={<ProgressBar />} />
            <Route path="/:uuid/step-:id/:page" element={<ProgressBar />} />
            <Route path="/:uuid/confirm-information" element={<ProgressBar step={totalSteps} />} />
            <Route path="*" element={<></>} />
          </Routes>
          <Routes>
            <Route path="/" element={<Navigate to={`/step-1${urlSearchParams}`} replace />} />
            <Route path="/step-1" element={<SelectLanguagePage />} />
            <Route path="/step-2" element={<LandingPage />} />
            <Route path=":uuid">
              <Route path="" element={<Navigate to="/step-1" replace />} />
              <Route path="step-1" element={<SelectLanguagePage />} />
              <Route path="step-2" element={<LandingPage />} />
              <Route
                path={`step-${getStepNumber('householdData', formData.referrerCode)}/:page`}
                element={
                  <HouseholdDataBlock
                    key={window.location.href}
                    handleHouseholdDataSubmit={handleHouseholdDataSubmit}
                  />
                }
              />
              <Route
                path="step-:id"
                element={
                  <QuestionComponentContainer
                    key={window.location.href}
                    handleTextfieldChange={handleTextfieldChange}
                    handleContinueSubmit={handleContinueSubmit}
                    handleRadioButtonChange={handleRadioButtonChange}
                    handleNoAnswerChange={handleNoAnswerChange}
                    handleIncomeStreamsSubmit={handleIncomeStreamsSubmit}
                    handleExpenseSourcesSubmit={handleExpenseSourcesSubmit}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                }
              />
              <Route path="final-page" element={<FinalPage />} />
              <Route path="*" element={<Navigate to="/step-1" replace />} />
            </Route>
            <Route path="*" element={<Navigate to={`/step-1${urlSearchParams}`} replace />} />
          </Routes>
          <div className="push"></div>
        </Box>
        <BrandedFooter />
      </div>
    </ThemeProvider>
  );
};

export default App;
