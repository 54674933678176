import { useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getScreen } from '../../apiCalls.js';
import { Context } from '../Wrapper/Wrapper.tsx';
import LoadingPage from '../LoadingPage/LoadingPage.tsx';
import type { ApiApplication, ApiChildMember, ReadOnlyChildMember } from '../../Types/ApiFormData.ts';
import type {
  Address,
  AdultMember,
  Application,
  ChildMember,
  ContactInfo,
  Income,
  LegalConsent,
  Member,
  OtherConditions,
  SelectedProvider,
} from '../../Types/FormData.ts';

const FetchScreen = () => {
  const { setFormData, screenDoneLoading } = useContext(Context);
  const { uuid } = useParams();
  const navigate = useNavigate();

  const fetchScreen = async (uuid: string) => {
    try {
      const response = await getScreen(uuid);
      createFormData(response);
    } catch (err) {
      navigate('/step-1');
      return;
    }
    screenDoneLoading();
  };

  const createFormData = (response: ApiApplication) => {
    let address: Address | undefined = undefined;
    if (response.address !== null) {
      address = {
        address1: response.address.address_1,
        address2: response.address.address_2,
        city: response.address.city,
        state: response.address.state,
        zipcode: response.address.zipcode,
        validAddress: false,
        latitude: response.address.latitude,
        longitude: response.address.longitude,
        county: response.address.county,
      };
    }

    let otherConditions: OtherConditions | undefined = undefined;
    if (response.other_conditions !== null) {
      otherConditions = {
        homeless: response.other_conditions.homeless,
        hasTanf: response.other_conditions.has_tanf,
        hasSnap: response.other_conditions.has_snap,
        hasSsi: response.other_conditions.has_ssi,
      };
    }

    let contactInformation: ContactInfo | undefined = undefined;
    if (response.contact_information !== null) {
      contactInformation = {
        firstName: response.contact_information.first_name,
        lastName: response.contact_information.last_name,
        email: response.contact_information.email,
        phone: response.contact_information.phone,
        weekdaysMornings: response.contact_information.weekdays_mornings,
        weekdaysLunch: response.contact_information.weekdays_lunch,
        weekdaysAfternoons: response.contact_information.weekdays_afternoons,
        weekdaysEvenings: response.contact_information.weekdays_evenings,
        saturdays: response.contact_information.saturdays,
        sundays: response.contact_information.sundays,
      };
    }

    let legalConsent: LegalConsent | undefined = undefined;
    if (response.legal_consent !== null) {
      legalConsent = {
        infoCorrect: response.legal_consent.info_correct,
        readPoliciesAndTerms: response.legal_consent.read_policies_and_terms,
        thirteenOrOlder: response.legal_consent.thirteen_or_older,
        electronicSignature: response.legal_consent.electronic_signature,
        initials: response.legal_consent.initials,
        signature: response.legal_consent.signature,
      };
    }

    const members: Member[] = [];
    for (const member of response.members) {
      let childMember: ChildMember | undefined = undefined;
      let adultMember: AdultMember | undefined = undefined;
      if (member.child_member !== null) {
        const child = member.child_member as ApiChildMember & ReadOnlyChildMember;
        const careNeeds = child.child_care_needs;

        const selectedProviders: SelectedProvider[] = [];
        for (const provider of child.selected_providers) {
          selectedProviders.push({
            name: provider.name,
            id: provider.license_number,
          });
        }

        const initialSelectedProviders: SelectedProvider[] = [];
        for (const provider of child.initial_selected_providers) {
          initialSelectedProviders.push({
            name: provider.name,
            id: provider.license_number,
          });
        }

        const [year, month, day] = child.date_of_birth.split('-');

        childMember = {
          frontEndId: child.front_end_id,
          firstName: child.first_name,
          lastName: child.last_name,
          birthYear: year,
          birthMonth: month,
          birthDay: day,
          seekingChildCare: child.seeking_child_care,
          iep: child.iep,
          limitedEnglish: child.limited_english,
          unableToCareForThemself: child.unable_to_care_for_themself,
          childCareNeeds: {
            homeBased: careNeeds.home_based,
            center: careNeeds.center,
            school: careNeeds.school,
            preschool: careNeeds.preschool,
            headStart: careNeeds.head_start,
            familyOrFriend: careNeeds.family_or_friend,
            exploring: careNeeds.exploring,
            neededCare: careNeeds.needed_care,
            startDate: careNeeds.start_date,
          },
          selectedProviders: selectedProviders,
          initialSelectedProviders: initialSelectedProviders,
          eligibility: child.eligibility ?? undefined,
        };
      }

      if (member.adult_member !== null) {
        const adult = member.adult_member;

        const incomes: Income[] = [];
        for (const income of adult.income) {
          incomes.push({
            type: income.type,
            amount: income.amount,
            frequency: income.frequency,
            hoursWorked: income.hours_worked ?? undefined,
          });
        }

        adultMember = {
          employed: adult.employed,
          selfEmployed: adult.self_employed,
          searchingForJob: adult.searching_for_job,
          postSecondaryStudent: adult.post_secondary_student,
          training: adult.training,
          englishSecondLanguageStudent: adult.english_second_language_student,
          highSchoolStudent: adult.high_school_student,
          middleSchoolStudent: adult.middle_school_student,
          disabled: adult.disabled,
          nationalGuard: adult.national_guard,
          militaryReserves: adult.millitary_reserves,
          militaryActive: adult.millitary_active,
          pregnant: adult.pregnant,
          breastFeeding: adult.breast_feeding,
          noQualifyingFactor: !adult.has_qualifying_factor,
          hasIncome: incomes.length > 0,
          income: incomes,
        };
      }

      members.push({
        relationship: member.relationship ?? '',
        childMember: childMember,
        adultMember: adultMember,
      });
    }

    const initialFormData: Application = {
      isTest: response.is_test ?? false,
      externalId: response.external_id ?? undefined,
      adamsWithChild: response.adams_with_child ?? false,
      householdSize: response.household_size ?? 0,
      schoolDistrict: response.school_district ?? 'adams_12',
      referrerCode: response.referrer_code ?? undefined,
      address: address,
      otherConditions: otherConditions,
      contactInformation: contactInformation,
      legalConsent: legalConsent,
      members: members,
    };

    setFormData({ ...initialFormData });
  };

  useEffect(() => {
    // https://stackoverflow.com/questions/20041051/how-to-judge-a-string-is-uuid-type
    const uuidRegx = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    if (uuid === undefined || !uuid.match(uuidRegx)) {
      screenDoneLoading();
      return;
    }
    fetchScreen(uuid);
  }, [uuid]);

  return <LoadingPage />;
};

export default FetchScreen;
