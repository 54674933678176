import React, { useEffect, useState, PropsWithChildren } from 'react';
import useStyle from '../../Assets/styleController';
import { IntlProvider } from 'react-intl';
import { WrapperContext } from '../../Types/WrapperContext';
import { Application } from '../../Types/FormData';
import { getTranslations } from '../../apiCalls';
import useReferrer from '../Referrer/referrerHook';
import languageOptions, { Language } from '../../Assets/languageOptions';

const initialFormData: Application = {
  isTest: false,
  members: [],
};

export const Context = React.createContext<WrapperContext>({} as WrapperContext);

const Wrapper = (props: PropsWithChildren<{}>) => {
  const [translationsLoading, setTranslationsLoading] = useState<boolean>(true);
  const [screenLoading, setScreenLoading] = useState<boolean>(true);
  const [pageIsLoading, setPageIsLoading] = useState<boolean>(true);

  const screenDoneLoading = () => {
    setScreenLoading(false);
  };

  useEffect(() => {
    if (!screenLoading && !translationsLoading) {
      setPageIsLoading(false);
    }
  }, [screenLoading, translationsLoading]);

  let [translations, setTranslations] = useState<Record<Language, { [key: string]: string }> | undefined>(undefined);

  useEffect(() => {
    getTranslations().then((value) => {
      setTranslations(value);
    });
  }, []);
  let defaultLanguage = localStorage.getItem('language') as Language;

  const userLanguage = navigator.language.toLowerCase() as Language;

  const verifyLanguage = (language: Language) => {
    return Object.keys(languageOptions).some((lang) => language.slice(0, 2) === lang) ? language.slice(0, 2) : 'en-us';
  };

  defaultLanguage = defaultLanguage ? defaultLanguage : (verifyLanguage(userLanguage) as Language);

  const pathname = window.location.pathname;

  const [theme, setTheme, styleOverride] = useStyle('default');

  const languages = Object.keys(languageOptions) as Language[];
  languages.forEach((lang: Language) => {
    if (pathname.includes(`/${lang}`)) {
      defaultLanguage = lang;
    }
  });

  const [locale, setLocale] = useState<Language>(defaultLanguage);
  const [messages, setMessages] = useState({});

  useEffect(() => {
    if (translations) {
      localStorage.setItem('language', locale);
    }

    if (translations === undefined) {
      setMessages({});
      return;
    } else {
      setTranslationsLoading(false);
    }

    for (const lang of Object.keys(translations) as Language[]) {
      if (locale.toLocaleLowerCase() === lang) {
        setMessages(translations[lang]);
        return;
      }
    }
    setMessages(translations['en-us'] ?? {});
  }, [locale, translations]);

  const selectLanguage = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const newLocale = target.value;

    if (languages.every((lang) => lang !== newLocale)) {
      setLocale('en-us');
      return;
    }

    setLocale(newLocale as Language);
  };

  const [formData, setFormData] = useState<Application>(initialFormData);
  const { getReferrer, setReferrer } = useReferrer(formData.referrerCode);

  useEffect(() => {
    setReferrer(formData.referrerCode);
  }, [formData.referrerCode]);

  return (
    <Context.Provider
      value={{
        locale,
        selectLanguage,
        formData,
        setFormData,
        theme,
        setTheme,
        styleOverride,
        pageIsLoading,
        screenDoneLoading,
        getReferrer,
      }}
    >
      <IntlProvider locale={locale} messages={messages} defaultLocale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default Wrapper;
