import questions from './questions';
import type { QuestionName } from '../Types/Questions';

export const STARTING_QUESTION_NUMBER = 3;
const defaultStepDirectory: QuestionName[] = [
  'address',
  'householdSize',
  'householdData',
  'schoolDistrict',
  'conditions',
  'contact',
  'confirmation',
  'providers',
  'legal',
];

export function getStepDirectory(referrer: string | undefined) {
  return defaultStepDirectory;
}

export function getStepNumber(name: QuestionName, referrer: string | undefined) {
  const stepNumber = getStepDirectory(referrer).findIndex((question) => question === name);
  return stepNumber + STARTING_QUESTION_NUMBER;
}

export function getQuestion(stepNumber: number, referrer: string | undefined) {
  const stepName = getStepDirectory(referrer)[stepNumber - STARTING_QUESTION_NUMBER];
  return questions[stepName];
}
