import {
  Checkbox,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Switch,
  styled,
  InputAdornment,
} from '@mui/material';
import { filterDefault, useChildIsDefault, useCitizenIsDefault, useProviderContext, useTypeIsDefault } from './hooks';
import { FormattedMessage } from 'react-intl';
import { PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { FormattedMessageType } from '../../Types/Questions';
import FilterListIcon from '@mui/icons-material/FilterList';
import './ProviderFilters.css';
import { Context } from '../Wrapper/Wrapper';
import { StyledTextField } from '../Textfield/Textfield';
import { usePlacesWidget } from 'react-google-autocomplete';
import { validateAddress } from '../Address/Address';
import childCareOptions from '../../Assets/ChildBlockAssets/childCareOptions';
import { YouMarker } from './ProviderMapMarkers';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
if (GOOGLE_API_KEY === undefined) {
  throw new Error('Google API key not set');
}

function useFilterControls() {
  const { filters, setFilters } = useProviderContext();

  function toggleCitizenship() {
    setFilters({ ...filters, citizen: !filters.citizen });
  }

  function setSelectedChild(childId: string | undefined) {
    setFilters({ ...filters, childId: childId });
  }

  function updateType(type: string) {
    if (!(type in filters.types)) {
      return;
    }

    // @ts-ignore already checked if type is in filter.types
    setFilters({ ...filters, types: { ...filters.types, [type]: !filters.types[type] } });
  }

  function setLatLng(lat: number, lng: number) {
    setFilters({ ...filters, address: { lat, lng } });
  }

  return { toggleCitizenship, setSelectedChild, updateType, setLatLng };
}

const CitizenFilter = () => {
  const { filters } = useProviderContext();
  const { toggleCitizenship } = useFilterControls();

  return (
    <div>
      <FormControlLabel
        control={<Switch />}
        checked={!filters.citizen}
        onChange={toggleCitizenship}
        label={
          <FormattedMessage
            id="providers.filters.citizen.label"
            defaultMessage="Show providers available if my child is not a U.S. citizen, green card holder, or other qualified noncitizen."
          />
        }
      />
    </div>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)({
  marginLeft: -5,
});

const ChildFilter = () => {
  const { filters, eligibility } = useProviderContext();
  const { setSelectedChild } = useFilterControls();

  return (
    <div>
      <div>
        <strong>
          <FormattedMessage
            id="providers.filters.child.header"
            defaultMessage="Filter by recommended providers for a child:"
          />
        </strong>
      </div>
      <RadioGroup
        aria-labelledby="Filter by child"
        name="child-filter"
        value={filters.childId ?? 'none'}
        onChange={(event) => {
          let childId: string | undefined = event.target.value;
          if (childId === 'none') {
            childId = undefined;
          }
          setSelectedChild(childId);
        }}
      >
        <StyledFormControlLabel
          value="none"
          control={<Radio />}
          label={<FormattedMessage id="providers.filters.child.none" defaultMessage="All" />}
        />
        {eligibility.map((eligibility, index) => {
          return (
            <StyledFormControlLabel
              value={eligibility.child_id}
              control={<Radio />}
              label={eligibility.name}
              key={index}
            />
          );
        })}
      </RadioGroup>
    </div>
  );
};

const TypeFilter = () => {
  const { filters } = useProviderContext();
  const { updateType } = useFilterControls();

  return (
    <FormControl>
      {Object.entries(filters.types).map((entry) => {
        const [key, value] = entry;
        return (
          <FormControlLabel
            control={
              <Checkbox
                onClick={() => {
                  updateType(key);
                }}
              />
            }
            checked={value}
            // @ts-ignore
            label={childCareOptions[key]}
            key={key}
          />
        );
      })}
    </FormControl>
  );
};

const AddressFilter = () => {
  const { formData } = useContext(Context);

  const { ref, autocompleteRef } = usePlacesWidget({
    apiKey: GOOGLE_API_KEY,
    options: {
      types: ['address'],
      fields: ['formatted_address', 'address_components'],
      componentRestrictions: { country: 'us' },
    },
  });

  // onPlaceSelected did not work for the usePlacesWidget
  // Work around to add the event listener maually using the autocompleteRef
  // It is not best practice to use a ref as a useEffect dependency, because refs don't trigger rerenders
  // but a rerender will be triggered before the user selects an address
  useEffect(() => {
    if (!autocompleteRef.current) {
      return;
    }

    const eventListener = autocompleteRef.current.addListener('place_changed', () => {
      const place = autocompleteRef.current.getPlace();
      setAddress(place.formatted_address);
    });

    return () => {
      eventListener.remove();
    };
  }, [autocompleteRef.current]);

  const [address, setAddress] = useState(formData.address?.address1 ?? '');
  const { setLatLng } = useFilterControls();

  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout>();

  function debounceValidateAddress(address1: string) {
    clearTimeout(debounceTimer);

    const timer = setTimeout(() => {
      validateAddress(address1).then((res) => {
        if (res.valid) {
          setLatLng(res.parts.latitude, res.parts.longitude);
        }
      });
    }, 500);

    setDebounceTimer(timer);
  }

  useEffect(() => {
    // don't request api if address is empty
    if (address === '') {
      return;
    }

    debounceValidateAddress(address);
  }, [address]);

  return (
    <StyledTextField
      onChange={(event) => {
        const target = event.target.value;

        setAddress(target);
      }}
      label={<FormattedMessage id="providers.filter.address" defaultMessage="Address" />}
      color="secondary"
      variant="outlined"
      inputRef={ref}
      value={address}
      sx={{ width: '25rem', margin: 0 }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <YouMarker />
          </InputAdornment>
        ),
      }}
    />
  );
};

type FilterPopUpProps = {
  handleClose: () => void;
} & PropsWithChildren;

const FilterPopUp = ({ children, handleClose }: FilterPopUpProps) => {
  return (
    <span className="provider-filter-popup-relative-container">
      <div className="provider-filter-popup-hidden-background" onClick={handleClose}></div>
      <div className="provider-filter-popup-container">{children}</div>
    </span>
  );
};

const ResetButton = () => {
  const { formData } = useContext(Context);
  const { setFilters } = useProviderContext();

  return (
    <button
      onClick={() => {
        setFilters(filterDefault(formData));
      }}
      className="provder-filters-reset"
    >
      <FormattedMessage id="provider.filters.reset" defaultMessage="Reset All Filters" />
    </button>
  );
};

type FilterButtonProps = {
  open: boolean;
  handleClick: () => void;
  text: FormattedMessageType;
  isDefault: boolean;
};
const FilterButton = ({ open, text, handleClick, isDefault }: FilterButtonProps) => {
  const iconClassName = useMemo(() => {
    let className = 'provider-filter-button-icon';

    if (open) {
      className += ' open';
    }

    return className;
  }, [open]);

  const containerClassName = useMemo(() => {
    let className = 'provider-filter-button';

    if (!isDefault) {
      className += ' changed';
    }

    return className;
  }, [isDefault]);
  return (
    <button onClick={handleClick} className={containerClassName}>
      <span>{text}</span>
      <KeyboardArrowRightIcon className={iconClassName} />
    </button>
  );
};

const ProviderFilter = () => {
  const [childFilterOpen, setChildFilterOpen] = useState(false);
  const childIsDefault = useChildIsDefault();
  const [citizenFilterOpen, setCitizenFilterOpen] = useState(false);
  const citizenIsDefault = useCitizenIsDefault();
  const [typeFilterOpen, setTypeFilterOpen] = useState(false);
  const typeIsDefault = useTypeIsDefault();

  function closeAllPopUps() {
    setChildFilterOpen(false);
    setCitizenFilterOpen(false);
    setTypeFilterOpen(false);
  }

  function handleCloseGenerator(open: boolean, setOpen: (open: boolean) => void) {
    return () => {
      closeAllPopUps();
      setOpen(!open);
    };
  }

  return (
    <div className="provider-filter-container">
      <div className="provider-group-container">
        <FilterListIcon className="provider-filter-icon" />
        {citizenFilterOpen && (
          <FilterPopUp handleClose={() => setCitizenFilterOpen(false)}>
            <CitizenFilter />
          </FilterPopUp>
        )}
        <FilterButton
          handleClick={handleCloseGenerator(citizenFilterOpen, setCitizenFilterOpen)}
          open={citizenFilterOpen}
          text={<FormattedMessage id="provider.filters.button.citizen" defaultMessage="Citizen" />}
          isDefault={citizenIsDefault}
        />
        {childFilterOpen && (
          <FilterPopUp handleClose={() => setChildFilterOpen(false)}>
            <ChildFilter />
          </FilterPopUp>
        )}
        <FilterButton
          handleClick={handleCloseGenerator(childFilterOpen, setChildFilterOpen)}
          open={childFilterOpen}
          text={<FormattedMessage id="provider.filters.button.child" defaultMessage="Child" />}
          isDefault={childIsDefault}
        />
        {typeFilterOpen && (
          <FilterPopUp handleClose={() => setTypeFilterOpen(false)}>
            <TypeFilter />
          </FilterPopUp>
        )}
        <FilterButton
          handleClick={handleCloseGenerator(typeFilterOpen, setTypeFilterOpen)}
          open={typeFilterOpen}
          text={<FormattedMessage id="provider.filters.button.type" defaultMessage="Type" />}
          isDefault={typeIsDefault}
        />
        <ResetButton />
      </div>
      <AddressFilter />
    </div>
  );
};

export default ProviderFilter;
