import { ChangeEvent, ChangeEventHandler, useContext, useEffect, useState } from 'react';
import { Context } from '../Wrapper/Wrapper';
import Textfield from '../Textfield/Textfield';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  displayEmailHelperText,
  displayPhoneHasErrorHelperText,
  emailHasError,
  emptyError,
  firstNameRequiredMessage,
  lastNameRequiredMessage,
  phoneHasError,
  atLeastOneContactTimeOptionWasSelected,
} from '../../Assets/validationFunctions';
import CheckboxGroup from '../CheckboxGroup/CheckboxGroup';
import { contactTimeOptions } from './contactTimeOptions';
import { ContactInfo } from '../../Types/FormData';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import './Contact.css';

const FIRST_NAME_PROPS = {
  componentType: 'Textfield',
  inputType: 'text',
  inputName: 'firstName',
  numericField: false,
  inputLabel: <FormattedMessage id="contact.firstName" defaultMessage="First Name" />,
  inputError: emptyError,
  inputHelperText: firstNameRequiredMessage,
};

const LAST_NAME_PROPS = {
  componentType: 'Textfield',
  inputType: 'text',
  inputName: 'lastName',
  numericField: false,
  inputLabel: <FormattedMessage id="contact.lastName" defaultMessage="Last Name" />,
  inputError: emptyError,
  inputHelperText: lastNameRequiredMessage,
};
const EMAIL_PROPS = {
  componentType: 'Textfield',
  inputType: 'text',
  inputName: 'email',
  numericField: false,
  inputLabel: <FormattedMessage id="contact.email" defaultMessage="Email" />,
  inputError: emailHasError,
  inputHelperText: displayEmailHelperText,
};
const PHONE_PROPS = {
  componentType: 'Textfield',
  inputType: 'text',
  inputName: 'phone',
  numericField: false,
  inputLabel: <FormattedMessage id="contact.phone" defaultMessage="Phone" />,
  inputError: phoneHasError,
  inputHelperText: displayPhoneHasErrorHelperText,
};

type ContactProps = {
  submitted: number;
};

const Contact = ({ submitted }: ContactProps) => {
  const { formData, setFormData } = useContext(Context);
  const intl = useIntl();

  const contactInformation = formData.contactInformation;
  const [firstName, setFirstName] = useState(contactInformation?.firstName ?? '');
  const [lastName, setLastName] = useState(contactInformation?.lastName ?? '');
  const [email, setEmail] = useState(contactInformation?.email ?? '');
  const [phone, setPhone] = useState(contactInformation?.phone ?? '');
  const [timeToContact, setTimeToContact] = useState({
    weekdaysMornings: contactInformation?.weekdaysMornings ?? false,
    weekdaysLunch: contactInformation?.weekdaysLunch ?? false,
    weekdaysAfternoons: contactInformation?.weekdaysAfternoons ?? false,
    weekdaysEvenings: contactInformation?.weekdaysEvenings ?? false,
    saturdays: contactInformation?.saturdays ?? false,
    sundays: contactInformation?.sundays ?? false,
  });
  const [timeToContactHasError, setTimeToContactHasError] = useState(false);

  useEffect(() => {
    setFormData({
      ...formData,
      contactInformation: {
        ...formData.contactInformation,
        firstName,
        lastName,
        email,
        phone,
        ...timeToContact,
      },
    });
  }, [firstName, lastName, email, phone, timeToContact]);

  useEffect(() => {
    if (formData.contactInformation && atLeastOneContactTimeOptionWasSelected(timeToContact) === false) {
      setTimeToContactHasError(true);
      return;
    }

    setTimeToContactHasError(false);
  }, [submitted, timeToContact]);

  const handleChange = (
    setState: (str: string) => void,
  ): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> => {
    return (event) => {
      const value = event.target.value;

      setState(value);
    };
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target) {
      const targetName = event.target.name as keyof Omit<ContactInfo, 'firstName' | 'lastName' | 'email' | 'phone'>;
      setTimeToContact({
        ...timeToContact,
        [targetName]: !timeToContact[targetName],
      });
    }
  };

  const displayTimeToContactError = (submitted: number, timeToContactHasError: boolean) => {
    if (submitted && timeToContactHasError) {
      const translatedString = intl.formatMessage({
        id: 'contact.displayTimeToContactErr',
        defaultMessage: 'Please select a time to contact',
      });
      return <ErrorMessage error={translatedString} />;
    }
  };

  return (
    <div>
      <div>
        <Textfield
          componentDetails={FIRST_NAME_PROPS}
          submitted={submitted}
          data={{ firstName }}
          handleTextfieldChange={handleChange(setFirstName)}
          index="0"
        />
        <Textfield
          componentDetails={LAST_NAME_PROPS}
          submitted={submitted}
          data={{ lastName }}
          handleTextfieldChange={handleChange(setLastName)}
          index="0"
        />
      </div>
      <div>
        <Textfield
          componentDetails={EMAIL_PROPS}
          submitted={submitted}
          data={{ email }}
          handleTextfieldChange={handleChange(setEmail)}
          index="0"
        />
        <Textfield
          componentDetails={PHONE_PROPS}
          submitted={submitted}
          data={{ phone }}
          handleTextfieldChange={handleChange(setPhone)}
          index="0"
        />
      </div>
      <div>
        <h2 className="question-label">
          <FormattedMessage
            id="contact.timeToContactQ"
            defaultMessage="When is the best time to reach you by phone or text?"
          />
        </h2>
        <p>
          <FormattedMessage id="contact.checkAllThatApply" defaultMessage="Check all that apply." />
        </p>
        <CheckboxGroup
          stateObj={timeToContact}
          memberType="adultMember"
          inputName="contactInformation"
          options={contactTimeOptions}
          handleCheckboxChangeFunction={handleCheckboxChange}
        />
      </div>
      <div className="error-msg-height">{displayTimeToContactError(submitted, timeToContactHasError)}</div>
    </div>
  );
};

export default Contact;
