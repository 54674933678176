import { address2HasError, address2HelperText } from './validationFunctions';
import { FormattedMessage } from 'react-intl';

export const ADDRESS2_DETAILS = {
  componentType: 'Textfield',
  inputType: 'text',
  inputName: 'address2',
  numericField: false,
  inputLabel: <FormattedMessage id="address.address2" defaultMessage="Apt. / Unit #" />,
  inputError: address2HasError,
  inputHelperText: address2HelperText,
  fullWidth: true,
  required: false,
};
