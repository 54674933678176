import { FormattedMessage } from 'react-intl';

const childCareOptions = {
  homeBased: <FormattedMessage id="childBlock.careOptions.homeBased" defaultMessage="Licensed home-based child care" />,
  center: <FormattedMessage id="childBlock.careOptions.center" defaultMessage="Licensed child care center" />,
  school: <FormattedMessage id="childBlock.careOptions.school" defaultMessage="Before and After-School Care" />,
  preschool: <FormattedMessage id="childBlock.careOptions.preschool" defaultMessage="Pre-K" />,
  headStart: <FormattedMessage id="childBlock.careOptions.headStart" defaultMessage="Head Start" />,
  familyOrFriend: (
    <FormattedMessage
      id="childBlock.careOptions.familyOrFriend"
      defaultMessage="Family member, friend, or neighbor care"
    />
  ),
  exploring: <FormattedMessage id="childBlock.careOptions.exploring" defaultMessage="Not sure - just exploring" />,
};

export default childCareOptions;
