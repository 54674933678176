import { FormattedMessage } from 'react-intl';

const neededCareOptions = {
  part_time: (
    <FormattedMessage
      id="childBlock.neededCare.partTime"
      defaultMessage="Part-time (10-14 hours a week): Examples - before/after school care; 4 days a week (am/pm only) or two full days a week"
    />
  ),
  half_day_am: (
    <FormattedMessage
      id="childBlock.neededCare.halfDayAm"
      defaultMessage="Half-day Mornings (15-29 hours a week): Typically care that will end around lunch time"
    />
  ),
  half_day_pm: (
    <FormattedMessage
      id="childBlock.neededCare.halfDayPm"
      defaultMessage="Half-day Afternoons (15-29 hours a week): Typically care that will start after lunch time"
    />
  ),
  full_day: (
    <FormattedMessage
      id="childBlock.neededCare.fullDay"
      defaultMessage="Full Day (30-40 hours a week): Typically a full school day, ex: 8:15 am - 3:25 pm, Monday - Friday"
    />
  ),
  extended_day: (
    <FormattedMessage
      id="childBlock.neededCare.extendedDay"
      defaultMessage="Extended Day (41+ hours a week): Full days of care with ability to do early drop offs / late pickups"
    />
  ),
};

export default neededCareOptions;
