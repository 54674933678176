import { Button, Checkbox, checkboxClasses, FormControlLabel } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Context } from '../Wrapper/Wrapper.tsx';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { createScreen } from '../../Assets/updateScreen.ts';
import PreviousButton from '../PreviousButton/PreviousButton.tsx';
import { STARTING_QUESTION_NUMBER } from '../../Assets/stepDirectory.ts';
import adams_county_logo from '../../Assets/AdamsAssets/adams_county_logo.png';
import './LandingPage.css';
import { CheckBoxConsent } from '../Legal/Legal.tsx';
import { useErrorController } from '../../Assets/validationFunctions.tsx';
import { iepErrorMessage, notSelectedHasError } from '../../Assets/validationFunctions';

const LandingPage = () => {
  const { formData, locale, screenDoneLoading, setFormData } = useContext(Context);
  const queryString = formData.referrerCode ? `?referrer=${formData.referrerCode}` : '';
  let { uuid } = useParams();
  const navigate = useNavigate();

  const [adamsWithChild, setAdamsWithChild] = useState(formData.adamsWithChild ?? false);
  const [childWithIep, setChildWithIep] = useState(false);

  const iepErrorController = useErrorController(notSelectedHasError, iepErrorMessage);

  useEffect(() => {
    iepErrorController.updateError(childWithIep);
  }, [childWithIep]);

  const errorController = useErrorController(
    () => adamsWithChild !== true && childWithIep === false,
    () => {},
  );

  useEffect(() => {
    iepErrorController.setSubmittedCount(errorController.submittedCount);
  }, [errorController.submittedCount]);

  useEffect(() => {
    errorController.updateError(null);
    setFormData({ ...formData, adamsWithChild: adamsWithChild });
  }, [adamsWithChild]);

  useEffect(() => {
    const continueOnEnter = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleContinue();
      }
    };
    document.addEventListener('keyup', continueOnEnter);
    return () => {
      document.removeEventListener('keyup', continueOnEnter); // remove event listener on onmount
    };
  });

  const handleContinue = async () => {
    errorController.incrementSubmitted();
    if (errorController.hasError || iepErrorController.hasError) {
      return;
    }

    if (uuid) {
      navigate(`/${uuid}/step-${STARTING_QUESTION_NUMBER}`);
    } else {
      const response = await createScreen(formData, locale);
      screenDoneLoading();
      navigate(`/${response.id}/step-${STARTING_QUESTION_NUMBER}`);
    }
  };
  return (
    <main className="benefits-form">
      <div className="logo-container">
        <img src={adams_county_logo} alt="adams county logo" className="adams-landing-pg-logo" />
      </div>
      <article className="adams-landing-pg-article pink-background">
        <h1>
          <FormattedMessage
            id="landing.closed.header"
            defaultMessage="This pilot for Adams County residents is now closed."
          />
        </h1>
        <p>
          <FormattedMessage
            id="landing.closed.subheader"
            defaultMessage="If you want to apply for assistance with child care or preschool, please use these resources:"
          />
        </p>
        <div className="landing-closed-program-container">
          <h2>
            <FormattedMessage id="landing.closed.upk.header" defaultMessage="Universal Preschool (Free Preschool): " />
          </h2>
          <p>
            <FormattedMessage
              id="landing.closed.upk.subheader"
              defaultMessage="Universal Preschool (UPK) provides Colorado 4-year-olds with 15 hours per week of free preschool, and potentially extra hours with a qualifying factor."
            />
          </p>
          <ul>
            <li>
              <a href="https://upk.colorado.gov/" target="_blank" rel="noopener noreferrer">
                <FormattedMessage id="landing.closed.upk.apply" defaultMessage="Apply Here" />
              </a>
            </li>
            <li>
              <FormattedMessage
                id="landing.closed.upk.questions"
                defaultMessage="For questions or help with your application, contact us at upk@adcoconnect.org or 720.677.6157"
              />
            </li>
          </ul>
          <p className="landing-closed-warning">
            <FormattedMessage
              id="landing.closed.upk.warning"
              defaultMessage="For inquiries around 3 year old availability in the Universal Preschool program, please reach out to your local school district - space is very limited. For other care needs, please see CCCAP information below."
            />
          </p>
        </div>
        <div className="landing-closed-program-container">
          <h2>
            <FormattedMessage
              id="landing.closed.cccap.header"
              defaultMessage="CCCAP (Colorado Child Care Assistance Program):"
            />
          </h2>
          <p>
            <FormattedMessage
              id="landing.closed.cccap.subheader"
              defaultMessage="Provides help with child care or preschool costs so you can work, look for a job, or go to school. If you qualify, CCCAP may be able to pay for 40+ hours of care each week."
            />
          </p>
          <ul>
            <li>
              <a
                href="https://adcogov.org/sites/default/files/2023-08/3-Application_rev7.2023.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="landing.closed.cccap.apply" defaultMessage="Apply Here" />
              </a>
            </li>
            <li>
              <FormattedMessage id="landing.closed.cccap.moreInfo.1" defaultMessage="For more information, " />
              <a
                href="https://adcogov.org/colorado-child-care-assistance-program-cccap"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="landing.closed.cccap.moreInfo.2" defaultMessage="see here" />
              </a>
            </li>
            <li>
              <FormattedMessage id="landing.closed.cccap.help.1" defaultMessage="For help applying, contact " />
              <a
                href="https://www.benefitsinaction.org/application-assistance"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="landing.closed.cccap.help.2" defaultMessage="Benefits in Action" />
              </a>
              <FormattedMessage id="landing.closed.cccap.help.3" defaultMessage=" at 888.496.4252" />
            </li>
          </ul>
        </div>
        <div className="landing-closed-program-container">
          <h2>
            <FormattedMessage id="landing.closed.hs.header" defaultMessage="Head Start:" />
          </h2>
          <p>
            <FormattedMessage
              id="landing.closed.hs.subheader"
              defaultMessage="Head Start supports school readiness for children ages 3 to 5 by providing free preschool."
            />
          </p>
          <p className="landing-closed-warning">
            <FormattedMessage
              id="landing.closed.hs.warning"
              defaultMessage="Currently, all Head Start Centers in Adams County are at capacity for the 2024-25 school year. However, openings may arise during the year, so we recommend applying to be added to the waitlist. Additionally, we encourage you to apply for assistance through CCCAP."
            />
          </p>
          <ul>
            <li>
              <a href="https://eclkc.ohs.acf.hhs.gov/" target="_blank" rel="noopener noreferrer">
                <FormattedMessage id="landing.closed.hs.apply" defaultMessage="Apply to Head Start waitlist online" />
              </a>
            </li>
            <li>
              <FormattedMessage id="landing.closed.hs.questions" defaultMessage="Questions? Call 720-523-4616" />
            </li>
          </ul>
        </div>
        {/*<div>
          <h1>
            <FormattedMessage id="landing.header" defaultMessage="Adams County Preschool & Child Care Application" />
          </h1>
          <p className="subheader">
            <FormattedMessage
              id="landing.subheader"
              defaultMessage="A new application for three child care & preschool assistance programs"
            />
          </p>
        </div>
        <h2>
          <FormattedMessage id="landing.what" defaultMessage="This application:" />
        </h2>
        <ul>
          <li>
            <FormattedMessage
              id="landing.what.0"
              defaultMessage="Determines eligibility for three funding programs (CCCAP, Head Start, and Universal Preschool)"
            />
          </li>
          <li>
            <FormattedMessage
              id="landing.what.1"
              defaultMessage="Finds providers that best meet your needs and lowers your costs as much as possible"
            />
          </li>
          <li>
            <FormattedMessage
              id="landing.what.2"
              defaultMessage="Connects you with an Adams County navigator to help finalize your enrollment"
            />
          </li>
        </ul>*/}
      </article>
      {/*<CheckBoxConsent
        selected={adamsWithChild}
        setSelected={setAdamsWithChild}
        submitted={errorController.submittedCount}
      >
        <FormattedMessage
          id="landing.adamsWithChild"
          defaultMessage="Yes, I am an Adams County resident with a child in my home that will be 4 on or before October 1, 2024"
        />
      </CheckBoxConsent>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={childWithIep}
              onChange={() => {
                setChildWithIep(!childWithIep);
              }}
              sx={
                iepErrorController.showError
                  ? {
                      [`&, &.${checkboxClasses.checked}`]: {
                        color: '#c6252b',
                      },
                    }
                  : {}
              }
            />
          }
          label={
            <div>
              <FormattedMessage
                id="landing.childWithIep"
                defaultMessage="Please check this box if your 4 year old has an active Individualize Education Program (IEP)."
              />
            </div>
          }
        />
        {iepErrorController.showError && iepErrorController.message(childWithIep)}
      </div>
      <div className="back-continue-buttons">
        <PreviousButton navFunction={() => navigate(`/step-1${queryString}`)} />
        <Button variant="contained" onClick={handleContinue}>
          <FormattedMessage id="continue-button" defaultMessage="Continue" />
        </Button>
      </div>*/}
    </main>
  );
};

export default LandingPage;
