import { useContext, useState } from 'react';
import { Context } from '../Wrapper/Wrapper.tsx';
import { useParams } from 'react-router-dom';
import Radiofield from '../Radiofield/Radiofield';
import Textfield from '../Textfield/Textfield';
import PreviousButton from '../PreviousButton/PreviousButton';
import ContinueButton from '../ContinueButton/ContinueButton';
import BasicSelect from '../DropdownMenu/BasicSelect';
import BasicCheckboxGroup from '../CheckboxGroup/BasicCheckboxGroup';
import OptionCardGroup from '../OptionCardGroup/OptionCardGroup';
import FollowUpQuestions from '../FollowUpQuestions/FollowUpQuestions';
import { useErrorController, zipcodeHasError } from '../../Assets/validationFunctions.tsx';
import { getQuestion } from '../../Assets/stepDirectory.ts';
import './QuestionComponentContainer.css';
import Address from '../Address/Address.tsx';
import SchoolDistrict from '../SchoolDistrict/SchoolDistrict.tsx';
import Legal from '../Legal/Legal.tsx';
import Confirmation from '../Confirmation/Confirmation.tsx';
import ProviderPage from '../Provider/ProviderPage.tsx';
import ProviderError from '../Provider/ProviderError.tsx';
import { FormattedMessage } from 'react-intl';
import Conditions from '../Conditions/Conditions.tsx';
import Contact from '../Contact/Contact.tsx';

const QuestionComponentContainer = ({
  handleTextfieldChange,
  handleContinueSubmit,
  handleRadioButtonChange,
  handleNoAnswerChange,
  handleIncomeStreamsSubmit,
  handleExpenseSourcesSubmit,
  handleCheckboxChange,
}) => {
  const { formData, setFormData } = useContext(Context);
  let { id } = useParams();
  let matchingQuestion = getQuestion(+id, formData.immutableReferrer);
  const [providerHasError, setProviderHasError] = useState(false);
  const [providerNoChildren, setProviderNoChildren] = useState(false);

  const errorController = useErrorController(
    matchingQuestion.componentDetails.inputError,
    matchingQuestion.componentDetails.inputHelperText,
  );

  const renderTextfieldComponent = (question) => {
    return (
      <Textfield
        componentDetails={question.componentDetails}
        data={formData}
        handleTextfieldChange={handleTextfieldChange}
        submitted={errorController.submittedCount}
      />
    );
  };

  const renderRadiofieldComponent = (question) => {
    return (
      <Radiofield componentDetails={question.componentDetails} handleRadioButtonChange={handleRadioButtonChange} />
    );
  };

  const renderNoAnswerComponent = (question) => {
    return (
      <Radiofield
        componentDetails={question.componentDetails}
        handleRadioButtonChange={handleNoAnswerChange}
        preferNotToAnswer={true}
      />
    );
  };

  const renderBasicCheckboxGroup = (question) => {
    return (
      <BasicCheckboxGroup
        stateVariable={question.componentDetails.inputName}
        options={matchingQuestion.componentDetails.options}
      />
    );
  };

  const renderOptionCardGroup = (question) => {
    return (
      <OptionCardGroup
        options={matchingQuestion.componentDetails.options}
        stateVariable={question.componentDetails.inputName}
        memberData={formData}
        setMemberData={setFormData}
      />
    );
  };

  const renderBasicSelectComponent = (question) => {
    return (
      <BasicSelect
        componentDetails={question.componentDetails}
        options={question.componentDetails.options}
        formDataProperty={question.componentDetails.inputName}
        submitted={errorController.submittedCount}
      />
    );
  };

  const renderAddressComponent = (question) => {
    return <Address submitted={errorController.submittedCount} />;
  };

  const renderSchoolDistrictComponent = (question) => {
    return <SchoolDistrict submitted={errorController.submittedCount} />;
  };

  const renderLegalComponent = (question) => {
    return <Legal submitted={errorController.submittedCount} />;
  };

  const renderConditionsComponent = (question) => {
    return <Conditions />;
  };

  const renderContactComponent = (question) => {
    return <Contact submitted={errorController.submittedCount} />;
  };

  const renderConfirmationComponent = (question) => {
    return <Confirmation />;
  };

  const renderProviderComponent = (question) => {
    return (
      <ProviderPage
        submitted={errorController.submittedCount}
        setProviderHasError={setProviderHasError}
        setProviderNoChildren={setProviderNoChildren}
      />
    );
  };

  const createComponent = (component) => {
    const inputName = matchingQuestion.componentDetails.inputName;
    const { followUpQuestions } = matchingQuestion;
    const hasFollowUpQuestions = followUpQuestions && followUpQuestions.length > 0;
    // this is specifically for health & referral q error handling
    const isHealthQuestion = inputName === 'healthInsurance';

    return (
      <div className="question-container" id={id}>
        {<h2 className="question-label">{matchingQuestion.question}</h2>}
        {matchingQuestion.questionDescription && (
          <p className="question-description">{matchingQuestion.questionDescription}</p>
        )}
        {component}
        {shouldRenderFollowUpQuestions(hasFollowUpQuestions, inputName) && (
          <FollowUpQuestions
            followUpQuestions={matchingQuestion.followUpQuestions}
            submitted={errorController.submittedCount}
            formData={formData}
            handleCheckboxChange={handleCheckboxChange}
            handleExpenseSourcesSubmit={handleExpenseSourcesSubmit}
            handleIncomeStreamsSubmit={handleIncomeStreamsSubmit}
            handleTextfieldChange={handleTextfieldChange}
          />
        )}
        {isHealthQuestion && errorController.showError && errorController.message(formData[inputName])}
        {createPreviousAndContinueButtons()}
      </div>
    );
  };

  const shouldRenderFollowUpQuestions = (hasFollowUpQuestions, inputName) => {
    if (!hasFollowUpQuestions) {
      return false;
    }
    if (inputName === 'zipcode') {
      return !zipcodeHasError(formData.zipcode);
    }
    if (formData[inputName] === true) {
      // this case is for a radio button question where the user selected "yes"
      return true;
    }
    if (formData[inputName] === 'true') {
      // this case is for a radio button with prefer not to answer where the use selected "yes"
      return true;
    }
    if (formData[inputName] === 'other') {
      // this case is for the referral source question where the user selected "other"
      return true;
    }
    if (
      inputName === 'signUpInfo' &&
      (formData.signUpInfo.sendUpdates || formData.signUpInfo.sendOffers) &&
      !formData.signUpInfo.hasUser
    ) {
      return true;
    }

    return false;
  };

  const createPreviousAndContinueButtons = () => {
    //render normal button block if the question isn't the income or expense question OR
    //if the user doesn't have an income/expenses at all,
    //otherwise these buttons will be created in the IncomeBlock/ExpenseBlock components
    const isNotIncomeAndNotExpenseQ = matchingQuestion.name !== 'hasIncome' && matchingQuestion.name !== 'hasExpenses';
    const hasFalsyIncome =
      matchingQuestion.name === 'hasIncome' && formData[matchingQuestion.componentDetails.inputName] === false;
    const hasFalsyExpense =
      matchingQuestion.name === 'hasExpenses' && formData[matchingQuestion.componentDetails.inputName] === false;
    if (isNotIncomeAndNotExpenseQ || hasFalsyIncome || hasFalsyExpense) {
      return (
        <div className="question-buttons">
          <PreviousButton questionName={matchingQuestion.name} />
          <ContinueButton
            handleContinueSubmit={handleContinueSubmit}
            errorController={errorController}
            inputName={matchingQuestion.componentDetails.inputName}
            questionName={matchingQuestion.name}
            disabledEnter={matchingQuestion.name === 'providers'}
          />
        </div>
      );
    }
  };

  const renderHeaderAndSubheader = () => {
    return (
      <>
        {matchingQuestion.subheader && (
          <strong className="question-secondary-header">{matchingQuestion.subheader}</strong>
        )}
        {matchingQuestion.header && <h1 className="sub-header">{matchingQuestion.header}</h1>}
      </>
    );
  };

  if (providerHasError) {
    return (
      <main className="benefits-form">
        <ProviderError>
          <FormattedMessage
            id="resultsError.message"
            defaultMessage="We're sorry. We are having some trouble completing your request. Please make sure you have completed all of the questions and try again. If you are still unable to load your provider results, please contact addamsecepilot@garycommunity.org"
          />
        </ProviderError>
      </main>
    );
  }

  if (providerNoChildren) {
    return (
      <main className="benefits-form">
        <ProviderError>
          <FormattedMessage
            id="resultsError.noChildren"
            defaultMessage="You indicated that no children in your household need early childhood care or preschool. This pilot is only for families seeking child care. Please use the button bellow to go back and edit your household members."
          />
        </ProviderError>
      </main>
    );
  }

  return (
    <main className="benefits-form">
      {renderHeaderAndSubheader()}
      {(matchingQuestion.componentDetails.componentType === 'Textfield' &&
        createComponent(renderTextfieldComponent(matchingQuestion))) ||
        (matchingQuestion.componentDetails.componentType === 'Radiofield' &&
          createComponent(renderRadiofieldComponent(matchingQuestion))) ||
        (matchingQuestion.componentDetails.componentType === 'PreferNotToAnswer' &&
          createComponent(renderNoAnswerComponent(matchingQuestion))) ||
        (matchingQuestion.componentDetails.componentType === 'BasicCheckboxGroup' &&
          createComponent(renderBasicCheckboxGroup(matchingQuestion))) ||
        (matchingQuestion.componentDetails.componentType === 'OptionCardGroup' &&
          createComponent(renderOptionCardGroup(matchingQuestion))) ||
        (matchingQuestion.componentDetails.componentType === 'BasicSelect' &&
          createComponent(renderBasicSelectComponent(matchingQuestion))) ||
        (matchingQuestion.componentDetails.componentType === 'Address' &&
          createComponent(renderAddressComponent(matchingQuestion))) ||
        (matchingQuestion.componentDetails.componentType === 'SchoolDistrict' &&
          createComponent(renderSchoolDistrictComponent(matchingQuestion))) ||
        (matchingQuestion.componentDetails.componentType === 'Legal' &&
          createComponent(renderLegalComponent(matchingQuestion))) ||
        (matchingQuestion.componentDetails.componentType === 'Contact' &&
          createComponent(renderContactComponent(matchingQuestion))) ||
        (matchingQuestion.componentDetails.componentType === 'Confirmation' &&
          createComponent(renderConfirmationComponent(matchingQuestion))) ||
        (matchingQuestion.componentDetails.componentType === 'Providers' &&
          createComponent(renderProviderComponent(matchingQuestion))) ||
        (matchingQuestion.componentDetails.componentType === 'Conditions' &&
          createComponent(renderConditionsComponent(matchingQuestion)))}
    </main>
  );
};

export default QuestionComponentContainer;
