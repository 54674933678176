import { FormattedMessage } from 'react-intl';
import BasicSelect from '../DropdownMenu/BasicSelect';
import { useContext, useEffect, useState } from 'react';
import { Context } from '../Wrapper/Wrapper';
import { schoolDistrictHelperText, emptyError } from '../../Assets/validationFunctions';
import { FormattedMessageType } from '../../Types/Questions';
import './SchoolDistrict.css';

export const SCHOOL_DISTRICT_OPTIONS: { [key: string]: FormattedMessageType } = {
  adams_12: <FormattedMessage id="schoolDistrict.adams12" defaultMessage="Adams 12" />,
  adams_14: <FormattedMessage id="schoolDistrict.adams14" defaultMessage="Adams 14" />,
  brighton_27J: <FormattedMessage id="schoolDistrict.brighton27J" defaultMessage="Brighton 27J" />,
  westminster: <FormattedMessage id="schoolDistrict.westminster" defaultMessage="Westminster" />,
  mapleton: <FormattedMessage id="schoolDistrict.mapleton" defaultMessage="Mapleton" />,
  aurora_public: <FormattedMessage id="schoolDistrict.aurora" defaultMessage="Aurora" />,
  bennett_29J: <FormattedMessage id="schoolDistrict.bennett29J" defaultMessage="Bennett 29J" />,
  byers_32J: <FormattedMessage id="schoolDistrict.byers32J" defaultMessage="Byers 32J" />,
  strasburg_31J: <FormattedMessage id="schoolDistrict.strasburg31J" defaultMessage="Strasburg 31J" />,
};

type SchoolDistrictProps = {
  submitted: number;
};

const SchoolDistrict = ({ submitted }: SchoolDistrictProps) => {
  const { formData, setFormData } = useContext(Context);
  const [schoolDistrictDetails, setSchoolDistrictDetails] = useState({
    componentProperties: {
      labelId: 'school-district-select',
      inputLabelText: <FormattedMessage id="schoolDistrict.label" defaultMessage="School District" />,
      id: 'school-district-select',
      value: formData.schoolDistrict ?? '',
      label: <FormattedMessage id="schoolDistrict.label" defaultMessage="School District" />,
      disabledSelectMenuItemText: (
        <FormattedMessage id="schoolDistrict.disabledText" defaultMessage="Select a school district" />
      ),
    },
    inputError: emptyError,
    inputHelperText: schoolDistrictHelperText,
  });

  useEffect(() => {
    setSchoolDistrictDetails({
      ...schoolDistrictDetails,
      componentProperties: {
        ...schoolDistrictDetails.componentProperties,
        value: formData.schoolDistrict ?? '',
      },
    });
  });

  const onChange = (value: string) => {
    setFormData({ ...formData, schoolDistrict: value });
  };

  return (
    <>
      <p className="schoolDistrict-not-sure">
        <FormattedMessage id="schoolDistrict.notSure" defaultMessage="Not sure of your district? Type your address " />
        <a
          href="https://www.greatschools.org/school-district-boundaries-map/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="schoolDistrict.notSure.link" defaultMessage="in this site" />
        </a>
        .
      </p>
      <BasicSelect
        componentDetails={schoolDistrictDetails}
        options={SCHOOL_DISTRICT_OPTIONS}
        submitted={submitted}
        onChange={onChange}
      />
    </>
  );
};

export default SchoolDistrict;
