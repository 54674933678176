import { FormattedMessage } from 'react-intl';

export const childCareStartDateOptions = {
  immediately: (
    <FormattedMessage id="childBlock.childCareStartDateOptions.immediately" defaultMessage="As soon as possible" />
  ),
  summer: <FormattedMessage id="childBlock.childCareStartDateOptions.summer" defaultMessage="Summer care" />,
  school_year: (
    <FormattedMessage
      id="childBlock.childCareStartDateOptions.school_year"
      defaultMessage="For the fall / school year"
    />
  ),
  school_breaks: (
    <FormattedMessage
      id="childBlock.childCareStartDateOptions.school_breaks"
      defaultMessage="For school breaks (winter break, spring break)"
    />
  ),
};
