import { FormControlLabel, FormGroup, Checkbox, FormControl } from '@mui/material';

const CheckboxGroup = ({ stateObj, memberType, inputName, options, handleCheckboxChangeFunction }) => {
  const createFormControlLabels = (optionList) => {
    const optionKeys = Object.keys(optionList);

    const formControlLabels = optionKeys.map((optionKey) => {
      let label = optionList[optionKey];
      let checkedValue;

      if (inputName === 'childCareNeeds') {
        checkedValue = stateObj[memberType][inputName][optionKey];
      } else if (inputName === 'childMember') {
        checkedValue = stateObj[memberType][optionKey];
      } else if (inputName === 'contactInformation') {
        checkedValue = stateObj[optionKey];
      }

      return (
        <FormControlLabel
          sx={{ alignItems: 'flex-start', marginTop: `1rem` }}
          control={
            <Checkbox
              checked={checkedValue}
              onChange={(event) => handleCheckboxChangeFunction(event, stateObj, inputName, memberType)}
              name={optionKey}
              sx={{ marginTop: -1 }}
            />
          }
          label={label}
          key={optionKey}
        />
      );
    });

    return formControlLabels;
  };

  return (
    <FormControl>
      <FormGroup>{createFormControlLabels(options)}</FormGroup>
    </FormControl>
  );
};

export default CheckboxGroup;
