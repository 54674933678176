import { FormattedMessage, useIntl } from 'react-intl';
import adamsConditionOptions, { Conditions } from '../../Assets/adamsConditionOptions';
import { Checkbox, FormControlLabel, FormGroup, Box, RadioGroup, Radio } from '@mui/material';
import { useEffect } from 'react';
import { Member, AdultMember } from '../../Types/FormData';
import PersonIncomeBlock from '../IncomeBlock/PersonIncomeBlock';
import { useErrorController } from '../../Assets/validationFunctions';
import ErrorMessageWrapper from '../ErrorMessage/ErrorMessageWrapper';

type HHDBMemberProps = {
  page: number;
  memberData: Member;
  setMemberData: (member: Member) => void;
  submitted: number;
};

const conditions = Object.keys(adamsConditionOptions) as Conditions[];

const AdultBlock = ({ page, memberData, setMemberData, submitted }: HHDBMemberProps) => {
  const intl = useIntl();
  let initialAdultData: AdultMember = {
    employed: false,
    selfEmployed: false,
    searchingForJob: false,
    postSecondaryStudent: false,
    training: false,
    englishSecondLanguageStudent: false,
    highSchoolStudent: false,
    middleSchoolStudent: false,
    disabled: false,
    nationalGuard: false,
    militaryReserves: false,
    militaryActive: false,
    pregnant: false,
    breastFeeding: false,
    noQualifyingFactor: false,
    hasIncome: false,
    income: [],
  };

  const hasConditions = () => {
    for (const condition of conditions) {
      if (condition !== 'noQualifyingFactor' && memberData.adultMember?.[condition] === true) {
        return true;
      }
    }

    return false;
  };

  const hasConditionsErrorController = useErrorController(
    () => hasConditions() === memberData.adultMember?.noQualifyingFactor ?? false,
    () => {
      if (hasConditions() && memberData.adultMember?.noQualifyingFactor) {
        return (
          <ErrorMessageWrapper fontSize="1rem">
            <FormattedMessage
              id="householdDataBlock.hasNoConditions.errorMessage.notBoth"
              defaultMessage='Please deselect "None of these apply" if any of the conditions apply'
            />
          </ErrorMessageWrapper>
        );
      }
      return (
        <ErrorMessageWrapper fontSize="1rem">
          <FormattedMessage
            id="householdDataBlock.hasNoConditions.errorMessage"
            defaultMessage="It is very important that you select one of the conditions if they apply."
          />
        </ErrorMessageWrapper>
      );
    },
  );

  useEffect(() => {
    hasConditionsErrorController.updateError(null);
  }, [hasConditions(), memberData.adultMember?.noQualifyingFactor]);
  console.log(hasConditions(), memberData.adultMember?.noQualifyingFactor, hasConditionsErrorController.hasError);

  useEffect(() => {
    hasConditionsErrorController.setSubmittedCount(submitted);
  }, [submitted]);

  useEffect(() => {
    //if there is no data for this member, then initialize it with the initialAdultData value
    if (!memberData.adultMember) {
      setMemberData({
        ...memberData,
        adultMember: initialAdultData,
        childMember: undefined,
      });
    }
  }, []);

  useEffect(() => {
    //if hasIncome is false then set empty income array
    if (memberData.adultMember?.hasIncome === false) {
      setMemberData({
        ...memberData,
        adultMember: {
          ...memberData.adultMember,
          income: [],
        },
      });
    }
  }, [memberData.adultMember?.hasIncome]);

  const handleAdultCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, memberData: Member) => {
    const { name, checked } = event.target;
    // @ts-ignore
    setMemberData({ ...memberData, adultMember: { ...memberData.adultMember, [name]: checked } });
  };

  const createAdultConditionCheckboxes = (memberData: Member) => {
    const allCheckboxes = Object.entries(adamsConditionOptions).map((conditionKeyValPair) => {
      const conditionKey = conditionKeyValPair[0] as Conditions;
      const conditionLabel = conditionKeyValPair[1];
      const stateValueAtThisKey = memberData.adultMember?.[conditionKey] ?? false;

      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={stateValueAtThisKey}
              name={conditionKey}
              onChange={(event) => handleAdultCheckboxChange(event, memberData)}
            />
          }
          key={conditionKey}
          label={conditionLabel}
        />
      );
    });

    return (
      <FormGroup>
        {allCheckboxes}
        {hasConditionsErrorController.showError && hasConditionsErrorController.message(null)}
      </FormGroup>
    );
  };

  const createConditionsQuestion = (page: number) => {
    const fMId =
      page === 1
        ? 'householdDataBlock.createConditionsQuestion-do-these-apply-to-you'
        : 'householdDataBlock.createConditionsQuestion-do-these-apply';
    const fMDefaultMsg =
      page === 1 ? 'Do any of the following apply to you?' : 'Do any of the following apply to them?';
    return (
      <h2 className="question-label">
        <FormattedMessage id={fMId} defaultMessage={fMDefaultMsg} />
      </h2>
    );
  };

  const createIncomeRadioQuestion = (page: number) => {
    const ariaLabel = 'householdDataBlock.createIncomeRadioQuestion-ariaLabel';
    const translatedAriaLabel = intl.formatMessage({ id: ariaLabel, defaultMessage: 'do you have any income?' });

    const formattedMsgId =
      page === 1 ? 'questions.hasIncome' : 'householdDataBlock.createIncomeRadioQuestion-questionLabel';

    const formattedMsgDefaultMsg =
      page === 1
        ? 'Do you have an income?'
        : 'Does this individual in your household have significant income you have not already included?';

    return (
      <Box className="section-container" sx={{ padding: '3rem 0 2rem 0' }}>
        <Box className="section">
          <h2 className="question-label">
            <FormattedMessage id={formattedMsgId} defaultMessage={formattedMsgDefaultMsg} />
          </h2>
          <p className="question-description">
            <FormattedMessage
              id="householdDataBlock.createIncomeRadioQuestion-questionDescription"
              defaultMessage="This includes money from jobs, alimony, investments, or gifts. Income is the money earned or received before deducting taxes"
            />
          </p>
          <div className="radiogroup-container">
            <RadioGroup
              aria-labelledby={translatedAriaLabel}
              name="hasIncome"
              value={memberData.adultMember?.hasIncome ?? false}
              onChange={(event) => {
                if (memberData.adultMember === undefined) {
                  setMemberData({ ...memberData, adultMember: initialAdultData });
                  return;
                }
                setMemberData({
                  ...memberData,
                  adultMember: { ...memberData.adultMember, hasIncome: !memberData.adultMember.hasIncome },
                });
              }}
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label={<FormattedMessage id="radiofield.label-yes" defaultMessage="Yes" />}
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label={<FormattedMessage id="radiofield.label-no" defaultMessage="No" />}
              />
            </RadioGroup>
          </div>
        </Box>
      </Box>
    );
  };

  return (
    <div className="head-of-hh-container">
      {createConditionsQuestion(page)}
      <p className="question-description">
        <FormattedMessage
          id="householdDataBlock.createConditionsQuestion-pick"
          defaultMessage="Choose all that apply."
        />
      </p>
      {createAdultConditionCheckboxes(memberData)}
      {createIncomeRadioQuestion(page)}
      {memberData.adultMember?.hasIncome && (
        <PersonIncomeBlock memberData={memberData} setMemberData={setMemberData} page={page} submitted={submitted} />
      )}
    </div>
  );
};

export default AdultBlock;
