import { PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  providerIsSelected,
  useEligibleChildren,
  useProviderContext,
  useHoursEligible,
  useUpdateSelectedProviders,
  formatHours,
  useFocused,
  useSetFocusedProvider,
  filterCitizen,
  filterTypes,
  maxUpkHoursAvailable,
} from './hooks';
import { Provider } from '../../Types/Eligibility';
import './ProviderCards.css';
import SortProviders, { KM_PER_MILE, calcCrow } from './providerSorting';
import { FormattedMessage } from 'react-intl';
import { FormattedMessageType } from '../../Types/Questions';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { ChildMember } from '../../Types/FormData';
import { useSearchParams } from 'react-router-dom';
import { Context } from '../Wrapper/Wrapper';
import HelpBubble from '../HelpBubble/HelpBubble';
import { Checkbox, FormControlLabel } from '@mui/material';
import { ProviderMarker } from './ProviderMapMarkers';

type ProviderDetailRowProps = {
  title: FormattedMessageType | string;
} & PropsWithChildren;

const ProviderDetailRow = ({ title, children }: ProviderDetailRowProps) => {
  return (
    <div>
      <strong>{title}:</strong> <span>{children}</span>
    </div>
  );
};

type StarsProps = {
  starCount: number;
};
const Stars = ({ starCount }: StarsProps) => {
  const stars = [];

  for (let i = 0; i < 5; i++) {
    if (i < starCount) {
      stars.push(<StarIcon className="provider-card-star provider-card-star-full" key={i} />);
      continue;
    }

    stars.push(<StarBorderIcon className="provider-card-star" key={i} />);
  }

  return <span aria-label={`${starCount} out of 5`}>{stars}</span>;
};

type ProviderDisclaimerProps = {
  provider: Provider;
};

const EXTRA_UPK_SLOTS = ['full_day', 'half_day', 'half_day_am', 'half_day_pm', 'extended_day'];
const EXTRA_CCCAP_SLOTS = ['half_day_am', 'half_day_pm', 'full_day', 'extended_day'];
const EXTRA_HEAD_START_SLOTS = ['half_day_am', 'half_day_pm', 'full_day_28', 'full_day_35', 'extended_day'];

function programHasExtraTime(program: { [key: string]: unknown }, extraTimes: string[]) {
  for (const [key, value] of Object.entries(program)) {
    if (extraTimes.includes(key) && value === true) {
      return true;
    }
  }

  return false;
}

const ProviderDisclaimer = ({ provider }: ProviderDisclaimerProps) => {
  const { eligibility } = useProviderContext();
  const eligibleChildren = useEligibleChildren();

  const schoolBased = useMemo(() => {
    return provider.school_based_provider;
  }, [provider]);
  const fee = useMemo(() => {
    return provider.cccap.on_provider_list;
  }, [provider]);
  const additionalHours = useMemo(() => {
    let upkEligible = false;
    let cccapEligible = false;
    let headStartEligible = false;

    for (const childEligibility of eligibility) {
      if (childEligibility.eligibility.upk.eligible) {
        upkEligible = true;
      }
      if (childEligibility.eligibility.cccap.eligible) {
        cccapEligible = true;
      }
      if (childEligibility.eligibility.head_start.eligible) {
        headStartEligible = true;
      }
    }

    if (!upkEligible) {
      return false;
    }

    if (provider.cccap.on_provider_list && cccapEligible) {
      return false;
    }

    if (provider.head_start.on_provider_list && headStartEligible) {
      return false;
    }

    if (programHasExtraTime(provider.upk, EXTRA_UPK_SLOTS)) {
      return true;
    }

    if (programHasExtraTime(provider.cccap, EXTRA_CCCAP_SLOTS)) {
      return true;
    }

    if (programHasExtraTime(provider.head_start, EXTRA_HEAD_START_SLOTS)) {
      return true;
    }

    return false;
  }, [provider, eligibility]);
  const threeYearOldProvider = useMemo(() => {
    if (!provider.upk.on_provider_list) {
      return false;
    }

    for (const child of eligibleChildren) {
      if (child.childMember === undefined) {
        continue;
      }

      const childMember = child.childMember;
      const childBirthDay = new Date(
        `${childMember.birthYear}-${parseInt(childMember.birthMonth) - 1}-${childMember.birthDay}`,
      );

      const timeDiff = new Date(Math.abs(childBirthDay.getTime() - new Date('2024-10-1').getTime()));

      const age = Math.abs(timeDiff.getUTCFullYear() - 1970);

      if (age === 3) {
        return true;
      }
    }

    return false;
  }, [provider, eligibleChildren]);

  return (
    <>
      {schoolBased && (
        <p className="provider-card-disclaimer">
          <FormattedMessage
            id="providerCard.disclaimer.schoolBased"
            defaultMessage="* This provider is a school district and may require families to live in the district."
          />
        </p>
      )}
      {fee && (
        <p className="provider-card-disclaimer">
          <FormattedMessage
            id="providerCard.disclaimer.fee"
            defaultMessage="* No parent fee is required, but this provider may charge a fee in 2025"
          />
        </p>
      )}
      {threeYearOldProvider && (
        <p className="provider-card-disclaimer">
          <FormattedMessage
            id="providerCard.disclaimer.threeYearOlds"
            defaultMessage="* Confirm schedule and availability for 3-year-olds with a navigator"
          />
        </p>
      )}
      {additionalHours && (
        <p className="provider-card-disclaimer">
          <FormattedMessage
            id="providerCard.disclaimer.additionalHours"
            defaultMessage="* Hours 16+ may carry a fee, check with provider."
          />
        </p>
      )}
    </>
  );
};

type DebugProviderRowProps = {
  name: string;
  bool: boolean;
};
const DebugProviderRow = ({ name, bool }: DebugProviderRowProps) => {
  return <ProviderDetailRow title={name}>{bool ? 'TRUE' : 'FALSE'}</ProviderDetailRow>;
};

type DebugProviderProps = {
  provider: Provider;
};

const DebugProvider = ({ provider }: DebugProviderProps) => {
  const searchParams = useSearchParams()[0];

  const debug = searchParams.get('debug') === 'true';

  if (!debug) {
    return null;
  }

  return (
    <div>
      <div>Upk</div>
      <hr></hr>
      {Object.keys(provider.upk).map((key, index) => {
        // @ts-ignore
        return <DebugProviderRow name={key} bool={provider.upk[key]} key={index} />;
      })}
      <br></br>
      <div>CCCAP</div>
      <hr></hr>
      {Object.keys(provider.cccap).map((key, index) => {
        // @ts-ignore
        return <DebugProviderRow name={key} bool={provider.cccap[key]} key={index} />;
      })}
      <br></br>
      <div>Head Start</div>
      <hr></hr>
      {Object.keys(provider.head_start).map((key, index) => {
        // @ts-ignore
        return <DebugProviderRow name={key} bool={provider.head_start[key]} key={index} />;
      })}
    </div>
  );
};

type SelectProviderButtonProps = {
  child: ChildMember;
  provider: Provider;
};

const SelectProviderButton = ({ provider, child }: SelectProviderButtonProps) => {
  const updateSelectedProviders = useUpdateSelectedProviders();
  const hoursEligible = useHoursEligible(child.frontEndId, provider);
  const formattedHours = useMemo(() => formatHours(hoursEligible), [hoursEligible]);
  const selected = useMemo(() => providerIsSelected(provider, child), [provider, child]);

  const selectProvider = () => {
    if (child.selectedProviders.some((selectedProvider) => selectedProvider.id === provider.id)) {
      return;
    }

    const newProviderList = [...child.selectedProviders, { name: provider.name, id: provider.id }];
    updateSelectedProviders(child.frontEndId, newProviderList);
  };

  const deselectProvider = () => {
    const newProviderList = child.selectedProviders.filter((selectedProvider) => selectedProvider.id !== provider.id);

    updateSelectedProviders(child.frontEndId, newProviderList);
  };

  const handleClick = () => {
    if (child.selectedProviders.some((selectedProvider) => selectedProvider.id === provider.id)) {
      deselectProvider();
      return;
    }

    selectProvider();
  };

  return (
    <div>
      <FormControlLabel
        label={`${child.firstName} ${child.lastName} (${formattedHours} hrs.)`}
        control={<Checkbox onChange={handleClick} checked={selected} />}
      />
    </div>
  );
};

type ProviderCardProps = {
  provider: Provider;
};

const providerTypeMap: { [key: string]: FormattedMessageType } = {
  'Child Care Center': <FormattedMessage id="providerCard.type.center" defaultMessage="Child Care Center" />,
  'Experienced Family Child Care Home': (
    <FormattedMessage id="providerCard.type.home" defaultMessage="Experienced Family Child Care Home" />
  ),
  'Family Child Care Home': (
    <FormattedMessage id="providerCard.type.familyHome" defaultMessage="Family Child Care Home" />
  ),
  'Large Family Child Care Home': (
    <FormattedMessage id="providerCard.type.largeFamilyHome" defaultMessage="Large Family Child Care Home" />
  ),
  'Three under Two Family Child Care Home': (
    <FormattedMessage
      id="providerCard.type.threeAndUnderHome"
      defaultMessage="Three under Two Family Child Care Home"
    />
  ),
  'School-Age Child Care Center': (
    <FormattedMessage id="providerCard.type.afterSchool" defaultMessage="Before and After School Care" />
  ),
  'Preschool Program': <FormattedMessage id="providerCard.type.preschool" defaultMessage="Preschool Program" />,
};

function mapProviderType(provider: Provider): FormattedMessageType | undefined {
  if (provider.head_start.on_provider_list) {
    return <FormattedMessage id="providerCard.type.headStart" defaultMessage="Head Start" />;
  }

  return providerTypeMap[provider.provider_type];
}

const ProviderCard = ({ provider }: ProviderCardProps) => {
  const { filters } = useProviderContext();
  const childEligibility = useEligibleChildren();
  const focused = useFocused(provider);
  const setFocusedProvider = useSetFocusedProvider(provider, false);

  const distanceFromAddress = useMemo(() => {
    const distanceInKm = calcCrow([filters.address.lat, filters.address.lng], [provider.latitude, provider.longitude]);

    return (distanceInKm / KM_PER_MILE).toFixed(1);
  }, [provider.latitude, provider.longitude]);

  const providerContainerClassName = useMemo(() => {
    let className = 'provider-card-container';

    if (focused) {
      className += ' focused';
    }

    return className;
  }, [provider, focused]);

  return (
    <div className={providerContainerClassName} onMouseEnter={setFocusedProvider}>
      <div className="provider-card-marker">
        <ProviderMarker provider={provider} />
      </div>
      <strong className="provider-card-header">{provider.name}</strong>
      <div className="provider-card-address">
        <address>
          {provider.address} {provider.city}, CO {provider.zipcode}, {distanceFromAddress}{' '}
          <FormattedMessage id="providerCard.distanceAway" defaultMessage="miles away" />
        </address>
      </div>
      <div className="provider-card-detail-contianer">
        {mapProviderType(provider) !== undefined && (
          <ProviderDetailRow title={<FormattedMessage id="providerCard.type" defaultMessage="Type" />}>
            {mapProviderType(provider)}
          </ProviderDetailRow>
        )}
        <ProviderDetailRow title={<FormattedMessage id="providerCard.phoneNumber" defaultMessage="Phone Number" />}>
          {provider.phone}
          {provider.phone_extension !== '' && ', Ext.' + provider.phone_extension.replace('x', '')}
        </ProviderDetailRow>
        {!Number.isNaN(Number(provider.quality_score)) && (
          <ProviderDetailRow
            title={<FormattedMessage id="providerCard.stars" defaultMessage="Colorado Shines Rating" />}
          >
            <Stars starCount={Number(provider.quality_score)} />
          </ProviderDetailRow>
        )}
        <ProviderDetailRow title={<FormattedMessage id="providerCard.capacity" defaultMessage="Capacity" />}>
          {provider.capacity}
        </ProviderDetailRow>
      </div>
      <ProviderDisclaimer provider={provider} />
      <div>
        <div className="providerCard-selectButtons-title-contianer">
          <strong>
            <FormattedMessage id="providerCard.selectButtons.title" defaultMessage="Select this provider for:" />
          </strong>
          <HelpBubble
            fontSize=".8rem"
            listOfMessages={[
              {
                fMsgId: 'providerCard.selectedButton.help',
                fMDefaultMsg:
                  'The number of hours represents the number of free hours that the child is likely eligible for. Additional hours may be available for a fee',
              },
            ]}
          />
        </div>
        <div className="provider-card-button-container">
          {childEligibility.map((child, index) => {
            if (child.childMember === undefined) {
              return null;
            }

            return (
              <SelectProviderButton child={child.childMember} provider={provider} key={child.childMember.frontEndId} />
            );
          })}
        </div>
      </div>
      <DebugProvider provider={provider} />
    </div>
  );
};

type Average = {
  total: number;
  count: number;
};

function averageProviderRank(provider: Average | undefined) {
  if (provider === undefined) {
    return Infinity;
  }

  return provider.total / provider.count;
}

const PROVIDERS_PER_PAGE = 50;

type JumpToPageProps = {
  currentPage: number;
  page: number;
  setPage: (page: number) => void;
  scrollToTop: () => void;
};

const JumpToPage = ({ page, setPage, currentPage, scrollToTop }: JumpToPageProps) => {
  const className = useMemo(() => {
    const baseClass = 'provider-cards-page-navigation-button';
    if (page === currentPage) {
      return baseClass + ' selected';
    }

    return baseClass;
  }, [page, currentPage]);
  return (
    <button
      onClick={() => {
        setPage(page);
        scrollToTop();
      }}
      className={className}
    >
      {page + 1}
    </button>
  );
};

type ProviderPageControllerProps = {
  page: number;
  setPage: (page: number) => void;
  providerCount: number;
  scrollToTop: () => void;
};

const ProviderPageController = ({ page, setPage, providerCount, scrollToTop }: ProviderPageControllerProps) => {
  const nextPage = () => {
    setPage(page + 1);
    scrollToTop();
  };
  const previousPage = () => {
    setPage(page - 1);
    scrollToTop();
  };
  const lastPage = useMemo(() => Math.ceil(providerCount / PROVIDERS_PER_PAGE) - 1, [providerCount]);
  const directPageLinks = useMemo(() => {
    const pageLinks = [];

    for (let i = 0; i <= lastPage; i++) {
      if (i === lastPage || i === 0) {
        pageLinks.push(<JumpToPage page={i} setPage={setPage} currentPage={page} key={i} scrollToTop={scrollToTop} />);
        continue;
      }

      if (Math.abs(page - i) > 2) {
        continue;
      }

      pageLinks.push(<JumpToPage page={i} setPage={setPage} currentPage={page} key={i} scrollToTop={scrollToTop} />);
    }

    return pageLinks;
  }, [page, providerCount]);

  return (
    <div className="provider-cards-page-navigation-container">
      <strong>
        <FormattedMessage id="providerCards.moreProviders" defaultMessage="More Providers:" />
      </strong>
      <div>
        {page > 0 && (
          <button onClick={previousPage} className="provider-cards-page-navigation-button">
            {'<'} Prev
          </button>
        )}
        {directPageLinks}
        {page < lastPage && (
          <button onClick={nextPage} className="provider-cards-page-navigation-button">
            Next {'>'}
          </button>
        )}
      </div>
    </div>
  );
};

type DebugDiffProps = {
  order: Provider[];
  index: number;
};
const DebugDiff = ({ order, index }: DebugDiffProps) => {
  const { filters, eligibility } = useProviderContext();
  const { formData } = useContext(Context);

  const childEligibility = useMemo(() => {
    return eligibility.find((el) => filters.childId === el.child_id);
  }, [filters.childId, eligibility]);

  const eligibleChildren = useEligibleChildren();
  const child = eligibleChildren.find((child) => child.childMember?.frontEndId === filters.childId);

  const searchParams = useSearchParams()[0];

  const debug = searchParams.get('debug') === 'true';

  if (
    !debug ||
    childEligibility === undefined ||
    child === undefined ||
    child.childMember === undefined ||
    index < 0 ||
    index > order.length - 2
  ) {
    return null;
  }

  const sorter = new SortProviders(childEligibility, formData, child.childMember, filters);

  return (
    <div>
      {'<'}
      {sorter.providerSortingDifference(order[index], order[index + 1])}
      {'>'}
    </div>
  );
};

const ProviderCards = () => {
  const { providers, filters, allProviders, focusedProvider } = useProviderContext();
  const [orderedProviders, setOrderedProviders] = useState<Provider[]>([]);
  const focusedRef = useRef<HTMLDivElement>(null);

  const [page, setPage] = useState(0);
  const providerIndexOffset = useMemo(() => page * PROVIDERS_PER_PAGE, [page]);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (focusedProvider === undefined || focusedProvider.scrollTo === false) {
      return;
    }

    let focusedIndex = orderedProviders.findIndex((provider) => provider.id === focusedProvider.provider.id);
    if (focusedIndex === -1) {
      return;
    }

    setPage(Math.floor(focusedIndex / PROVIDERS_PER_PAGE));
    setTimeout(() => {
      if (focusedRef.current !== null) {
        focusedRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    });
  }, [focusedProvider]);

  const scrollToTop = () => {
    if (containerRef.current === null) {
      return;
    }

    containerRef.current.scrollTop = 0;
    containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  useEffect(() => {
    if (filters.childId !== undefined) {
      setOrderedProviders(providers[filters.childId].filter(filterCitizen(filters)).filter(filterTypes(filters)));
      return;
    }

    const sortedProviders = allProviders.filter(filterTypes(filters)).toSorted((a, b) => {
      const aDist = calcCrow([a.latitude, a.longitude], [filters.address.lat, filters.address.lng]);
      const bDist = calcCrow([b.latitude, b.longitude], [filters.address.lat, filters.address.lng]);

      return aDist - bDist;
    });

    setOrderedProviders(sortedProviders);
  }, [providers, filters]);

  return (
    <div className="provider-cards-container" ref={containerRef}>
      <strong className="provider-cards-title">
        <FormattedMessage id="providerCards.title" defaultMessage="All Other Providers" />
      </strong>
      {orderedProviders
        .slice(0 + providerIndexOffset, PROVIDERS_PER_PAGE + providerIndexOffset)
        .map((provider, index) => {
          return (
            <div ref={focusedProvider?.provider.id === provider.id ? focusedRef : null} key={provider.id}>
              <ProviderCard provider={provider} />
              <DebugDiff order={orderedProviders} index={index} />
            </div>
          );
        })}
      <ProviderPageController
        page={page}
        setPage={setPage}
        providerCount={orderedProviders.length}
        scrollToTop={scrollToTop}
      />
    </div>
  );
};

export default ProviderCards;
