import { FormattedMessage } from 'react-intl';
import { FormattedMessageType } from '../Types/Questions';
export type Conditions =
  | 'employed'
  | 'selfEmployed'
  | 'searchingForJob'
  | 'postSecondaryStudent'
  | 'training'
  | 'englishSecondLanguageStudent'
  | 'highSchoolStudent'
  | 'middleSchoolStudent'
  | 'nationalGuard'
  | 'militaryReserves'
  | 'militaryActive'
  | 'pregnant'
  | 'breastFeeding'
  | 'noQualifyingFactor';

const adamsConditionOptions: Record<Conditions, FormattedMessageType> = {
  employed: <FormattedMessage id="employed" defaultMessage="Employed" />,
  selfEmployed: (
    <FormattedMessage
      id="selfEmployed"
      defaultMessage="Self-Employed - organize own work or are being paid in cash. No taxes are withheld from the money earned."
    />
  ),
  searchingForJob: <FormattedMessage id="searchingForJob" defaultMessage="Searching for a job" />,
  postSecondaryStudent: (
    <FormattedMessage
      id="postSecondaryStudent"
      defaultMessage="A Post-Secondary School Student - finished high school/GED and now enrolled in college or a trade/vocational school."
    />
  ),
  training: (
    <FormattedMessage
      id="training"
      defaultMessage="In a Training / Education program - currently enrolled in classes or a program to learn job skills. This could be a workforce training program or vocational / technical classes."
    />
  ),
  englishSecondLanguageStudent: (
    <FormattedMessage
      id="esl"
      defaultMessage="An English as a Second Language (ESL) Student - Currently enrolled in classes to learn English"
    />
  ),
  highSchoolStudent: <FormattedMessage id="highSchoolStudent" defaultMessage="GED Student" />,
  middleSchoolStudent: (
    <FormattedMessage id="middleSchoolStudent" defaultMessage="Middle / Junior High or High School Student" />
  ),
  nationalGuard: <FormattedMessage id="nationalGuard" defaultMessage="National Guard" />,
  militaryReserves: <FormattedMessage id="militaryReserves" defaultMessage="Military Reserves" />,
  militaryActive: <FormattedMessage id="militaryActive" defaultMessage="Active Military (serving full-time)" />,
  pregnant: (
    <FormattedMessage id="pregnant" defaultMessage="Currently pregnant or have been pregnant in the last 6 months" />
  ),
  breastFeeding: <FormattedMessage id="breastFeeding" defaultMessage="Breastfeeding a baby younger than a year old" />,
  noQualifyingFactor: <FormattedMessage id="noQualifyingFactor" defaultMessage="None of these apply" />,
};

export default adamsConditionOptions;
