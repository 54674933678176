import { FormattedMessage } from 'react-intl';

const birthMonthOptions = {
  '01': <FormattedMessage id="childBlock.month.jan" defaultMessage="January" />,
  '02': <FormattedMessage id="childBlock.month.feb" defaultMessage="February" />,
  '03': <FormattedMessage id="childBlock.month.mar" defaultMessage="March" />,
  '04': <FormattedMessage id="childBlock.month.apr" defaultMessage="April" />,
  '05': <FormattedMessage id="childBlock.month.may" defaultMessage="May" />,
  '06': <FormattedMessage id="childBlock.month.june" defaultMessage="June" />,
  '07': <FormattedMessage id="childBlock.month.july" defaultMessage="July" />,
  '08': <FormattedMessage id="childBlock.month.aug" defaultMessage="August" />,
  '09': <FormattedMessage id="childBlock.month.sep" defaultMessage="September" />,
  '10': <FormattedMessage id="childBlock.month.oct" defaultMessage="October" />,
  '11': <FormattedMessage id="childBlock.month.nov" defaultMessage="November" />,
  '12': <FormattedMessage id="childBlock.month.dec" defaultMessage="December" />,
};

export default birthMonthOptions;
