import { FormattedMessage } from 'react-intl';

export const contactTimeOptions = {
  weekdaysMornings: <FormattedMessage id="contactOptions.weekdaysMornings" defaultMessage="Weekday mornings" />,
  weekdaysLunch: <FormattedMessage id="contactOptions.weekdaysLunch" defaultMessage="Weekdays over lunch" />,
  weekdaysAfternoons: <FormattedMessage id="contactOptions.weekdaysAfternoons" defaultMessage="Weekday afternoons" />,
  weekdaysEvenings: <FormattedMessage id="contactOptions.weekdaysEvenings" defaultMessage="Weekday evenings" />,
  saturdays: <FormattedMessage id="contactOptions.saturdays" defaultMessage="Saturdays" />,
  sundays: <FormattedMessage id="contactOptions.sundays" defaultMessage="Sundays" />,
};
