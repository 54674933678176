import { FormattedMessage } from 'react-intl';
import './ProviderDescription.css';

const ProviderDescription = () => {
  return (
    <div className="provider-description-container">
      <p className="provider-description-paragraph bold">
        <FormattedMessage
          id="provider.description.0"
          defaultMessage="Confirm the providers you want below or use filters and map to adjust your top choices."
        />
      </p>
      <p className="provider-description-paragraph">
        <FormattedMessage
          id="provider.description.help"
          defaultMessage={
            'We\'ve selected 3 "best bet" providers based on your care needs. You can leave these as your top choices, or explore the filters and map to choose the options that you like best.'
          }
        />
      </p>
    </div>
  );
};

export default ProviderDescription;
