import { useContext, useMemo } from 'react';
import { Provider } from '../../Types/Eligibility';
import { Context } from '../Wrapper/Wrapper';
import { useFocused, useSetFocusedProvider } from './hooks';
import PlaceIcon from '@mui/icons-material/Place';
import './ProviderMapMarkers.css';

type ProviderMarkerProps = {
  provider: Provider;
  center?: boolean;
};

export const ProviderMarker = ({ provider, center }: ProviderMarkerProps) => {
  const { formData } = useContext(Context);
  const focused = useFocused(provider);
  const setFocusedProvider = useSetFocusedProvider(provider);

  const selected = formData.members.some((member) => {
    return (
      member.childMember?.selectedProviders.some((selectedProvider) => selectedProvider.id === provider.id) ?? false
    );
  });

  const className = useMemo(() => {
    let className = 'provider-marker marker';

    if (selected) {
      className += ' provider-selected';
    }

    if (focused) {
      className += ' focused';
    }

    if (center) {
      className += ' center';
    }

    return className;
  }, [focused, selected, center]);

  return (
    <div className={className} onClick={setFocusedProvider}>
      <PlaceIcon className="marker-icon" />
    </div>
  );
};

type ClusterMarkerProps = {
  count: number;
  total: number;
};

export const ClusterMarker = ({ count, total }: ClusterMarkerProps) => {
  const size = 1.7 + (count / total) * 3 + 'rem';
  return (
    <div className="cluster-marker marker" style={{ width: size, height: size }}>
      <span className="marker-text">{count}</span>
    </div>
  );
};

type YouMarkerProps = {
  center?: boolean;
};
export const YouMarker = ({ center }: YouMarkerProps) => {
  const className = useMemo(() => {
    let className = 'you-marker marker';

    if (center) {
      className += ' center';
    }

    return className;
  }, [center]);

  return (
    <div className={className}>
      <PlaceIcon className="marker-icon" />
    </div>
  );
};
