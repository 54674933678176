import { Filters, MemberEligibility, Provider } from '../../Types/Eligibility';
import { Application, ChildCareNeed, ChildMember } from '../../Types/FormData';
import { childProviderTypes } from './hooks';

/*
  Priority of sorting conditions:
    1. Provider in 7 miles
    2. Exact need met
    3. More need met
    4. Type of care met
    5. Head Start providers
    6. UPK school district providers
    7. CCCAP providers
    8. CCCAP + UPK providers
    9. UPK only providers
    10. Distance
*/

export const KM_PER_MILE = 1.609344;

type ApiNeedsProvided = 'extended_day' | 'full_day' | 'half_day' | 'part_time' | 'none';

const A_BEFORE_B = -1;
const B_BEFORE_A = 1;
const A_EQUAL_B = 0;

type SortResult = -1 | 0 | 1;

type SortFunction = (a: Provider, b: Provider) => SortResult;

export default class SortProviders {
  private preferredProviderType: string[];
  constructor(
    private eligibility: MemberEligibility,
    private application: Application,
    private child: ChildMember,
    private filters: Filters,
  ) {
    this.preferredProviderType = this.childPreferredProviderTypes();
  }

  private distanceFromProvider(provider: Provider): number {
    // return distance from the address to the provider in km
    return calcCrow([provider.latitude, provider.longitude], [this.filters.address.lat, this.filters.address.lng]);
  }

  private distanceGroup(provider: Provider): number {
    const distance = this.distanceFromProvider(provider);

    const milesInKm = 7 * KM_PER_MILE;
    return Math.floor(distance / milesInKm);
  }

  private sortDistanceGroup(a: Provider, b: Provider): SortResult {
    const aDistanceGroup = this.distanceGroup(a);
    const bDistanceGroup = this.distanceGroup(b);

    if (aDistanceGroup < bDistanceGroup) {
      return A_BEFORE_B;
    } else if (aDistanceGroup > bDistanceGroup) {
      return B_BEFORE_A;
    } else {
      return A_EQUAL_B;
    }
  }

  private sortDistance(a: Provider, b: Provider): SortResult {
    const aDistance = this.distanceFromProvider(a);
    const bDistance = this.distanceFromProvider(b);

    if (aDistance < bDistance) {
      return A_BEFORE_B;
    } else if (aDistance > bDistance) {
      return B_BEFORE_A;
    } else {
      return A_EQUAL_B;
    }
  }

  private hoursToNeed(hours: number): ApiNeedsProvided {
    if (hours > 40) {
      return 'extended_day';
    }
    if (hours > 30) {
      return 'full_day';
    }
    if (hours > 15) {
      return 'half_day';
    }
    if (hours > 0) {
      return 'part_time';
    }

    return 'none';
  }

  private canCover(needsCovered: ApiNeedsProvided, ...provided: ApiNeedsProvided[]) {
    return provided.includes(needsCovered);
  }

  private mapCccap(provider: Provider): Set<ChildCareNeed> {
    const needCovered = this.hoursToNeed(this.eligibility.eligibility.cccap.hours);
    const providerCoverage = provider.cccap;

    const providedNeeds = new Set<ChildCareNeed>();
    if (providerCoverage.extended_day && this.canCover(needCovered, 'extended_day')) {
      providedNeeds.add('extended_day');
    }
    if (providerCoverage.full_day && this.canCover(needCovered, 'extended_day', 'full_day')) {
      providedNeeds.add('full_day');
    }
    if (providerCoverage.half_day_am && this.canCover(needCovered, 'extended_day', 'half_day')) {
      providedNeeds.add('half_day_am');
    }
    if (providerCoverage.half_day_pm && this.canCover(needCovered, 'extended_day', 'half_day')) {
      providedNeeds.add('half_day_pm');
    }
    if (providerCoverage.part_time && this.canCover(needCovered, 'extended_day', 'half_day', 'part_time')) {
      providedNeeds.add('part_time');
    }

    return providedNeeds;
  }

  private mapUpk(provider: Provider): Set<ChildCareNeed> {
    const needCovered = this.hoursToNeed(this.eligibility.eligibility.upk.hours);
    const providerCoverage = provider.upk;

    const providedNeeds = new Set<ChildCareNeed>();

    if (providerCoverage.extended_day && this.canCover(needCovered, 'extended_day')) {
      providedNeeds.add('extended_day');
    }
    if (providerCoverage.full_day && this.canCover(needCovered, 'full_day')) {
      providedNeeds.add('full_day');
    }
    if (providerCoverage.half_day && this.canCover(needCovered, 'half_day')) {
      providedNeeds.add('half_day_am').add('half_day_pm');
    }
    if (providerCoverage.half_day_am && this.canCover(needCovered, 'half_day')) {
      providedNeeds.add('half_day_am');
    }
    if (providerCoverage.half_day_pm && this.canCover(needCovered, 'half_day')) {
      providedNeeds.add('half_day_pm');
    }
    if (
      (providerCoverage.part_time || providerCoverage.part_time_am || providerCoverage.part_time_pm) &&
      this.canCover(needCovered, 'part_time')
    ) {
      providedNeeds.add('part_time');
    }
    return providedNeeds;
  }

  private mapHeadStart(provider: Provider): Set<ChildCareNeed> {
    const needsCovered = this.hoursToNeed(this.eligibility.eligibility.head_start.hours);
    const providerCoverage = provider.head_start;

    const providedNeeds = new Set<ChildCareNeed>();
    if (providerCoverage.extended_day && this.canCover(needsCovered, 'extended_day')) {
      providedNeeds.add('extended_day');
    }
    if ((providerCoverage.full_day_28 || providerCoverage.full_day_35) && this.canCover(needsCovered, 'full_day')) {
      providedNeeds.add('full_day');
    }
    if (providerCoverage.half_day_am && this.canCover(needsCovered, 'half_day')) {
      providedNeeds.add('half_day_am');
    }
    if (providerCoverage.half_day_pm && this.canCover(needsCovered, 'half_day')) {
      providedNeeds.add('half_day_pm');
    }
    if (providerCoverage.part_time && this.canCover(needsCovered, 'part_time')) {
      providedNeeds.add('part_time');
    }

    return providedNeeds;
  }

  private providerNeedsMet(provider: Provider): Set<ChildCareNeed> {
    return new Set<ChildCareNeed>([
      ...this.mapCccap(provider),
      ...this.mapUpk(provider),
      ...this.mapHeadStart(provider),
    ]);
  }

  private moreNeedMet(provider: Provider): boolean {
    const need = this.child.childCareNeeds.neededCare;
    const providerNeeds = this.providerNeedsMet(provider);

    if (need === 'extended_day') {
      return false;
    }

    if (need === 'full_day') {
      return providerNeeds.has('extended_day');
    }

    if (need === 'half_day_am' || need === 'half_day_pm') {
      return providerNeeds.has('extended_day') || providerNeeds.has('full_day');
    }

    if (need === 'part_time') {
      return (
        providerNeeds.has('extended_day') ||
        providerNeeds.has('full_day') ||
        providerNeeds.has('half_day_am') ||
        providerNeeds.has('half_day_pm')
      );
    }

    return false;
  }

  private sortNeedMet(a: Provider, b: Provider): SortResult {
    const childNeed = this.child.childCareNeeds.neededCare;

    const aNeedMet = this.providerNeedsMet(a).has(childNeed);
    const bNeedMet = this.providerNeedsMet(b).has(childNeed);

    if (aNeedMet && !bNeedMet) {
      return A_BEFORE_B;
    }

    if (bNeedMet && !aNeedMet) {
      return B_BEFORE_A;
    }

    return A_EQUAL_B;
  }

  private sortMoreNeedMet(a: Provider, b: Provider): SortResult {
    const aMoreNeedMet = this.moreNeedMet(a);
    const bMoreNeedMet = this.moreNeedMet(b);

    if (aMoreNeedMet && !bMoreNeedMet) {
      return B_BEFORE_A;
    }

    if (bMoreNeedMet && !aMoreNeedMet) {
      return A_BEFORE_B;
    }

    return A_EQUAL_B;
  }

  private childPreferredProviderTypes(): string[] {
    return childProviderTypes(this.child.childCareNeeds);
  }

  private isProviderType(provider: Provider): boolean {
    const headStart = provider.head_start.on_provider_list && this.child.childCareNeeds.headStart;
    const preschool = provider.preschool_provider && this.child.childCareNeeds.preschool;
    const providerType = this.preferredProviderType.includes(provider.provider_type);

    return headStart || preschool || providerType;
  }

  private sortPreferredProviderType(a: Provider, b: Provider): SortResult {
    const aIsPreferred = this.isProviderType(a);
    const bIsPreferred = this.isProviderType(b);

    if (aIsPreferred && !bIsPreferred) {
      return A_BEFORE_B;
    }
    if (bIsPreferred && !aIsPreferred) {
      return B_BEFORE_A;
    }
    return A_EQUAL_B;
  }

  private sortHeadStart(a: Provider, b: Provider): SortResult {
    if (!this.eligibility.eligibility.head_start.eligible) {
      return A_EQUAL_B;
    }

    const aHeadStart = a.head_start.on_provider_list;
    const bHeadStart = b.head_start.on_provider_list;

    if (aHeadStart && !bHeadStart) {
      return A_BEFORE_B;
    }

    if (bHeadStart && !aHeadStart) {
      return B_BEFORE_A;
    }

    if (aHeadStart && bHeadStart) {
      return this.sortDistance(a, b);
    }

    return A_EQUAL_B;
  }

  private providerInSchoolDistrict(provider: Provider): boolean {
    if (!provider.upk.part_time) {
      return false;
    }

    if (!provider.school_based_provider || !provider.preschool_provider) {
      return false;
    }

    if (provider.school_district === this.application.schoolDistrict) {
      return true;
    }

    return false;
  }

  private sortSchoolDistrict(a: Provider, b: Provider): SortResult {
    if (!this.eligibility.eligibility.upk.eligible) {
      return A_EQUAL_B;
    }

    const aInSchoolDistrict = this.providerInSchoolDistrict(a);
    const bInSchoolDistrict = this.providerInSchoolDistrict(b);

    if (aInSchoolDistrict && !bInSchoolDistrict) {
      return A_BEFORE_B;
    }

    if (bInSchoolDistrict && !aInSchoolDistrict) {
      return B_BEFORE_A;
    }

    if (aInSchoolDistrict && bInSchoolDistrict) {
      return this.sortDistance(a, b);
    }

    return A_EQUAL_B;
  }

  private providerStackable(provider: Provider): boolean {
    if (!provider.upk.part_time) {
      return false;
    }

    if (!provider.cccap.on_provider_list) {
      return false;
    }

    return true;
  }

  private sortStacked(a: Provider, b: Provider): SortResult {
    if (this.eligibility.eligibility.upk.hours !== 15) {
      return A_EQUAL_B;
    }
    if (!this.eligibility.eligibility.cccap.eligible) {
      return A_EQUAL_B;
    }
    if (this.child.childCareNeeds.neededCare !== 'full_day') {
      return A_EQUAL_B;
    }

    const aStacked = this.providerStackable(a);
    const bStacked = this.providerStackable(b);

    if (aStacked && !bStacked) {
      return A_BEFORE_B;
    }

    if (bStacked && !aStacked) {
      return B_BEFORE_A;
    }

    if (aStacked && bStacked) {
      return this.sortDistance(a, b);
    }

    return A_EQUAL_B;
  }

  private sortCccapProvider(a: Provider, b: Provider): SortResult {
    if (!this.eligibility.eligibility.cccap.eligible) {
      return A_EQUAL_B;
    }

    const aCccapProvider = a.cccap.on_provider_list;
    const bCccapProvider = b.cccap.on_provider_list;

    if (aCccapProvider && !bCccapProvider) {
      return A_BEFORE_B;
    }

    if (bCccapProvider && !aCccapProvider) {
      return B_BEFORE_A;
    }

    return A_EQUAL_B;
  }

  private sortUpkProvider(a: Provider, b: Provider): SortResult {
    if (!this.eligibility.eligibility.upk.eligible) {
      return A_EQUAL_B;
    }

    const aUpkProvider = a.upk.on_provider_list;
    const bUpkProvider = b.upk.on_provider_list;

    if (aUpkProvider && !bUpkProvider) {
      return A_BEFORE_B;
    }

    if (bUpkProvider && !aUpkProvider) {
      return B_BEFORE_A;
    }

    return A_EQUAL_B;
  }

  sorters: SortFunction[] = [
    this.sortDistanceGroup,
    this.sortNeedMet,
    this.sortMoreNeedMet,
    this.sortPreferredProviderType,
    this.sortHeadStart,
    this.sortSchoolDistrict,
    this.sortStacked,
    this.sortCccapProvider,
    this.sortUpkProvider,
    this.sortDistance,
  ];

  sort(): SortFunction {
    return (a: Provider, b: Provider): SortResult => {
      // Loop through all of the sorting functions
      // If a sorting function returns that one provider is higher than another return that
      for (const sorter of this.sorters) {
        const result = sorter.bind(this)(a, b);

        if (result !== A_EQUAL_B) {
          return result;
        }
      }

      return A_EQUAL_B;
    };
  }

  providerSortingDifference(a: Provider, b: Provider): string {
    const names = [
      'distance group',
      'need met',
      'more need met',
      'preferred provider type',
      'head start',
      'school district',
      'stacking upk and cccap',
      'cccap provider',
      'upk provider',
      'distance',
    ];
    for (const index in this.sorters) {
      const result = this.sorters[index].bind(this)(a, b);

      if (result !== A_EQUAL_B) {
        return names[index];
      }
    }

    return 'equal';
  }

  testFilter() {
    // WARN: this is for test purposes only
    return (provider: Provider) => {
      if (this.distanceGroup.bind(this)(provider) !== 0) {
        return false;
      }

      console.log(this.providerNeedsMet.bind(this)(provider));

      if (this.providerNeedsMet.bind(this)(provider).has(this.child.childCareNeeds.neededCare)) {
        return true;
      }

      return false;
    };
  }
}

// https://stackoverflow.com/a/18883819
//This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)

type Cordinates = [number, number];

// Converts numeric degrees to radians
function toRad(value: number) {
  return (value * Math.PI) / 180;
}

export function calcCrow(cords1: Cordinates, cords2: Cordinates): number {
  const R = 6371; // km
  const dLat = toRad(cords2[0] - cords1[0]);
  const dLon = toRad(cords2[1] - cords1[1]);
  const lat1 = toRad(cords1[0]);
  const lat2 = toRad(cords2[0]);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;

  return d;
}
