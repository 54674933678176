import { FormattedMessage } from 'react-intl';

const finalConditionOptions = {
  iep: (
    <FormattedMessage
      id="childBlock.finalConditions.iep"
      defaultMessage="Has an active Individualized Education Program (IEP)"
    />
  ),
  limitedEnglish: <FormattedMessage id="childBlock.finalConditions.limitedEnglish" defaultMessage="Is multilingual" />,
  unableToCareForThemself: (
    <FormattedMessage
      id="childBlock.finalConditions.unableToCareForThemself"
      defaultMessage="Has a disability or is physically or mentally incapable of caring for themself"
    />
  ),
};

export default finalConditionOptions;
