import { useMemo } from 'react';
import { ChildMember, SelectedProvider } from '../../Types/FormData';
import {
  formatHours,
  useEligibleChildren,
  useHoursEligible,
  useProviderContext,
  useSetFocusedProvider,
  useUpdateSelectedProviders,
} from './hooks';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import './ProviderChildren.css';
import { useSearchParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Visibility } from '@mui/icons-material';

type DebugEligibilityProps = {
  child: ChildMember;
};
const DebugEligibility = ({ child }: DebugEligibilityProps) => {
  const { eligibility } = useProviderContext();
  const searchParams = useSearchParams()[0];

  const debug = searchParams.get('debug') === 'true';

  const childElgibility = useMemo(() => {
    return eligibility.find((eligibility) => eligibility.child_id === child.frontEndId)?.eligibility;
  }, [child.frontEndId, eligibility]);

  if (!debug || childElgibility === undefined) {
    return null;
  }

  return (
    <div>
      <div>Upk</div>
      <hr></hr>
      <div>
        <div>
          <strong>Eligible: </strong>
          {childElgibility.upk.eligible ? 'TRUE' : 'FALSE'}
        </div>
        <div>
          <strong>Hours: </strong>
          {childElgibility.upk.hours}
        </div>
      </div>
      <div>CCCAP</div>
      <hr></hr>
      <div>
        <div>
          <strong>Eligible: </strong>
          {childElgibility.cccap.eligible ? 'TRUE' : 'FALSE'}
        </div>
        <div>
          <strong>Hours: </strong>
          {childElgibility.cccap.hours}
        </div>
      </div>
      <div>Head Start</div>
      <hr></hr>
      <div>
        <div>
          <strong>Eligible: </strong>
          {childElgibility.head_start.eligible ? 'TRUE' : 'FALSE'}
        </div>
        <div>
          <strong>Hours: </strong>
          {childElgibility.head_start.hours}
        </div>
      </div>
    </div>
  );
};

type SelectedProviderProps = {
  child: ChildMember;
  provider: SelectedProvider;
};

const ChildSelectedProvider = ({ provider, child }: SelectedProviderProps) => {
  const { focusedProvider, allProviders } = useProviderContext();
  const intl = useIntl();

  const providerDetails = useMemo(() => {
    const details = allProviders.find((provider1) => provider.id === provider1.id);

    if (details === undefined) {
      throw new Error('Provider does not exist');
    }

    return details;
  }, [provider, allProviders]);

  const hoursEligible = useHoursEligible(child.frontEndId, providerDetails);
  const formattedHoursEligible = useMemo(
    () =>
      `(${formatHours(hoursEligible)} ${intl.formatMessage({
        id: 'provider.childCards.hoursFree',
        defaultMessage: 'hours free',
      })})`,
    [hoursEligible],
  );

  const className = useMemo(() => {
    let className = 'providers-child-card-provider-item';

    if (focusedProvider?.provider.id === provider.id) {
      className += ' focused';
    }

    return className;
  }, [focusedProvider, provider]);

  const updateSelectedProviders = useUpdateSelectedProviders();

  const handleRemoveProvider = () => {
    const updatedProviders = child.selectedProviders.filter((selectedProvider) => selectedProvider.id !== provider.id);

    updateSelectedProviders(child.frontEndId, updatedProviders);
  };

  const handleClick = useSetFocusedProvider(providerDetails);

  return (
    <li>
      <div className={className}>
        <button className="provider-child-card-provider-name" onClick={handleClick}>
          {provider.name} {formattedHoursEligible}
        </button>
        <div>
          <button className="providers-child-card-provider-button view" onClick={handleClick}>
            <Visibility sx={{ fontSize: '1.1rem', transform: 'translate(0, .25rem)' }} />
            <FormattedMessage id="provider.childCards.viewButton" defaultMessage="View" />
          </button>
          <button className="providers-child-card-provider-button close" onClick={handleRemoveProvider}>
            <DeleteOutlineIcon sx={{ fontSize: '1.1rem', transform: 'translate(0, .25rem)' }} />
            <FormattedMessage id="provider.childCards.removeButton" defaultMessage="Remove" />
          </button>
        </div>
      </div>
    </li>
  );
};

type ResetToDefaultProps = {
  child: ChildMember;
};
const ResetToDefault = ({ child }: ResetToDefaultProps) => {
  const updateSelectedProviders = useUpdateSelectedProviders();
  const { providers } = useProviderContext();

  const clickHandler = () => {
    updateSelectedProviders(child.frontEndId, providers[child.frontEndId].slice(0, 3));
  };

  return (
    <button onClick={clickHandler} className="provider-child-card-reset-button">
      <FormattedMessage id="provider.childCards.resetButton" defaultMessage="Restore Recommended Providers" />
    </button>
  );
};

type ChildCardProps = {
  child: ChildMember;
};

const ChildCard = ({ child }: ChildCardProps) => {
  const selectedProviders = child.selectedProviders;
  const { errorController } = useProviderContext();

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  const className = useMemo(() => {
    let className = 'providers-child-card-container';

    if (errorController.showError && selectedProviders.length !== 3) {
      className += ' error';
    }

    return className;
  }, [errorController.showError, selectedProviders.length]);

  return (
    <div className={className}>
      <strong className="providers-child-card-child-name">
        <FormattedMessage id="providers.childCards.title" defaultMessage="Select 3 providers for " />
        {child.firstName} {child.lastName}
      </strong>
      <div className="providers-child-card-description-contianer">
        <p className="providers-child-card-description">
          <FormattedMessage
            id="providers.childCards.description1"
            defaultMessage="Your top choices will be sent to a navigator who will help you find out about availability."
          />
          <FormattedMessage id="providers.childCards.description2.pre" defaultMessage=' Hit "' />
          <button onClick={scrollToBottom} className="providers-child-card-child-continue-link">
            <FormattedMessage id="providers.childCards.description2.link" defaultMessage="Continue" />
          </button>
          <FormattedMessage
            id="providers.childCards.description2.post"
            defaultMessage='" at the bottom of the page or select new providers from the list below.'
          />
        </p>
      </div>
      <ul className="providers-child-card-provider-list">
        {selectedProviders.map((provider) => {
          return <ChildSelectedProvider provider={provider} child={child} key={provider.id} />;
        })}
      </ul>
      <ResetToDefault child={child} />
      <DebugEligibility child={child} />
    </div>
  );
};

const ProviderChildren = () => {
  const { errorController } = useProviderContext();
  const childEligibility = useEligibleChildren();

  return (
    <section>
      <div className="providers-child-container">
        {childEligibility.map((member) => {
          if (member.childMember === undefined) {
            throw new Error('Member is not a child member');
          }

          return <ChildCard child={member.childMember} key={member.childMember.frontEndId} />;
        })}
      </div>
      {errorController.showError && <div>{errorController.message(null)}</div>}
    </section>
  );
};

export default ProviderChildren;
