import GoogleMapReact from 'google-map-react';
import './ProviderMap.css';
import { useContext, useMemo, useState, useEffect } from 'react';
import { Context } from '../Wrapper/Wrapper';
import { providerIsSelected, useEligibleChildren, useProviderContext } from './hooks';
import useSupercluster from 'use-supercluster';
import { Provider } from '../../Types/Eligibility';
import { ClusterMarker, ProviderMarker, YouMarker } from './ProviderMapMarkers';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
if (GOOGLE_API_KEY === undefined) {
  throw new Error('Google API key not set');
}

const INITIAL_ZOOM = 14;

const ProviderMap = () => {
  const { formData } = useContext(Context);
  const { allProviders, focusedProvider, filters } = useProviderContext();

  const [bounds, setBounds] = useState<[number, number, number, number] | null>(null);
  const [zoom, setZoom] = useState<number>(INITIAL_ZOOM);

  const eligibleChildren = useEligibleChildren();

  const allSelectedProviders = useMemo(() => {
    return allProviders.filter((provider) => {
      return eligibleChildren.some((child) => {
        if (child.childMember === undefined) {
          return false;
        }

        if (provider.id === focusedProvider?.provider.id) {
          return false;
        }

        return providerIsSelected(provider, child.childMember);
      });
    });
  }, [eligibleChildren, allProviders, focusedProvider]);

  const points = useMemo(
    () =>
      allProviders
        .filter((provider) => {
          return (
            !allSelectedProviders.some((selectedProvider) => selectedProvider.id === provider.id) &&
            !(focusedProvider?.provider.id === provider.id)
          );
        })
        .map((provider) => {
          return {
            type: 'Feature',
            properties: { cluster: false, provider: provider },
            geometry: {
              type: 'Point',
              coordinates: [provider.longitude, provider.latitude],
            },
          };
        }),
    [allProviders, allSelectedProviders, allSelectedProviders.length, focusedProvider],
  );

  const { clusters } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 100, maxZoom: 13 },
  });

  // The autocomplete does not load when there is a map on the screen.
  // So this is a hack to load it.
  // https://github.com/ErrorPro/react-google-autocomplete/issues/205
  const [delayed, setDelayed] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setDelayed(false);
    }, 500);
  }, []);

  if (delayed) {
    return <div className="map-container"></div>;
  }

  const lat = formData.address?.latitude ?? 0;
  const lng = formData.address?.longitude ?? 0;
  return (
    <div className="map-container">
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
        zoom={INITIAL_ZOOM}
        center={{ lat, lng }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
        }}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } = cluster.properties;
          const providerCords = { lat: latitude, lng: longitude };

          if (isCluster) {
            return (
              <ClusterMarker
                {...providerCords}
                count={pointCount}
                total={points.length}
                key={'cluster_' + cluster.id}
              />
            );
          }

          const provider: Provider = cluster.properties.provider;

          return (
            <ProviderMarker {...providerCords} provider={provider} center={true} key={'provider_' + provider.id} />
          );
        })}
        {allSelectedProviders.map((provider) => {
          const providerCords = { lat: provider.latitude, lng: provider.longitude };
          return (
            <ProviderMarker {...providerCords} provider={provider} center={true} key={'provider_' + provider.id} />
          );
        })}
        {focusedProvider !== undefined && (
          <ProviderMarker
            {...{ lat: focusedProvider?.provider.latitude, lng: focusedProvider?.provider.longitude }}
            provider={focusedProvider.provider}
            center={true}
            key="focused_provider"
          />
        )}
        <YouMarker {...(filters.address as any)} center={true} />
      </GoogleMapReact>
    </div>
  );
};

export default ProviderMap;
