import { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Box, Button, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import relationshipOptions from '../../Assets/relationshipOptions';
import { personDataIsValid, selectHasError, relationTypeHelperText } from '../../Assets/validationFunctions.tsx';
import { getStepNumber } from '../../Assets/stepDirectory';
import { Context } from '../Wrapper/Wrapper.tsx';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import AdultBlock from '../MemberData/AdultBlock.tsx';
import ChildBlock from '../MemberData/ChildBlock.tsx';
import './HouseholdDataBlock.css';
import HelpBubble from '../HelpBubble/HelpBubble';

const HouseholdDataBlock = ({ handleHouseholdDataSubmit }) => {
  const { formData, setFormData } = useContext(Context);
  const { householdSize } = formData;
  const remainingHHMNumber = Number(householdSize);
  let { uuid, page } = useParams();
  page = parseInt(page);
  const step = getStepNumber('householdData');
  const navigate = useNavigate();
  const setPage = (page) => {
    navigate(`/${uuid}/step-${step}/${page}`);
  };
  const [submittedCount, setSubmittedCount] = useState(0);
  const isChild = ['', 'child', 'foster_child', 'step_child', 'grandchild'];

  const initialMemberData = formData.members[page - 1] ?? {
    relationship: page === 1 ? 'head_of_household' : '',
    adultMember: undefined,
    childMember: undefined,
  };

  const [memberData, setMemberData] = useState(initialMemberData);
  const [wasSubmitted, setWasSubmitted] = useState(false);

  useEffect(() => {
    const updatedMembers = formData.members;
    updatedMembers[page - 1] = memberData;
    setFormData({ ...formData, members: updatedMembers });
  }, [memberData]);

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, [wasSubmitted]);

  const displayRelationshipQ = () => {
    return (
      <Box sx={{ marginBottom: '1.5rem' }}>
        <h2 className="question-label">
          <FormattedMessage
            id="householdDataBlock.createHOfHRelationQuestion-relation"
            defaultMessage="What is this person’s relationship to you?"
          />
          <HelpBubble
            listOfMessages={[
              {
                fMsgId: 'questions.householdSize-description',
                fMDefaultMsg:
                  'This is usually family members whom you live with and share important resources with like food and bills.',
              },
            ]}
          />
        </h2>
        {createRelationshipDropdownMenu()}
      </Box>
    );
  };

  const createQuestionHeader = (personIndex) => {
    let header;

    if (personIndex === 1) {
      header = (
        <h1 className="sub-header question-label">
          <FormattedMessage id="householdDataBlock.questionHeader" defaultMessage="Tell us about yourself." />
        </h1>
      );
    } else {
      header = (
        <h1 className="sub-header question-label">
          <FormattedMessage
            id="questions.householdData"
            defaultMessage="Tell us about the next person in your household."
          />
        </h1>
      );
    }

    return (
      <>
        {header}
        {
          <Box sx={{ marginBottom: '1.5rem' }}>
            <h2 className="household-data-sub-header secondary-heading">
              <FormattedMessage id="qcc.so-far-text" defaultMessage="So far you've told us about:" />
            </h2>
            <div>
              {formData.members.map((member, index) => {
                return createMembersAdded(member, index);
              })}
            </div>
          </Box>
        }
      </>
    );
  };

  const createDropdownCompProps = () => {
    const dropdownCompProps = {
      labelId: 'relation-to-hh-label',
      inputLabelText: (
        <FormattedMessage id="householdDataBlock.createDropdownCompProps-inputLabelText" defaultMessage="Relation" />
      ),
      id: 'relationship-select',
      label: <FormattedMessage id="householdDataBlock.createDropdownCompProps-label" defaultMessage="Relation Type" />,
      disabledSelectMenuItemText: (
        <FormattedMessage
          id="householdDataBlock.createDropdownCompProps-disabledSelectMenuItemText"
          defaultMessage="Click to select relationship"
        />
      ),
    };

    const details = {
      componentProperties: dropdownCompProps,
      inputError: selectHasError,
      inputHelperText: relationTypeHelperText,
    };

    return details;
  };

  const createRelationshipDropdownMenu = () => {
    const relationshipsWOHeadofHH = Object.entries(relationshipOptions)
      .filter((option) => option[0] !== 'head_of_household')
      .reduce((acc, filteredOptions) => {
        acc[filteredOptions[0]] = filteredOptions[1];
        return acc;
      }, {});

    return (
      <DropdownMenu
        componentDetails={createDropdownCompProps()}
        options={relationshipsWOHeadofHH}
        setMemberData={setMemberData}
        memberData={memberData}
        submitted={submittedCount}
      />
    );
  };

  const formatToUSD = (num) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
  };

  const getAdultMembers = (members, childDefinitionList) => {
    return members.filter((member) => !childDefinitionList.includes(member.relationship));
  };

  const createMembersAdded = (member, index) => {
    //add head of hh to relationshipOptions list
    relationshipOptions.head_of_household = (
      <FormattedMessage id="relationshipOptions.yourself" defaultMessage="Yourself" />
    );

    let relationship = relationshipOptions[member.relationship];
    const currentMemberIsDefined = relationship !== undefined;

    // let income = 0;
    // for (const { frequency, amount, hoursWorked } of member.adultMember.income) {
    //   let num = 0;
    //   switch (frequency) {
    //     case 'weekly':
    //       num = Number(amount) * 52;
    //       break;
    //     case 'biweekly':
    //       num = Number(amount) * 26;
    //       break;
    //     case 'monthly':
    //       num = Number(amount) * 12;
    //       break;
    //     case 'hourly':
    //       num = Number(amount) * Number(hoursWorked) * 52;
    //       break;
    //   }
    //   income += Number(num);
    // }

    const containerClassName = `member-added-container ${index + 1 === page ? 'current-household-member' : ''}`;

    const handleEditSubmit = () => {
      setSubmittedCount(submittedCount + 1);

      const validPersonData = personDataIsValid(memberData);
      if (validPersonData) {
        handleHouseholdDataSubmit(memberData, page - 1, uuid);
        navigate(`/${uuid}/step-${step}/${index + 1}`);
      }
    };

    return (
      currentMemberIsDefined && (
        <article className={containerClassName} key={index}>
          <div className="household-member-header">
            <h3 className="member-added-relationship">{relationship}</h3>
            <div className="household-member-edit-button">
              <IconButton onClick={handleEditSubmit}>
                <EditIcon />
              </IconButton>
            </div>
          </div>
          {/* <div className="member-added-income">
          <FormattedMessage id="householdDataBlock.member-income" defaultMessage="Income" />:{' '}
          {formatToUSD(Number(income))}
          <FormattedMessage id="displayAnnualIncome.annual" defaultMessage=" annually" />
        </div> */}
        </article>
      )
    );
  };

  const handleContinueSubmit = (event) => {
    event.preventDefault();
    setSubmittedCount(submittedCount + 1);

    const validPersonData = personDataIsValid(memberData);
    const lastHouseholdMember = page >= remainingHHMNumber;
    if (validPersonData && lastHouseholdMember) {
      handleHouseholdDataSubmit(memberData, page - 1, uuid);
      navigate(`/${uuid}/step-${step + 1}`);
    } else if (validPersonData) {
      handleHouseholdDataSubmit(memberData, page - 1, uuid);
      setPage(page + 1);
    } else if (!validPersonData) {
      setWasSubmitted(true);
    }
  };

  const handlePreviousSubmit = () => {
    if (page <= 1) {
      navigate(`/${uuid}/step-${step - 1}`);
    } else {
      setPage(page - 1);
    }
  };

  const renderAdultOrChildBlock = (relationship) => {
    return isChild.includes(relationship) ? (
      <ChildBlock page={page} memberData={memberData} setMemberData={setMemberData} submitted={submittedCount} />
    ) : (
      <AdultBlock page={page} memberData={memberData} setMemberData={setMemberData} submitted={submittedCount} />
    );
  };

  return (
    <main className="benefits-form">
      {createQuestionHeader(page)}
      {page !== 1 && displayRelationshipQ()}
      {memberData.relationship && renderAdultOrChildBlock(memberData.relationship)}
      <div className="question-buttons">
        <Button
          variant="outlined"
          startIcon={<NavigateBeforeIcon sx={{ mr: '-.5rem' }} />}
          onClick={handlePreviousSubmit}
        >
          <FormattedMessage id="previousButton" defaultMessage="Back" />
        </Button>
        <Button variant="contained" onClick={handleContinueSubmit}>
          <FormattedMessage id="continueButton" defaultMessage="Continue" />{' '}
        </Button>
      </div>
    </main>
  );
};

export default HouseholdDataBlock;
