import { useEffect } from 'react';
import { Member, ChildMember, ChildCareNeeds } from '../../Types/FormData';
import { FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import birthMonthOptions from '../../Assets/ChildBlockAssets/birthMonthOptions';
import birthDayOptions from '../../Assets/ChildBlockAssets/birthDayOptions';
import CheckboxGroup from '../CheckboxGroup/CheckboxGroup';
import childCareOptions from '../../Assets/ChildBlockAssets/childCareOptions';
import neededCareOptions from '../../Assets/ChildBlockAssets/neededCareOptions';
import finalConditionOptions from '../../Assets/ChildBlockAssets/finalConditionOptions';
import ErrorBox from '../ErrorBox/ErrorBox';
import HelpBubble from '../HelpBubble/HelpBubble';
import { ReactComponent as WarningIcon } from '../../Assets/warning-icon.svg';
import { FormattedMessageType } from '../../Types/Questions';
import { childCareStartDateOptions } from '../../Assets/ChildBlockAssets/childCareStartDateOptions';
import './ChildBlock.css';

type HHDBMemberProps = {
  page: number;
  memberData: Member;
  setMemberData: (member: Member) => void;
  submitted: number;
};

const ChildBlock = ({ page, memberData, setMemberData, submitted }: HHDBMemberProps) => {
  let initialChildData: ChildMember = {
    frontEndId: crypto.randomUUID(),
    firstName: '',
    lastName: '',
    birthYear: '',
    birthMonth: '',
    birthDay: '',
    seekingChildCare: false,
    iep: false,
    limitedEnglish: false,
    unableToCareForThemself: false,
    childCareNeeds: {
      homeBased: false,
      center: false,
      school: false,
      preschool: false,
      headStart: false,
      familyOrFriend: false,
      exploring: false,
      neededCare: 'part_time',
      startDate: 'immediately',
    },
    selectedProviders: [],
    initialSelectedProviders: [],
  };

  useEffect(() => {
    //if there is no data for this child member, then initialize it with the initialChildData value
    if (!memberData.childMember) {
      setMemberData({
        ...memberData,
        childMember: initialChildData,
        adultMember: undefined,
      });
    }
  }, []);

  useEffect(() => {
    if (memberData.childMember?.seekingChildCare === false) {
      setMemberData({
        ...memberData,
        childMember: {
          ...memberData.childMember,
          childCareNeeds: {
            homeBased: false,
            center: false,
            school: false,
            preschool: false,
            headStart: false,
            familyOrFriend: false,
            exploring: false,
            neededCare: 'part_time',
            startDate: 'immediately',
          },
        },
      });
    }
  }, [memberData.childMember?.seekingChildCare]);

  const createTextfield = (name: string, nameLabel: FormattedMessageType, childData: ChildMember) => {
    return (
      <TextField
        name={name}
        label={nameLabel}
        value={childData && childData[name]}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setMemberData({
            ...memberData,
            childMember: {
              ...childData,
              [name]: event.target.value,
            },
          });
        }}
        required
        sx={{ marginRight: '1rem', backgroundColor: '#ffffff' }}
      />
    );
  };

  const createAscendingMenuItems = (options: { [key: string]: string }) => {
    const menuItemKeys = Object.keys(options);
    menuItemKeys.sort((a, z) => sortNumbersAscending(a, z));

    const dropdownMenuItems = menuItemKeys.map((option, i) => {
      return (
        <MenuItem value={option} key={option}>
          {options[option]}
        </MenuItem>
      );
    });

    return dropdownMenuItems;
  };

  const handleDropdownChange = (event: SelectChangeEvent) => {
    if (memberData.childMember) {
      setMemberData({
        ...memberData,
        childMember: { ...memberData.childMember, [event.target.name]: event.target.value as string },
      });
    }
  };

  const createChildDropdown = (
    inputName: string,
    inputLabel: FormattedMessageType,
    childData: ChildMember,
    optionsList: Record<string, JSX.Element>,
  ) => {
    return (
      <div className="child-dd-container">
        <FormControl>
          <InputLabel id={inputLabel.props.defaultMessage}>{inputLabel}</InputLabel>
          <Select
            labelId={inputLabel.props.defaultMessage}
            id={inputName}
            name={inputName}
            value={childData[inputName]}
            label={inputLabel}
            onChange={handleDropdownChange}
            required={true}
            sx={{ width: '8rem', backgroundColor: '#ffffff' }}
          >
            {createAscendingMenuItems(optionsList)}
          </Select>
        </FormControl>
      </div>
    );
  };

  const sortNumbersAscending = (a: number | string, z: number | string) => {
    //instructions on how to compare elements when they're being sorted
    if (Number(a) < Number(z)) {
      return -1; // sort z after a
    } else if (Number(a) > Number(z)) {
      return 1; // sort a after z
    } else {
      return 0; // a === z, so keep original order
    }
  };

  const renderAdditionalInfoBlock = (childData: ChildMember) => {
    return (
      memberData.childMember && (
        <div className="section-container">
          <div className="section pink-section">
            <p className="question-label margin-bottom">
              <FormattedMessage
                id="householdDataBlock.pleaseProvideAddtlInfo"
                defaultMessage="Please provide additional information about this individual:"
              />
            </p>
            <div>
              {createTextfield(
                'firstName',
                <FormattedMessage id="childBlock.firstName" defaultMessage="First Name" />,
                childData,
              )}
              {createTextfield(
                'lastName',
                <FormattedMessage id="childBlock.lastName" defaultMessage="Last Name" />,
                childData,
              )}
            </div>
            <div className="bday-section-dropdown-container">
              {createChildDropdown(
                'birthMonth',
                <FormattedMessage id="childBlock.birthMonth" defaultMessage="Birth Month" />,
                childData,
                birthMonthOptions,
              )}
              {createChildDropdown(
                'birthDay',
                <FormattedMessage id="childBlock.birthDay" defaultMessage="Birth Day" />,
                childData,
                birthDayOptions,
              )}
              {createTextfield(
                'birthYear',
                <FormattedMessage id="childBlock.birthYear" defaultMessage="Birth Year" />,
                childData,
              )}
            </div>
          </div>
        </div>
      )
    );
  };

  const renderChildCareRadioQ = (childData: ChildMember) => {
    const seekingChildCareBoolValue = childData?.seekingChildCare ?? false;
    const seekingChildCareOnChangeHandler = () => {
      if (childData === undefined) {
        setMemberData({ ...memberData, childMember: initialChildData });
        return;
      }
      setMemberData({
        ...memberData,
        childMember: { ...childData, seekingChildCare: !childData.seekingChildCare },
      });
    };

    return (
      <Box>
        <h2 className="question-label">
          <FormattedMessage
            id="childBlock.areYouSeeking.header"
            defaultMessage="Are you seeking child care or preschool for this child?"
          />
        </h2>
        <p className="margin-bottom">
          <FormattedMessage
            id="childBlock.areYouSeekingSubheader"
            defaultMessage="Programs may be available for children under 12 and children between 13 and 18 who have a disability."
          />
        </p>
        {renderRadioGroup(`seekingChildCare`, seekingChildCareBoolValue, seekingChildCareOnChangeHandler)}
      </Box>
    );
  };

  const renderRadioGroup = (name: keyof ChildMember, stateBoolVal: boolean, onChangeHandler: () => void) => {
    return (
      <div className="radiogroup-container">
        <RadioGroup name={name} value={stateBoolVal} onChange={onChangeHandler}>
          <FormControlLabel
            value="true"
            control={<Radio />}
            label={<FormattedMessage id="radiofield.label-yes" defaultMessage="Yes" />}
          />
          <FormControlLabel
            value="false"
            control={<Radio />}
            label={<FormattedMessage id="radiofield.label-no" defaultMessage="No" />}
          />
        </RadioGroup>
      </div>
    );
  };

  const handleChildCareNeedsCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    memberData: Member,
    inputName: string,
    memberType: string,
  ) => {
    const { name, checked } = event.target;
    setMemberData({
      ...memberData,
      [memberType]: {
        ...memberData[memberType],
        [inputName]: { ...memberData[memberType][inputName], [name]: checked },
      },
    });
  };

  const createChildCareNeedsCheckboxSection = (
    memberData: Member,
    memberType: string,
    inputName: string,
    options: Record<string, string>,
    handleChangeFunction: (
      event: React.ChangeEvent<HTMLInputElement>,
      memberData: Member,
      inputName: string,
      memberType: string,
    ) => void,
  ) => {
    return (
      <CheckboxGroup
        stateObj={memberData}
        memberType={memberType}
        inputName={inputName}
        options={options}
        handleCheckboxChangeFunction={handleChangeFunction}
      />
    );
  };

  const handleChildCareNeedsRadioX = (
    event: React.ChangeEvent<HTMLInputElement>,
    childCareNeedsVar: keyof ChildCareNeeds,
  ) => {
    if (memberData.childMember) {
      setMemberData({
        ...memberData,
        childMember: {
          ...memberData.childMember,
          childCareNeeds: {
            ...memberData.childMember.childCareNeeds,
            [childCareNeedsVar]: (event.target as HTMLInputElement).value,
          },
        },
      });
    }
  };

  const createNeededCareRadioSection = (
    memberData: Member,
    inputName: keyof ChildCareNeeds,
    options: Record<string, JSX.Element>,
  ) => {
    const formControlLabels = Object.entries(options).map((keyValPair) => {
      return <FormControlLabel value={keyValPair[0]} control={<Radio />} label={keyValPair[1]} key={keyValPair[0]} />;
    });

    return (
      <FormControl>
        <RadioGroup
          aria-labelledby="select-needed-care-radio-buttons-group"
          name={inputName}
          value={memberData.childMember?.childCareNeeds[inputName]}
          onChange={(event) => handleChildCareNeedsRadioX(event, inputName)}
        >
          {formControlLabels}
        </RadioGroup>
      </FormControl>
    );
  };

  const renderChildCareNeedsSection = (memberData: Member) => {
    return (
      <>
        <div className="section-container">
          <div className="section pink-section">
            <h2 className="question-label margin-bottom">
              <FormattedMessage
                id="childBlock.typeOfCare"
                defaultMessage="What type of child care/early childhood education program are you looking for?"
              />
            </h2>
            <p className="question-description">
              <FormattedMessage id="childBlock.typeOfCare.subheader" defaultMessage="Choose all that apply" />
            </p>
            {createChildCareNeedsCheckboxSection(
              memberData,
              'childMember',
              'childCareNeeds',
              childCareOptions,
              handleChildCareNeedsCheckboxChange,
            )}
          </div>
        </div>
        {memberData.childMember?.childCareNeeds.familyOrFriend && (
          <ErrorBox>
            <p>
              <FormattedMessage
                id="childBlock.typeOfCare.warning"
                defaultMessage="If you wish to have a family, friend, or neighbor care for your child and that person does not have a child care license, that person may need to register with the CCCAP program as a “Qualified Exempt Provider.” An Adams County navigator can help your caregiver register. Contact: adamsCCAPnavigators@adcogov.org."
              />
            </p>
            <p className="margin-top">
              <FormattedMessage
                id="childBlock.typeOfCare.1"
                defaultMessage=" Your friend, family member, or neighbor can also reach out to Adams County Human Services at the address above for information about how to become a Qualified Exempt Provider."
              />
            </p>
          </ErrorBox>
        )}
        <div>
          <h2 className="question-label margin-bottom">
            <FormattedMessage id="childBlock.hoursOfCare" defaultMessage="How many hours of care do you need?" />
          </h2>
          {createNeededCareRadioSection(memberData, 'neededCare', neededCareOptions)}
        </div>
        <div>
          <h2 className="question-label margin-bottom margin-top">
            <FormattedMessage
              id="childBlock.startDate"
              defaultMessage="When do you need child care or preschool to start for this child?"
            />
          </h2>
          {createNeededCareRadioSection(memberData, 'startDate', childCareStartDateOptions)}
        </div>
      </>
    );
  };

  const handleFinalCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    memberData: Member,
    inputName: string,
    memberType: string,
  ) => {
    const { name, checked } = event.target;

    setMemberData({
      ...memberData,
      [memberType]: {
        ...memberData[memberType],
        [name]: checked,
      },
    });
  };

  const renderFinalCheckboxes = (memberData: Member) => {
    const formattedMessagesList = [
      {
        fMsgId: 'iep-help-text',
        fMDefaultMsg:
          'An IEP is a formal public school document that outlines special education and related services for a specific child.  Children with an IEP receive preschool in alignment with their IEP.',
      },
      {
        fMsgId: 'multilingual-help-text',
        fMDefaultMsg:
          'A child is multilingual if they are currently developing a second language while continuing to develop English proficiency.',
      },
    ];
    return (
      <div className="section-container">
        <div className="section pink-section">
          <h2 className="question-label margin-bottom">
            <FormattedMessage id="childBlock.conditions" defaultMessage="Do any of these apply to them?" />
            <HelpBubble listOfMessages={formattedMessagesList} />
          </h2>
          <CheckboxGroup
            stateObj={memberData}
            memberType="childMember"
            inputName="childMember"
            options={finalConditionOptions}
            handleCheckboxChangeFunction={handleFinalCheckboxChange}
          />
        </div>
      </div>
    );
  };

  const showChildMemberErrMsg = (childData: ChildMember) => {
    const firstAndLastAreValid = childData.firstName !== '' && childData.lastName !== '';
    const birthMonthAndDayAreValid = childData.birthMonth !== '' && childData.birthDay !== '';
    const numberMustBeFourDigitsLongRegex = /^\d{4}$/;
    const birthYearIsValid =
      numberMustBeFourDigitsLongRegex.test(childData.birthYear) && Number(childData.birthYear) <= 2024;
    const childCareNeedsIsValid =
      !childData.seekingChildCare ||
      (childData.seekingChildCare &&
        Object.entries(childData.childCareNeeds)
          .filter((entry) => entry[0] !== 'neededCare')
          .some((entry) => {
            return entry[1] === true;
          }));

    if (!firstAndLastAreValid) {
      return (
        <div className="validation-error-container">
          <WarningIcon className="back-to-screen-warning-icon" />
          <p className="err-msg">
            <FormattedMessage
              id="childBlock.firstLastName.error"
              defaultMessage="Please enter a valid first and last name"
            />
          </p>
        </div>
      );
    } else if (!birthMonthAndDayAreValid) {
      return (
        <div className="validation-error-container">
          <WarningIcon className="back-to-screen-warning-icon" />
          <p className="err-msg">
            <FormattedMessage
              id="childBlock.errors.birthday"
              defaultMessage="Please select a birth month and birth day"
            />
          </p>
        </div>
      );
    } else if (!birthYearIsValid) {
      return (
        <div className="validation-error-container">
          <WarningIcon className="back-to-screen-warning-icon" />
          <p className="err-msg">
            <FormattedMessage id="childBlock.errors.birthYear" defaultMessage="Please enter a four digit birth year" />
          </p>
        </div>
      );
    } else if (!childCareNeedsIsValid) {
      return (
        <div className="validation-error-container">
          <WarningIcon className="back-to-screen-warning-icon" />
          <p className="err-msg">
            <FormattedMessage
              id="childBlock.errors.program"
              defaultMessage="Please select at least one type of child care/early childhood education program"
            />
          </p>
        </div>
      );
    }
  };

  const renderIEPWarningBox = () => {
    return (
      <ErrorBox>
        <p>
          <FormattedMessage
            id="landingPage.iepMessage.0"
            defaultMessage="You have said that your child has an Individualized Education Program (IEP), which means they receive special education services. Not all preschool providers can offer the services in an IEP. The “administrative unit” (usually your current school district) can best match you with providers in order to make sure your child gets the services they need. Please reach out to your Administrative Unit in your school district to find out more about how this works. Get the number "
          />
          <a
            href="https://www.cde.state.co.us/cdesped/sped-dir"
            target="_blank"
            rel="noopener noreferrer"
            className="here-link"
          >
            <FormattedMessage id="childBlock.iep.info.link" defaultMessage="here" />
          </a>
          .
        </p>
        <p>
          <FormattedMessage
            id="landingPage.iepMessage.1"
            defaultMessage="If you have other children in the family that need child care or preschool and do not have an IEP, please reach out to us at adamsecepilot@garycommunity.org and we will reach out!"
          />
        </p>
      </ErrorBox>
    );
  };

  return (
    memberData.childMember && (
      <div className="child-blk-container">
        {renderAdditionalInfoBlock(memberData.childMember)}
        {renderChildCareRadioQ(memberData.childMember)}
        {memberData.childMember.seekingChildCare && renderChildCareNeedsSection(memberData)}
        {renderFinalCheckboxes(memberData)}
        {memberData.childMember?.iep && renderIEPWarningBox()}
        {showChildMemberErrMsg(memberData.childMember)}
      </div>
    )
  );
};

export default ChildBlock;
