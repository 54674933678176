import { Checkbox, FormControlLabel } from '@mui/material';
import './Legal.css';
import { FormattedMessage } from 'react-intl';
import { ChangeEventHandler, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { Context } from '../Wrapper/Wrapper';
import {
  checkboxNotCheckedError,
  checkboxNotCheckedMessage,
  emptyError,
  useErrorController,
} from '../../Assets/validationFunctions';
import Textfield from '../Textfield/Textfield';

const SIGNATURE_DETAILS = {
  componentType: 'Textfield',
  inputType: 'text',
  inputName: 'signature',
  numericField: false,
  inputLabel: <FormattedMessage id="legal.signature" defaultMessage="Signature" />,
  inputError: emptyError,
  inputHelperText: () => '',
  required: true,
};

const INITIALS_DETAILS = {
  componentType: 'Textfield',
  inputType: 'text',
  inputName: 'initials',
  numericField: false,
  inputLabel: <FormattedMessage id="legal.initials" defaultMessage="Initials" />,
  inputError: emptyError,
  inputHelperText: () => '',
  required: true,
};

type CheckBoxConsentProps = {
  selected: boolean;
  setSelected: (selected: boolean) => void;
  submitted: number;
} & PropsWithChildren;
export const CheckBoxConsent = ({ selected, setSelected, submitted, children }: CheckBoxConsentProps) => {
  const errorController = useErrorController(checkboxNotCheckedError, checkboxNotCheckedMessage);

  useEffect(() => {
    errorController.updateError(selected);
  }, [selected]);

  useEffect(() => {
    errorController.setSubmittedCount(submitted);
  }, [submitted]);

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={selected}
            onChange={() => {
              setSelected(!selected);
            }}
            sx={errorController.showError ? { color: '#c6252b' } : {}}
          />
        }
        label={<div>{children}</div>}
      />
      {errorController.showError && errorController.message(selected)}
    </div>
  );
};

type LegalProps = {
  submitted: number;
};

const Legal = ({ submitted }: LegalProps) => {
  const { formData, setFormData } = useContext(Context);
  const errorController = useErrorController(checkboxNotCheckedError, checkboxNotCheckedMessage);

  const [infoCorrect, setInfoCorrect] = useState(formData.legalConsent?.infoCorrect ?? false);
  const [readPoliciesAndTerms, setReadPoliciesAndTerms] = useState(
    formData.legalConsent?.readPoliciesAndTerms ?? false,
  );
  const [thirteenOrOlder, setThirteenOrOlder] = useState(formData.legalConsent?.thirteenOrOlder ?? false);
  const [electronicSignature, setElectronicSignature] = useState(formData.legalConsent?.electronicSignature ?? false);
  const [initials, setInitials] = useState(formData.legalConsent?.initials ?? '');
  const [signature, setSignature] = useState(formData.legalConsent?.signature ?? '');

  useEffect(() => {
    errorController.setSubmittedCount(submitted);
  }, [submitted]);

  useEffect(() => {
    setFormData({
      ...formData,
      legalConsent: { infoCorrect, readPoliciesAndTerms, thirteenOrOlder, electronicSignature, initials, signature },
    });
  }, [infoCorrect, readPoliciesAndTerms, thirteenOrOlder, electronicSignature, initials, signature]);

  const onSignatureChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const target = event.target;
    const value = target.value;
    setSignature(value);
  };

  const onInitialsChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const target = event.target;
    const value = target.value;
    setInitials(value);
  };

  return (
    <div className="legal-container">
      <section className="legal-text">
        <div>
          <FormattedMessage
            id="legal.text.title"
            defaultMessage="By submitting this form, you understand and agree that:"
          />
        </div>
        <p className="legal-text-item">
          <FormattedMessage
            id="legal.text.items.0"
            defaultMessage="(1) failure to report required changes or misreporting information may result in the recovery and/or discontinuance of your child care benefits;"
          />
        </p>
        <p className="legal-text-item">
          <FormattedMessage
            id="legal.text.items.1"
            defaultMessage="(2) your preferred preschool provider(s), Local Coordinating Organizations (LCOs), Administrative Unit, state of Colorado staff that will directly support Universal Preschool (UPK) Colorado, and staff contracted to support the UPK Colorado and related business needs will have access to the personal information you provide;"
          />
        </p>
        <p className="legal-text-item">
          <FormattedMessage
            id="legal.text.items.2"
            defaultMessage="(3) the information you provide is used to support the Adams County Head Start program, the Colorado Child Care Assistance Program (CCCAP), and/or the UPK Colorado program, as applicable, including but not limited to, determining enrollment eligibility and supporting the matching and enrollment processes; and"
          />
        </p>
        <p className="legal-text-item">
          <FormattedMessage
            id="legal.text.items.3"
            defaultMessage="(4) in order to participate in and receive benefits and services through Head Start, CCCAP, and/or UPK, Adams County Connections, the Adams County Department of Human Services and the Colorado Department of Early Childhood may need to share information about you with other organizations, including any child care provider you may choose to use or any other governmentally-administered assistance program."
          />
        </p>
      </section>
      <div>
        <CheckBoxConsent selected={infoCorrect} setSelected={setInfoCorrect} submitted={submitted}>
          <FormattedMessage
            id="legal.infoCorrect.label"
            defaultMessage="You certify that the information on this form is correct, to the best of your knowledge, and agree to the sharing of your information in the above manner."
          />
        </CheckBoxConsent>
        <CheckBoxConsent selected={readPoliciesAndTerms} setSelected={setReadPoliciesAndTerms} submitted={submitted}>
          <FormattedMessage
            id="legal.readPolicies.label.0"
            defaultMessage="By proceeding, you confirm that you have read and agree to the "
          />
          <a
            href="https://drive.google.com/file/d/1jzFBDF8ffxj0ZmavF2LVuUVT7gNcSp6I/view?usp=sharing"
            target="blank"
            className="legal-terms-link"
          >
            <FormattedMessage id="legal.readPolicies.label.1" defaultMessage="Privacy Policy and Additional Terms" />
          </a>
          <FormattedMessage id="legal.readPolicies.label.2" defaultMessage=" & " />
          <a
            href="https://drive.google.com/file/d/1SX_JD5ISFWaiFoIGOO8NAih8eTuBytYG/view?usp=sharing"
            target="blank"
            className="legal-terms-link"
          >
            <FormattedMessage id="legal.readPolicies.label.3" defaultMessage="Consent to Contact" />
          </a>
          .
        </CheckBoxConsent>
        <CheckBoxConsent selected={thirteenOrOlder} setSelected={setThirteenOrOlder} submitted={submitted}>
          <FormattedMessage
            id="legal.thirteenOrOlder.label"
            defaultMessage="You confirm you are 13 years of age or older."
          />
        </CheckBoxConsent>
        <CheckBoxConsent selected={electronicSignature} setSelected={setElectronicSignature} submitted={submitted}>
          <FormattedMessage
            id="legal.electronicSignature.label"
            defaultMessage="You agree that your name and initials will be an electronic representation of your signature."
          />
        </CheckBoxConsent>
      </div>
      <h2>
        <FormattedMessage id="legal.signature.title" defaultMessage="Type your name and initials below." />
      </h2>
      <div>
        <Textfield
          data={{ signature: signature }}
          index={0}
          handleTextfieldChange={onSignatureChange}
          componentDetails={SIGNATURE_DETAILS}
          submitted={errorController.submittedCount}
        />
        <Textfield
          data={{ initials: initials }}
          index={0}
          handleTextfieldChange={onInitialsChange}
          componentDetails={INITIALS_DETAILS}
          submitted={errorController.submittedCount}
        />
      </div>
    </div>
  );
};

export default Legal;
