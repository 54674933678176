import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ADAMS_ZIPCODES from './adamsZipcodes';
import type { ErrorController, ValidationFunction, MessageFunction } from '../Types/ErrorController';
import ErrorMessageWrapper from '../Components/ErrorMessage/ErrorMessageWrapper';
import type { ContactInfo, Income, Member, ContactTimeOptions } from '../Types/FormData';
import ErrorBox from '../Components/ErrorBox/ErrorBox';
import adamsConditionOptions, { Conditions } from './adamsConditionOptions';

function useErrorController(hasErrorFunc: ValidationFunction<any>, messageFunc: MessageFunction<any>): ErrorController {
  const [hasError, setHasError] = useState(false);
  const [submittedCount, setSubmittedCount] = useState(0);

  const showError = hasError && submittedCount > 0;

  const incrementSubmitted = () => {
    setSubmittedCount(submittedCount + 1);
  };

  const updateError: ValidationFunction<any> = (value, formData) => {
    const updatedHasError = hasErrorFunc(value, formData);
    setHasError(updatedHasError);
    return updatedHasError;
  };

  const message: MessageFunction<any> = (value, formData) => {
    return messageFunc(value, formData);
  };

  return { hasError, showError, submittedCount, incrementSubmitted, setSubmittedCount, updateError, message };
}

const ageHasError: ValidationFunction<string | number> = (applicantAge) => {
  // handleTextfieldChange prevents setting anything to formData that does not pass a number regex test
  // so applicantAge will always be initiated as a string and converted to a number once it passes the regex test
  const numberApplicantAge = Number(applicantAge);
  //the numbers that we type in have to be 0-8 digits long but we want them to be within this min/max range
  const minimumAge = 13;
  const maximumAge = 130;
  return numberApplicantAge < minimumAge || numberApplicantAge > maximumAge;
};

const displayAgeHelperText: MessageFunction<string> = (applicantAge) => {
  const numberApplicantAge = Number(applicantAge);
  const minimumAge = 13;
  const maximumAge = 130;
  if (numberApplicantAge < minimumAge || numberApplicantAge > maximumAge) {
    return (
      <ErrorMessageWrapper fontSize="1rem">
        <FormattedMessage id="errorMessage-age" defaultMessage="Please enter your age" />
      </ErrorMessageWrapper>
    );
  }
};

const zipcodeHasError: ValidationFunction<string | number> = (zipcode) => {
  //the zipcode input must have digits [0-9] and be exactly 5 digits long
  const numberMustBeFiveDigitsLongRegex = /^\d{5}$/;
  if (numberMustBeFiveDigitsLongRegex.test(zipcode.toString())) {
    //this means that the zipcode input passed the regex test so we can just return false since there is no error
    //this additional test checks the zipcode input against all CO zipcodes
    return !ADAMS_ZIPCODES.includes(zipcode.toString());
  } else {
    return true;
  }
};

const displayZipcodeHelperText: MessageFunction<string | number> = (zipcode) => {
  if (zipcodeHasError(zipcode)) {
    return (
      <ErrorMessageWrapper fontSize="1rem">
        <FormattedMessage id="validation-helperText.zipcode" defaultMessage="Please enter a valid Adams zip code" />
      </ErrorMessageWrapper>
    );
  }
};

const radiofieldHasError: ValidationFunction<any> = (radiofield) => {
  return typeof radiofield !== 'boolean';
};

const hoursWorkedValueHasError: ValidationFunction<string> = (valueInput) => {
  const numberUpToEightDigitsLongRegex = /^\d{0,3}$/;
  return Number(valueInput) <= 0 && (numberUpToEightDigitsLongRegex.test(valueInput) || valueInput === '');
};

const hoursWorkedHelperText: MessageFunction<string> = (valueInput) => {
  if (hoursWorkedValueHasError(valueInput)) {
    return (
      <ErrorMessageWrapper fontSize="1rem">
        <FormattedMessage id="errorMessage-greaterThanZero" defaultMessage="Please enter a number greater than 0" />
      </ErrorMessageWrapper>
    );
  }
};

const incomeStreamValueHasError: ValidationFunction<string> = (valueInput) => {
  const incomeAmountRegex = /^\d{0,7}(?:\d\.\d{0,2})?$/;

  return Number(valueInput) <= 0 && (incomeAmountRegex.test(valueInput) || valueInput === '');
};

const displayIncomeStreamValueHelperText: MessageFunction<string> = (valueInput) => {
  if (incomeStreamValueHasError(valueInput)) {
    return (
      <ErrorMessageWrapper fontSize="1rem">
        <FormattedMessage id="errorMessage-greaterThanZero" defaultMessage="Please enter a number greater than 0" />
      </ErrorMessageWrapper>
    );
  }
};

const incomeStreamsAreValid: ValidationFunction<Income[]> = (incomeStreams) => {
  const allIncomeStreamsAreValid = incomeStreams.every((incomeSourceData) => {
    const { type, amount, frequency, hoursWorked } = incomeSourceData;
    return (
      type.length > 0 &&
      amount !== '' &&
      Number(amount) > 0 &&
      (frequency !== 'hourly' || (hoursWorked !== 0 && Number(hoursWorked) > 0)) &&
      frequency.length > 0
    );
  });

  //incomeStreams must have a non-zero length since this function is only called if
  //the user indicated that they had income. This stmt was also added to fix the vacuously
  //true stmt that allIncomeStreamsAreValid for an empty array
  return incomeStreams.length > 0 && allIncomeStreamsAreValid;
};

const expenseSourceValueHasError: ValidationFunction<string | number> = (valueInput) => {
  return valueInput === '' || Number(valueInput) <= 0;
};

const displayExpenseSourceValueHelperText: MessageFunction<string | number> = (valueInput) => {
  if (expenseSourceValueHasError(valueInput)) {
    return (
      <ErrorMessageWrapper fontSize="1rem">
        <FormattedMessage id="errorMessage-greaterThanZero" defaultMessage="Please enter a number greater than 0" />
      </ErrorMessageWrapper>
    );
  }
};

const expenseSourcesHaveError: ValidationFunction<Expense[]> = (expenses) => {
  const expensesHasError = expenses.some((expenseSourceData) => {
    const { expenseSourceName, expenseAmount } = expenseSourceData;
    return expenseSourceName === '' || expenseAmount === '' || Number(expenseAmount) <= 0;
  });

  return expensesHasError;
};

const displayExpensesHelperText: MessageFunction<Expense[]> = (expenses) => {
  if (expenseSourcesHaveError(expenses)) {
    return (
      <ErrorMessageWrapper fontSize="1rem">
        <FormattedMessage
          id="expenseBlock.return-error-message"
          defaultMessage="Please select and enter a response for all expense fields"
        />
      </ErrorMessageWrapper>
    );
  }
};

const householdSizeHasError: ValidationFunction<string> = (_, formData) => {
  if (formData === undefined) {
    return true;
  }

  return formData.householdSize === undefined || formData.householdSize <= 0;
};

const displayHouseholdSizeHelperText: MessageFunction<string> = (sizeOfHousehold) => {
  const numValueInput = Number(sizeOfHousehold);
  return (
    (numValueInput <= 0 || numValueInput > 8) && (
      <ErrorMessageWrapper fontSize="1rem">
        <FormattedMessage
          id="errorMessage-numberOfHHMembers"
          defaultMessage="Please enter the number of people in your household (max. 8)"
        />
      </ErrorMessageWrapper>
    )
  );
};

const householdAssetsHasError: ValidationFunction<string> = (householdAssets) => {
  return Number(householdAssets) < 0;
};

const displayHouseholdAssetsHelperText: MessageFunction<string> = (householdAssets) => {
  if (householdAssetsHasError(householdAssets)) {
    return (
      <ErrorMessageWrapper fontSize="1rem">
        <FormattedMessage id="validation-helperText.assets" defaultMessage="Please enter 0 or a positive number." />
      </ErrorMessageWrapper>
    );
  }
};

const householdMemberAgeHasError: ValidationFunction<string> = (applicantAge) => {
  if (applicantAge === '') {
    return true;
  }
  const numberApplicantAge = Number(applicantAge);
  return numberApplicantAge < 0;
};

const displayHouseholdMemberAgeHelperText: MessageFunction<string> = (applicantAge) => {
  if (householdMemberAgeHasError(applicantAge)) {
    return (
      <ErrorMessageWrapper fontSize="1rem">
        <FormattedMessage
          id="errorMessage-HHMemberAge"
          defaultMessage="Please enter 0 or a positive number for the household member's age"
        />
      </ErrorMessageWrapper>
    );
  }
};

const personDataIsValid: ValidationFunction<Member> = (memberData) => {
  const isChild = ['', 'child', 'foster_child', 'step_child', 'grandchild'];
  const definedMemberData = memberData.adultMember || memberData.childMember;
  const isAnAdult = definedMemberData && !isChild.includes(memberData.relationship);
  const isAChild = definedMemberData && isChild.includes(memberData.relationship);

  const relationship = memberData.relationship;
  const relationshipToHHIsValid = relationship !== '';

  if (isAnAdult) {
    //this if stmt is for TS
    if (memberData.adultMember === undefined) {
      return false;
    }

    const { hasIncome, income } = memberData.adultMember;

    const conditions = Object.keys(adamsConditionOptions) as Conditions[];
    let hasCondition = false;
    for (const condition of conditions) {
      if (condition === 'noQualifyingFactor') {
        continue;
      }
      if (memberData.adultMember?.[condition] === true) {
        hasCondition = true;
        break;
      }
    }
    const conditionsAreValid = hasCondition !== memberData.adultMember.noQualifyingFactor;

    const incomeIsValid = (hasIncome && incomeStreamsAreValid(income)) || !hasIncome;

    return relationshipToHHIsValid && incomeIsValid && conditionsAreValid;
  } else if (isAChild) {
    //this if stmt is for TS
    if (memberData.childMember === undefined) {
      return false;
    }

    const firstAndLastAreValid = memberData.childMember.firstName !== '' && memberData.childMember.lastName !== '';
    const birthMonthAndDayAreValid = memberData.childMember.birthMonth !== '' && memberData.childMember.birthDay !== '';
    const numberMustBeFourDigitsLongRegex = /^\d{4}$/;
    const birthYearIsValid =
      numberMustBeFourDigitsLongRegex.test(memberData.childMember.birthYear) &&
      Number(memberData.childMember.birthYear) <= 2024;
    const childCareNeedsIsValid =
      !memberData.childMember.seekingChildCare ||
      (memberData.childMember.seekingChildCare &&
        Object.entries(memberData.childMember.childCareNeeds)
          .filter((entry) => entry[0] !== 'neededCare')
          .some((entry) => {
            return entry[1] === true;
          }));
    const noIep = memberData.childMember.iep === false;

    return (
      relationshipToHHIsValid &&
      firstAndLastAreValid &&
      birthMonthAndDayAreValid &&
      birthYearIsValid &&
      childCareNeedsIsValid &&
      noIep
    );
  }

  return false;
};

const getHealthInsuranceError: MessageFunction<{ index: number; healthInsurance: HealthInsurance }> = ({
  index,
  healthInsurance,
}) => {
  if (healthInsuranceDataIsValid(healthInsurance) === false) {
    if (healthInsurance.none === true) {
      //then they chose none and another option
      return (
        <ErrorMessageWrapper fontSize="1rem">
          {index === 1 ? (
            <FormattedMessage
              id="validation-helperText.hhMemberInsuranceNone-you"
              defaultMessage="Please do not select any other options if you do not have health insurance"
            />
          ) : (
            <FormattedMessage
              id="validation-helperText.hhMemberInsuranceNone-they"
              defaultMessage="Please do not select any other options if they do not have health insurance"
            />
          )}
        </ErrorMessageWrapper>
      );
    } else if (healthInsurance.dont_know === true) {
      //then they chose dont_know and another option
      return (
        <ErrorMessageWrapper fontSize="1rem">
          <FormattedMessage
            id="validation-helperText.hhMemberInsuranceDontKnow"
            defaultMessage="Please do not select any other options if you don't know"
          />
        </ErrorMessageWrapper>
      );
    } else {
      //they haven't selected an option
      return (
        <ErrorMessageWrapper fontSize="1rem">
          <FormattedMessage
            id="validation-helperText.hhMemberInsurance"
            defaultMessage="Please select at least one health insurance option"
          />
        </ErrorMessageWrapper>
      );
    }
  }
};

const healthInsuranceDataIsValid: ValidationFunction<HealthInsurance> = (hhMemberHealthInsData) => {
  const numOfTrueValues = Object.values(hhMemberHealthInsData).filter(
    (healthInsuranceValue) => healthInsuranceValue === true,
  ).length;

  if (hhMemberHealthInsData.none === true || hhMemberHealthInsData.dont_know === true) {
    //check here to ensure that that is the ONLY option that was selected via numOfTrueValues
    return numOfTrueValues === 1;
  } else {
    const atLeastOneOptionWasSelected = numOfTrueValues > 0;
    return atLeastOneOptionWasSelected;
  }
};

const healthInsuranceDataHasError: ValidationFunction<HealthInsurance> = (hhMemberHealthInsData: HealthInsurance) => {
  return !healthInsuranceDataIsValid(hhMemberHealthInsData);
};

const emailHasError: ValidationFunction<string> = (email) => {
  return !/^.+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(email);
};

const displayEmailHelperText: MessageFunction<string> = (email) => {
  if (emailHasError(email)) {
    return (
      <ErrorMessageWrapper fontSize="1rem">
        <FormattedMessage id="validation-helperText.email" defaultMessage="Please enter a valid email address" />
      </ErrorMessageWrapper>
    );
  }
};

const selectHasError: ValidationFunction<string> = (referralSource) => {
  return !referralSource;
};

const displayReferralSourceHelperText: MessageFunction<string> = (source) => {
  if (selectHasError(source)) {
    return (
      <ErrorMessageWrapper fontSize="1rem">
        <FormattedMessage id="validation-helperText.referralSource" defaultMessage="Please select a referral source." />
      </ErrorMessageWrapper>
    );
  }
};

const displayMissingSelectHelperText: MessageFunction<string> = (source) => {
  if (selectHasError(source)) {
    return (
      <ErrorMessageWrapper fontSize="1rem">
        <FormattedMessage id="validation-helperText.selectOption" defaultMessage="Please select an option." />
      </ErrorMessageWrapper>
    );
  }
};

const nameHasError: ValidationFunction<string> = (name) => {
  return name === '';
};

const displayFirstNameHelperText: MessageFunction<string> = (firstName) => {
  if (nameHasError(firstName)) {
    return (
      <ErrorMessageWrapper fontSize="1rem">
        <FormattedMessage id="validation-helperText.firstName" defaultMessage="Please enter your first name" />
      </ErrorMessageWrapper>
    );
  }
};

const displayLastNameHelperText: MessageFunction<string> = (lastName) => {
  if (nameHasError(lastName)) {
    return (
      <ErrorMessageWrapper fontSize="1rem">
        <FormattedMessage id="validation-helperText.lastName" defaultMessage="Please enter your last name" />
      </ErrorMessageWrapper>
    );
  }
};

const phoneHasError: ValidationFunction<string> = (phoneNumber) => {
  const digitizedPhone = phoneNumber.replace(/\D/g, '');
  return digitizedPhone.length !== 10;
};

const displayPhoneHasErrorHelperText: MessageFunction<string> = (phoneNumber) => {
  if (phoneHasError(phoneNumber)) {
    return (
      <ErrorMessageWrapper fontSize="1rem">
        <FormattedMessage
          id="validation-helperText.phoneNumber"
          defaultMessage="Please enter a 10 digit phone number"
        />
      </ErrorMessageWrapper>
    );
  }
};

const signUpFormHasError: ValidationFunction<SignUpInfo & { serverError?: boolean }> = (props) => {
  const { email, phone, firstName, lastName, sendUpdates, sendOffers, commConsent, hasUser, serverError } = props;
  const atLeastOneCheckboxSelectionWasMade = sendUpdates === true || sendOffers === true;
  if (hasUser === true) {
    return false;
  }

  return (
    (emailHasError(email) && email !== '') ||
    (phoneHasError(phone) && phone !== '') ||
    (!email && !phone) ||
    !firstName ||
    !lastName ||
    atLeastOneCheckboxSelectionWasMade === false ||
    commConsent === false ||
    serverError === true
  );
};

const displayNoEmailOrPhoneHelperText = (email: string, phone: string) => {
  if (!email && !phone) {
    return (
      <ErrorMessageWrapper fontSize="1rem">
        <FormattedMessage
          id="validation-helperText.noEmailOrPhoneNumber"
          defaultMessage="Please enter an email or phone number"
        />
      </ErrorMessageWrapper>
    );
  }
};

const signUpServerHasError: ValidationFunction<boolean | undefined> = (serverError) => {
  return serverError === true;
};

const signUpServerErrorHelperText: MessageFunction<SignUpInfo> = (props) => {
  return (
    <ErrorMessageWrapper fontSize="1.5rem">
      <FormattedMessage
        id="validation-helperText.serverError"
        defaultMessage="Please enter a valid email address. This error could also be caused by entering an email address that is already in our system. If the error persists, remember that this question is optional and will not impact your MyFriendBen results. You can skip this question by deselecting the boxes at the top of the page and pressing continue."
      />
    </ErrorMessageWrapper>
  );
};

const signUpOptionsHaveError: ValidationFunction<SignUpInfo> = (signUpInfo) => {
  const { sendOffers, sendUpdates } = signUpInfo;
  const doesNotWantNotifications = sendOffers === false && sendUpdates === false;

  if (doesNotWantNotifications) {
    return false;
  } else {
    return signUpFormHasError(signUpInfo);
  }
};

const healthInsuranceHasError: ValidationFunction<{ [key: string]: boolean }> = (healthInsuranceSelections) => {
  const healthInsuranceKeys = Object.keys(healthInsuranceSelections);
  const noOptionWasSelected = healthInsuranceKeys.every(
    (option) => healthInsuranceSelections[option as keyof HealthInsurance] === false,
  );
  return noOptionWasSelected;
};

const displayHealthInsuranceHelperText: MessageFunction<{ [key: string]: boolean }> = (healthInsuranceSelections) => {
  if (healthInsuranceHasError(healthInsuranceSelections)) {
    return (
      <ErrorMessageWrapper fontSize="1.5rem">
        <FormattedMessage
          id="validation-helperText.healthInsurance"
          defaultMessage='If none of these apply, please select "One or more household member(s) do not have health insurance"'
        />
      </ErrorMessageWrapper>
    );
  }
};

const acuteHHConditionsHasError = () => {
  return false;
};

const benefitsHasError: ValidationFunction<string> = (hasBenefits, formData) => {
  if (hasBenefits !== 'true') {
    return false;
  }
  if (formData === undefined) {
    throw new Error('FormData not provided');
  }
  const { benefits } = formData;
  const selectedAtLeastOneBenefit = Object.keys(benefits).some(
    (benefit) => formData.benefits[benefit as keyof Benefits] === true,
  );

  //return the opposite since we're indicating whether or not there's an error
  return !selectedAtLeastOneBenefit;
};

const displayBenefitsHelperText: MessageFunction<string> = (hasBenefits, formData) => {
  if (benefitsHasError(hasBenefits, formData)) {
    return (
      <ErrorMessageWrapper fontSize="1.5rem">
        <FormattedMessage
          id="validation-helperText.benefits"
          defaultMessage='If your household does not receive any of these benefits, please select the "No" option above.'
        />
      </ErrorMessageWrapper>
    );
  }
};

const countySelectHelperText: MessageFunction<string> = () => {
  return (
    <ErrorMessageWrapper fontSize="1rem">
      <FormattedMessage id="errorMessage-county" defaultMessage="Please Select a county" />
    </ErrorMessageWrapper>
  );
};

const expenseTypeHelperText: MessageFunction<string> = () => {
  return (
    <ErrorMessageWrapper fontSize="1rem">
      <FormattedMessage id="errorMessage-expenseType" defaultMessage="Please select an expense type" />
    </ErrorMessageWrapper>
  );
};

const relationTypeHelperText: MessageFunction<string> = () => {
  return (
    <ErrorMessageWrapper fontSize="1rem">
      <FormattedMessage id="errorMessage-HHMemberRelationship" defaultMessage="Please select a relationship" />
    </ErrorMessageWrapper>
  );
};

const incomeStreamHelperText: MessageFunction<string> = () => {
  return (
    <ErrorMessageWrapper fontSize="1rem">
      <FormattedMessage id="errorMessage-incomeType" defaultMessage="Please select an income type" />
    </ErrorMessageWrapper>
  );
};

const incomeFrequencyHelperText: MessageFunction<string> = () => {
  return (
    <ErrorMessageWrapper fontSize="1rem">
      <FormattedMessage id="errorMessage-incomeFrequency" defaultMessage="Please select a frequency" />
    </ErrorMessageWrapper>
  );
};

const otherReferalSourceHelperText: MessageFunction<string> = () => {
  return (
    <ErrorMessageWrapper fontSize="1rem">
      <FormattedMessage id="errorMessage-otherReferralSource" defaultMessage="Please type in your referral source" />
    </ErrorMessageWrapper>
  );
};

const termsOfServiceHasError: ValidationFunction<boolean> = (isChecked) => {
  return !isChecked;
};

const displayAgreeToTermsErrorMessage: MessageFunction<null> = () => {
  return (
    <ErrorMessageWrapper fontSize="1rem">
      <FormattedMessage id="disclaimer.error" defaultMessage="Please check the box to continue." />
    </ErrorMessageWrapper>
  );
};

const addressHasError: ValidationFunction<null> = (_, formData) => {
  if (formData === undefined || formData.address === undefined) {
    return true;
  }

  const address = formData.address;

  if (!address.validAddress) {
    return true;
  }

  if (address.address1 === '' || address.city === '' || address.zipcode === '' || address.state === '') {
    return true;
  }

  if (address.county !== 'ADAMS') {
    return true;
  }

  if (address.state !== 'Colorado') {
    return true;
  }

  return false;
};

const displayAddressError: MessageFunction<null> = () => {
  return null;
};

const address1HasError: ValidationFunction<boolean> = (bool) => {
  return bool;
};

const address1HelperText: MessageFunction<string> = (value) => {
  return (
    <ErrorMessageWrapper fontSize="1rem">
      <FormattedMessage id="city-error" defaultMessage="Please enter a valid address" />
    </ErrorMessageWrapper>
  );
};

const address2HasError: ValidationFunction<string> = (value) => {
  return false;
};

const address2HelperText: MessageFunction<string> = (value) => {
  return '';
};

const cityHasError: ValidationFunction<string> = (value) => {
  return !value || value.length < 1;
};

const cityHelperText: MessageFunction<string> = (value) => {
  return (
    <ErrorMessageWrapper fontSize="1rem">
      <FormattedMessage id="city-error" defaultMessage="City is required" />
    </ErrorMessageWrapper>
  );
};

const stateHasError: ValidationFunction<string> = (value) => {
  return value !== 'Colorado';
};

const stateHelperText: MessageFunction<string> = (value) => {
  return (
    <ErrorMessageWrapper fontSize="1rem">
      <FormattedMessage id="city-error" defaultMessage="You must live in Colorado" />
    </ErrorMessageWrapper>
  );
};

const schoolDistrictError: ValidationFunction<string> = (_, formData) => {
  return formData === undefined || formData.schoolDistrict === undefined;
};

const emptyError: ValidationFunction<string> = (value) => {
  return value === '';
};

const schoolDistrictHelperText: MessageFunction<string> = (value) => {
  return (
    <ErrorMessageWrapper fontSize="1rem">
      <FormattedMessage id="schoolDistrict.error" defaultMessage="Please select a school district" />
    </ErrorMessageWrapper>
  );
};

const legalConsentHasError: ValidationFunction<string> = (value, formData) => {
  if (formData === undefined) {
    return true;
  }

  if (formData.legalConsent === undefined) {
    return true;
  }

  if (
    formData.legalConsent.infoCorrect === false ||
    formData.legalConsent.readPoliciesAndTerms === false ||
    formData.legalConsent.thirteenOrOlder === false ||
    formData.legalConsent.electronicSignature === false
  ) {
    return true;
  }

  if (formData.legalConsent.signature === '' || formData.legalConsent.initials === '') {
    return true;
  }

  return false;
};

const checkboxNotCheckedError: ValidationFunction<boolean> = (value) => {
  return value !== true;
};

const checkboxNotCheckedMessage: MessageFunction<boolean> = (value) => {
  return (
    <ErrorMessageWrapper fontSize="1rem">
      <FormattedMessage id="legal.error" defaultMessage="Please check the box to continue" />
    </ErrorMessageWrapper>
  );
};

const providerError: ValidationFunction<null> = (_, formData) => {
  if (formData === undefined) {
    return true;
  }

  for (const member of formData.members) {
    if (member.childMember === undefined) {
      continue;
    }

    if (member.childMember.seekingChildCare === false) {
      continue;
    }

    if (member.childMember.selectedProviders.length !== 3) {
      return true;
    }
  }

  return false;
};

const providerMessage: MessageFunction<null> = (_, formData) => {
  return (
    <ErrorMessageWrapper fontSize="1rem">
      <FormattedMessage id="providers.error" defaultMessage="Please select exactly 3 providers" />
    </ErrorMessageWrapper>
  );
};

const conditionsHasError: ValidationFunction<null> = (_, formData) => {
  return formData?.otherConditions === undefined;
};

const firstNameRequiredMessage: MessageFunction<string> = (value) => {
  return (
    <ErrorMessageWrapper fontSize="1rem">
      <FormattedMessage id="contact.firstName.error" defaultMessage="First Name is required" />
    </ErrorMessageWrapper>
  );
};

const lastNameRequiredMessage: MessageFunction<string> = (value) => {
  return (
    <ErrorMessageWrapper fontSize="1rem">
      <FormattedMessage id="contact.lastName.error" defaultMessage="Last Name is required" />
    </ErrorMessageWrapper>
  );
};

const contactInformationHasError: ValidationFunction<null> = (_, formData) => {
  if (formData === undefined || formData.contactInformation === undefined) {
    return true;
  }

  const contactInfo = formData.contactInformation as ContactInfo;
  const selectedContactTimes: ContactTimeOptions = {
    weekdaysMornings: contactInfo.weekdaysMornings,
    weekdaysLunch: contactInfo.weekdaysLunch,
    weekdaysAfternoons: contactInfo.weekdaysAfternoons,
    weekdaysEvenings: contactInfo.weekdaysEvenings,
    saturdays: contactInfo.saturdays,
    sundays: contactInfo.sundays,
  };

  if (emptyError(contactInfo.firstName) || emptyError(contactInfo.lastName)) {
    return true;
  }

  if (emailHasError(contactInfo.email)) {
    return true;
  }

  if (phoneHasError(contactInfo.phone)) {
    return true;
  }

  if (atLeastOneContactTimeOptionWasSelected(selectedContactTimes) === false) {
    return true;
  }

  return false;
};

const atLeastOneContactTimeOptionWasSelected = (selectedContactTimes: ContactTimeOptions) => {
  const timeToContactOptions = [
    'weekdaysMornings',
    'weekdaysLunch',
    'weekdaysAfternoons',
    'weekdaysEvenings',
    'saturdays',
    'sundays',
  ];

  return timeToContactOptions.some((option) => {
    const typedOption = option as keyof ContactTimeOptions;
    return selectedContactTimes[typedOption];
  });
};

const notSelectedHasError: ValidationFunction<boolean> = (value) => {
  return value !== false;
};

const iepErrorMessage: MessageFunction<boolean> = () => {
  return (
    <ErrorBox>
      <p>
        <FormattedMessage
          id="landingPage.iepMessage.0"
          defaultMessage="You have said that your child has an Individualized Education Program (IEP), which means they receive special education services. Not all preschool providers can offer the services in an IEP. The “administrative unit” (usually your current school district) can best match you with providers in order to make sure your child gets the services they need. Please reach out to your Administrative Unit in your school district to find out more about how this works. Get the number "
        />
        <a
          href="https://www.cde.state.co.us/cdesped/sped-dir"
          target="_blank"
          rel="noopener noreferrer"
          className="here-link"
        >
          <FormattedMessage id="childBlock.iep.info.link" defaultMessage="here" />
        </a>
        .
      </p>
      <p>
        <FormattedMessage
          id="landingPage.iepMessage.1"
          defaultMessage="If you have other children in the family that need child care or preschool and do not have an IEP, please reach out to us at adamsecepilot@garycommunity.org and we will reach out!"
        />
      </p>
    </ErrorBox>
  );
};

export {
  useErrorController,
  ageHasError,
  displayAgeHelperText,
  zipcodeHasError,
  displayZipcodeHelperText,
  radiofieldHasError,
  hoursWorkedValueHasError,
  hoursWorkedHelperText,
  incomeStreamValueHasError,
  displayIncomeStreamValueHelperText,
  incomeStreamsAreValid,
  expenseSourceValueHasError,
  displayExpenseSourceValueHelperText,
  expenseSourcesHaveError,
  displayExpensesHelperText,
  householdSizeHasError,
  displayHouseholdSizeHelperText,
  householdAssetsHasError,
  displayHouseholdAssetsHelperText,
  householdMemberAgeHasError,
  displayHouseholdMemberAgeHelperText,
  personDataIsValid,
  emailHasError,
  displayEmailHelperText,
  selectHasError,
  displayReferralSourceHelperText,
  displayMissingSelectHelperText,
  nameHasError,
  displayFirstNameHelperText,
  displayLastNameHelperText,
  phoneHasError,
  displayPhoneHasErrorHelperText,
  signUpFormHasError,
  signUpServerHasError,
  signUpServerErrorHelperText,
  signUpOptionsHaveError,
  healthInsuranceHasError,
  displayHealthInsuranceHelperText,
  acuteHHConditionsHasError,
  benefitsHasError,
  displayBenefitsHelperText,
  countySelectHelperText,
  expenseTypeHelperText,
  relationTypeHelperText,
  incomeStreamHelperText,
  incomeFrequencyHelperText,
  otherReferalSourceHelperText,
  termsOfServiceHasError,
  displayAgreeToTermsErrorMessage,
  healthInsuranceDataHasError,
  getHealthInsuranceError,
  addressHasError,
  displayAddressError,
  address1HasError,
  address1HelperText,
  address2HasError,
  address2HelperText,
  cityHasError,
  cityHelperText,
  stateHasError,
  stateHelperText,
  schoolDistrictError,
  emptyError,
  schoolDistrictHelperText,
  legalConsentHasError,
  checkboxNotCheckedError,
  checkboxNotCheckedMessage,
  providerError,
  providerMessage,
  conditionsHasError,
  firstNameRequiredMessage,
  lastNameRequiredMessage,
  contactInformationHasError,
  notSelectedHasError,
  iepErrorMessage,
  atLeastOneContactTimeOptionWasSelected,
};
